import { List, Avatar, ButtonGroup, Button } from '@douyinfe/semi-ui';
import { useEffect, useState } from 'react';

export default function ToupiaoQt() {
    const [toupiao_list, setToupiaoList] = useState([])
    const [data, setData] = useState([])

    useEffect(() => {
        setData([
            {name: "名字1", "bz": "bz", isSelect: false},
            {name: "名字2", "bz": "bz", isSelect: false},
            {name: "名字3", "bz": "bz", isSelect: false},
            {name: "名字4", "bz": "bz", isSelect: false},
            {name: "名字5", "bz": "bz", isSelect: false},
            {name: "名字6", "bz": "bz", isSelect: true},
        ])
    }, [])

    return <>
        <div>
            <h3>在编</h3>
            <List
                dataSource={data}
                renderItem={item => (
                    <List.Item
                        header={<Avatar color="blue">{item.name}</Avatar>}
                        main={
                            <div>
                                <span style={{ color: 'var(--semi-color-text-0)', fontWeight: 500 }}>{item.name}</span>
                                <p style={{ color: 'var(--semi-color-text-2)', margin: '4px 0' }}>{item.bz}</p>
                            </div>
                        }
                        extra={
                            item.isSelect ? <><Button>投票</Button></> : <><Button>已投票</Button></>
                        }
                    />
                )}
            />
        </div>
    </>
}