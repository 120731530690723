import React, { useEffect, useState } from 'react';
import { Button, Modal, Form, Toast } from '@douyinfe/semi-ui';
import requests from '../../../../utils/request'

let form_data = {}

export default function ExamPaperPoolEditor({ record, visible, setVisible, fetchData }) {

    if (visible && JSON.stringify(record) !== "{}") {
        record.t = [record.examStartTime, record.examEndtime]
        form_data = record
    }

    const handleOk = () => {
        form_data.examStartTime = form_data.t[0]
        form_data.examEndtime = form_data.t[1]
        form_data.t = undefined
        console.log(form_data)
        requests.post("/skyy/exam/update_exam_paperpool", form_data).then(res => {
            Toast.success("修改成功")
            fetchData()
            setVisible(false)
        })
        // setVisible(false)
    }

    const handleAfterClose = () => {
        // fetchData()
    }
    const { Input, DatePicker } = Form



    return <>
        <Modal
            title={`编辑试卷`}
            visible={visible}
            onOk={handleOk}
            afterClose={handleAfterClose} //>=1.16.0
            onCancel={() => setVisible(false)}
            okText={'保存修改'}
            cancelText={'取消'}
            closable={false}
            width={700}
        >
            <Form
                initValues={record}
                style={{ padding: 10, width: '100%' }}
                onValueChange={(v) => form_data = v}
                labelPosition='left'
            >
                <Input
                    field="examPaperName"
                    label="试题名称"
                    trigger='blur'
                    autoComplete={"false"}
                />
                <DatePicker label="考试时间" type='dateTimeRange' field='t' style={{ width: 464, display: 'inline-block' }} />
                <Input
                    field="examPassword"
                    label="考试密码"
                    trigger='blur'
                    autoComplete={"false"}
                />
            </Form>

        </Modal>
    </>
}