import React, { useState, useEffect } from 'react';
import { Table, Button, Toast } from '@douyinfe/semi-ui';
import requests from '../../../../utils/request'
import DelButton from '../../../../component/del_button';
import AddTpClient from '../pages/add_tp_client';

let getdas = null
let setDas = null

const operation = (text, record) => {

    const handleDel = () => {
        requests.get("/skyy/admin/del_tp_client", {
            params: { tp_client_id: record.tp_client_id }
        }).then(res => {
            Toast.success("删除成功")
            let key = record.tp_client_id
            const das = getdas()
            let newDataSource = [...das];
            if (key != null) {
                let idx = newDataSource.findIndex(data => data.tp_client_id === key);
                if (idx > -1) {
                    newDataSource.splice(idx, 1);
                    setDas(newDataSource);
                }
            }
        })
    }
    return <>
        <DelButton onOk={handleDel} desc='删除' />
    </>
}

const columns = [
    {
        title: 'id',
        dataIndex: 'tp_client_id',
    },
    {
        title: 'client_id',
        dataIndex: 'client_id',
    },
    {
        title: '系统名称',
        dataIndex: 'sys_name'
    },
    {
        title: '操作',
        render: operation,
        width: 250
    },
]



export default function TpClientManage() {
    const [dataSource, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [currentPage, setPage] = useState(1);
    const [total, setTotal] = useState(0);
    const [pageSize, setPageSize] = useState(10);
    const [paperpoolAddVisible, setPaperpoolAddVisible] = useState(false)

    setDas = setData

    getdas = () => dataSource

    const fetchData = (cur = currentPage, size = pageSize) => {
        setLoading(true);
        requests.post("/skyy/admin/get_tp_clients", { currentPage: cur, pageSize: size }).then(res => {
            setLoading(false)
            setData(res.data.result)
            setTotal(res.data.total)
        })
    };

    const handleChange = (cur, size) => {
        setPage(cur)
        setPageSize(size)
    };

    useEffect(() => {
        fetchData(currentPage, pageSize)
    }, [currentPage, pageSize])


    return <>
        <div>
            <Button onClick={() => setPaperpoolAddVisible(true)}>新增client</Button>
        </div>
        <Table
            columns={columns}
            rowKey={'tp_client_id'}
            dataSource={dataSource}
            pagination={{
                currentPage,
                pageSize: pageSize,
                total: total,
                onChange: handleChange,
                showSizeChanger: true,
                pageSizeOpts: [10, 20, 30, 40, 50, 100, 500]
            }}
            loading={loading}
            bordered={true}
            sticky={true}
        />
        <AddTpClient visible={paperpoolAddVisible} setVisible={setPaperpoolAddVisible} fetchData={fetchData} />
    </>
}
