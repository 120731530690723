// 函数防抖
function debounce(func, delay) {
    let timeoutId;
    return function (...args) {
        clearTimeout(timeoutId);
        timeoutId = setTimeout(() => {
            func.apply(this, args);
        }, delay);
    };
}

// 函数节流
function throttle(fn, delay = 500) {
    let timer = null;
  
    return (...args) => {
      if (timer) return;
      timer = setTimeout(() => {
        fn(...args);
        timer = null;
      }, delay);
    };
  }

// 复制内容到剪切版

function copyToClip(content) {
    navigator.clipboard.writeText(content)
}

export {debounce, throttle, copyToClip}