import React, { useRef, useState } from 'react';
import {Popconfirm, Form, Row, Col, Toast ,Button } from '@douyinfe/semi-ui';
import { forEach, times } from 'lodash-es';
import requests from "../../../../utils/request"
import doWithAuthCode from "../../../../utils/dd_api"

export default function XxkDaily() {
    const { DatePicker, TextArea } = Form;
    const style = { width: '50%' };
    let nosubmit = []


    const getdaily = (formState,formApi)=>{
        formApi.setValue("text","正在加载数据请稍后...")
        var date1 = new Date(formState.values.date)
        let year = date1.getFullYear()
        let mouth = date1.getMonth() 
        let day = date1.getDate()
        var stardate = Date.parse(new Date(year,mouth,day,0,0,1)).toString()
        var enddate = Date.parse(new Date(year,mouth,day+1,23,59,59)).toString()
        let msg = ""
        let i = 1
        var names = ['龙武','胡阳熠','叶晓雨','谢智林','尹舒龙','邓彬']
        requests.get("/xxk/xxkdaily/xxk_daily_msg?stardate="+stardate+"&enddate=" + enddate).then(res => {
            let da = res.data
            names.forEach(name => {
                msg = msg + name + ':\n' 
                da[name].forEach(el =>{
                    if( el == "休假"){
                        msg = msg +  el + '\n'
                    }else if(el == "暂未提交"){
                        msg = msg +  el + '\n'
                        nosubmit.push(name)
                    }else{
                        msg = msg + i + '. ' + el + '\n'
                        i++
                    }
                })
                i=1
                msg = msg + '\n'
            })
            formApi.setValue("text",year+"年"+(mouth+1)+"月"+day+"日:" +"\n\n" + msg)
        })
    }

    const sendDaily = (formState,formApi)=>{
        requests.post("/xxk/xxkdaily/sendMsg",{'msg': formState.values.text}).then(res=>{
            Toast.success('上传成功')
        }).catch(res=>{
            Toast.success('上传失败')
        })
    }

    const remindDaily = ()=>{
        let curdate = new Date()
        if(curdate.getHours>=6){
            requests.post("/xxk/xxkdaily/remaind_daily_msg",{'users': nosubmit}).then(res=>{
                Toast.success('提醒成功')
            }).catch(res=>{
                Toast.success('提醒失败')
            })
        }
        Toast.success('未超过6点不提醒。')
    }

    return <>
        <center>
            <h2>信息科日报汇总</h2>
        </center>

        <Form
            initValues={{"date":new Date()}}
            style={{ padding: 10, width: '100%' }}
            onSubmit={values => Toast.info({ content: JSON.stringify(values) })}
        >
            {
                ({ formState, values, formApi }) => (
                    <div>
                        <Row>
                            <Button onClick={()=>window.location.reload()}>刷新</Button>
                        </Row>
                        <Row>
                            <DatePicker field="date" label='日期:' style={style}  placeholder='请选择生效日期' />
                        </Row>
                        <Row>
                            <Button onClick={()=>getdaily(formState,formApi)}>日报查询</Button>
                        </Row>
                        <Row>
                            <TextArea
                                noLabel = {true}
                                field='text'
                                initValue="请点击日报查询信息.." 
                                rows={27}
                            />
                        </Row>
                        <Row>
                            <Col span={7}>
                                <Popconfirm
                                    title="是否上传数据"
                                    onConfirm={()=>sendDaily(formState,formApi)}
                                    position='bottom'
                                >
                                    <Button >日报提交</Button>  
                                </Popconfirm>
                            </Col>
                            <Col span={10}>
                                <Button onClick={()=>remindDaily()}>日报提醒</Button>
                            </Col>
                        </Row>
                    </div>
                )
            }
        </Form>
    </>

}


