import { Table, Button, Input } from '@douyinfe/semi-ui';
import { IconDelete, IconEdit, IconMinusCircle,IconHandle } from '@douyinfe/semi-icons';
import moment from 'moment';

function StatusText({ value }) {
    if (value === 1) {
        return (
            <div>正常</div>
        )
    } else if (value === 0) {
        return (
            <div>封禁中</div>
        )
    }
}



function UserList({ tb_data, set_edit_user_visible, set_user_data, get_user_group_list
    , get_user_group_list_by_id, setUserGroupList, setSelectedGroupList, currentPage, pageSize, total, handleChange,set_edit_userMsg_visible,setEditUserMsgData}) {


    const columns = [
        {
            title: 'id',
            dataIndex: 'user_id',
        },
        {
            title: '用户名',
            dataIndex: 'username',
        },
        {
            title: '部门',
            dataIndex: 'dept'
        },
        {
            title: '工号',
            dataIndex: 'gonghao'
        },
        {
            title: '状态',
            dataIndex: 'status',
            render: (text, record, index) => {
                return (
                    <StatusText value={text} />
                );
            }
        },
        {
            title: '创建时间',
            dataIndex: 'create_time',
            render: (text, record, index) => {
                return <>
                    {moment(new Date(text).toLocaleString("en-US", { timeZone: "Asia/Shanghai" })).format('YYYY-MM-DD HH:mm:ss')}
                </>
            }
        },
        {
            title: '最后修改时间',
            dataIndex: 'update_time',
            render: (text, record, index) => {
                return <>
                    {moment(new Date(text).toLocaleString("en-US", { timeZone: "Asia/Shanghai" })).format('YYYY-MM-DD HH:mm:ss')}
                </>

            }
        },
        {
            title: '操作',
            dataIndex: 'operate',
            render: (text, record) => (
                <Operation
                    text={text}
                    record={record}
                    set_visible={set_edit_user_visible}
                    set_user_data={set_user_data}
                    get_user_group_list={get_user_group_list}
                    get_user_group_list_by_id={get_user_group_list_by_id}
                    setUserGroupList={setUserGroupList}
                    setSelectedGroupList={setSelectedGroupList}
                    set_edit_userMsg_visible={set_edit_userMsg_visible}
                    setEditUserMsgData={setEditUserMsgData}
                />
            ),
        },

    ];
    return <>
        
        <Table
            columns={columns}
            dataSource={tb_data}
            rowKey='user_id'
            pagination={{
                currentPage,
                pageSize: pageSize,
                total: total,
                onChange: handleChange,
                showSizeChanger: true,
                pageSizeOpts: [10, 20, 30, 40, 50, 100, 500, 600]
            }}
            bordered={true}
        />
    </>
}

export default UserList


function Operation({ text, record, set_visible, set_user_data, get_user_group_list, get_user_group_list_by_id, setUserGroupList, setSelectedGroupList,set_edit_userMsg_visible,setEditUserMsgData }) {
    const handle_euv = () => {
        set_user_data(record)
        setEditUserMsgData(record)
        get_user_group_list().then(res => {
            const mapData = res.data.map(cur => {
                return {
                    'label': `${cur.name}(${cur.desc})`,
                    'value': cur.group_id,
                    'key': cur.group_id
                }
            })
            setUserGroupList(mapData)
            get_user_group_list_by_id(record.user_id).then(res => {
                const ids = res.data.map(cur => cur.group_id)
                setSelectedGroupList(ids)
                set_visible(true)
            })

        })
    }
    const edit_show = ()=>{
        set_edit_userMsg_visible(true)
        setEditUserMsgData(record)
    }
    return <>
        <Button icon={<IconEdit />} theme="borderless" onClick={handle_euv} />
        <Button icon={<IconMinusCircle rotate={45} />} theme="borderless" type="warning" onClick={() => alert("禁用功能开发中")} />
        <Button icon={<IconDelete />} theme="borderless" type="danger" onClick={() => alert("删除功能开发中")} />
        <Button icon={<IconHandle />} theme="borderless" type="danger" onClick={edit_show} />
    </>
}


