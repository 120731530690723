import { React, useState, useEffect, useRef } from "react";
import {
  Space,
  Form,
  Toast,
  Row,
  Col,
  Button,
  Popconfirm,
  Divider,
} from "@douyinfe/semi-ui";
import { IconUpload, IconDelete, IconClose } from "@douyinfe/semi-icons";
import requests from "../../../../../utils/request_noloading";
import { download_file } from "../../../../../utils/file";
import DingUserChose from "../../../../../component/ding_user_chose";

// 第一个添加用户栏的备注
export const userA_bz = "院长/书记";

// 第二个添加用户栏的备注
export const userB_bz = "领导阅示后处理人";

// 领导(默认值)
const A_init = [
  {
    username: "邹家全",
    userid: "01293531133836352747",
    bz: userA_bz,
  },
]

// 领导阅示后处理人(默认值)
const B_init = [
  {
    username: "谭佳宁",
    userid: "01313100151735139419",
    bz: userB_bz,
  },
  {
    username: "张婷",
    userid: "2431205228778071",
    bz: userB_bz,
  },
  {
    username: "贺靓",
    userid: "20120161141159513",
    bz: userB_bz,
  },
]

// 需要新增用户的表单字段名称
let field_name = null

export default function ShouWeiUpload() {
  const customRequest = ({ file, onSuccess }) => {
    const formData = new FormData();
    formData.append("file", file.fileInstance);
    formData.append("type", "shouwen");
    requests.post("/file/upload", formData).then((data) => {
      onSuccess(data);
    });
  };
  const [date, setdate] = useState({});
  // 当前选择框的备注
  const [cur_bz, set_cur_bz] = useState(null);

  const formApi = useRef();
  const [user_chose_v, set_user_chose_v] = useState(false);

  // 点击选择按钮调用
  const handleSelectUser = (f_name, bz) => {
    field_name = f_name
    set_cur_bz(bz);
    set_user_chose_v(true);
  };

  // 选择好用户后的回调
  const onSelectUser = (users) => {
    users = users.map((user) => {
      user.bz = cur_bz;
      return user;
    });
    let new_users = formApi.current.getValue(field_name);
    new_users = new_users.concat(users);
    // 去重

    formApi.current.setValue(field_name, new_users);
  };

  const renderFileOperation = (fileItem) => (
    <div style={{ display: "flex", columnGap: 8, padding: "0 8px" }}>
      <Button
        onClick={(e) => fileItem.onRemove()}
        icon={<IconDelete></IconDelete>}
        type="tertiary"
        theme="borderless"
        size="small"
      ></Button>
    </div>
  );

  return (
    <>
      <DingUserChose
        visible={user_chose_v}
        setvisible={set_user_chose_v}
        onSelect={onSelectUser}
      />
      <Form
        labelPosition={"left"}
        labelAlign={"left"}
        style={{ padding: "5px", width: 800 }}
        getFormApi={(fApi) => {
          formApi.current = fApi;
        }}
        render={({ formState, formApi, values }) => (
          <div>
            <Row>
              <center>
                <h2>收文填写</h2>
              </center>
              <Col span={24}>
                <Form.Select
                  label="收文类型"
                  field="sw_type"
                  style={{ width: "250px" }}
                >
                  <Form.Select.Option value="行政发文">
                    行政发文
                  </Form.Select.Option>
                  <Form.Select.Option value="中共党委发文">
                    中共党委发文
                  </Form.Select.Option>
                  <Form.Select.Option value="会议及培训">
                    会议及培训
                  </Form.Select.Option>
                  <Form.Select.Option value="传阅">传阅</Form.Select.Option>
                </Form.Select>
                <Form.Input field="fwdw" label="发文单位" placeholder="" />
                <Form.DatePicker
                  field="fwsj"
                  label="发文时间"
                  initValue={new Date()}
                  style={{ width: "250px" }}
                />
                <Form.Input field="ywbh" label="原文编号" placeholder="" />
                <Form.Input field="ywbt" label="原文标题" placeholder="" />
                <Form.DatePicker
                  field="swsj"
                  label="收文时间"
                  initValue={new Date()}
                  style={{ width: "250px" }}
                />
                <Form.Input field="swsxh" label="收文顺序号" placeholder="" />
                <Form.Select
                  label="收文途径"
                  field="swtj"
                  style={{ width: "250px" }}
                >
                  <Form.Select.Option value="钉钉">钉钉</Form.Select.Option>
                  <Form.Select.Option value="微信">微信</Form.Select.Option>
                </Form.Select>
                <Form.DatePicker
                  field="sx"
                  label="时限"
                  initValue={"2099-12-31"}
                  style={{ width: "250px" }}
                />
                <Form.TextArea
                  field="bz"
                  label="备注"
                  placeholder="文字内容"
                  size="large"
                ></Form.TextArea>
                <Form.Upload
                  customRequest={customRequest}
                  field="fj"
                  label="附件"
                  itemStyle={{ width: 300 }}
                  renderFileOperation={renderFileOperation}
                >
                  <Button icon={<IconUpload />} theme="light">
                    上传附件
                  </Button>
                </Form.Upload>
              </Col>
              <Divider margin="12px" />
            </Row>

            <Row>
              <Col span={20}>
                <Form.TagInput
                  field="User_A"
                  allowDuplicates={false}
                  label="领导"
                  initValue={A_init}
                  style={{ width: "90%" }}
                  renderTagItem={(value, index, onClose) =>
                    renderTagItem(value, index, onClose)
                  }
                />
              </Col>
              <Col offset={1} span={3}>
                <Button
                  type="primary"
                  style={{ marginTop: 12 }}
                  onClick={() => {
                    handleSelectUser("User_A", userA_bz);
                  }}
                >
                  新增
                </Button>
              </Col>
            </Row>
            <Row>
              <Col span={20}>
                <Form.TagInput
                  field="User_B"
                  label="领导阅示后处理人"
                  initValue={B_init}
                  style={{ width: "90%" }}
                  renderTagItem={(value, index, onClose) =>
                    renderTagItem(value, index, onClose)
                  }
                />
              </Col>
              <Col offset={1} span={3}>
                <Button
                  type="primary"
                  style={{ marginTop: 12 }}
                  onClick={() => {
                    handleSelectUser("User_B", userB_bz);
                  }}
                >
                  新增
                </Button>
              </Col>
            </Row>

            <Row>
              <Divider margin="12px" />
              <Space>
                <Popconfirm
                  title="是否确定提交台账"
                  position="bottom"
                  onConfirm={() => {
                    let fj = [];
                    if (date["fj"] != null) {
                      fj = date["fj"].map((val) => {
                        return { name: val.name, path: val.response.data.path };
                      });
                    }
                    values["fj"] = fj;
                    requests
                      .post("/office/upload_shouwen_msg", values)
                      .then((da) => {
                        Toast.success("上传成功");
                      });
                  }}
                >
                  <Button type="primary" style={{ marginTop: 12 }}>
                    提交
                  </Button>
                </Popconfirm>
              </Space>
            </Row>
          </div>
        )}
        onValueChange={(values) => {
          setdate(values);
          // console.log(values);
        }}
      ></Form>
    </>
  );
}

export function renderTagItem(value, index, onClose) {
  return (
    <div
      key={index}
      style={{
        display: "flex",
        alignItems: "center",
        fontSize: 14,
        marginRight: 10,
        backgroundColor: "var(--semi-color-bg-4)",
        border: "1px solid rgba(var(--semi-grey-2), .7)",
        color: "var(--semi-color-text-0)",
      }}
    >
      <span style={{ marginLeft: 8 }}>{`${value.username}`}</span>
      <IconClose
        size="small"
        onClick={onClose}
        style={{ color: "#979D9E", marginLeft: 5 }}
      />
    </div>
  );
}
