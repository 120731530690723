import { DatePicker, Layout, Button, Table, Input, Toast, Modal } from '@douyinfe/semi-ui';
import MyButton from "../../../../pages/MyButton";
import React, { useState, useEffect } from 'react';
import exportToExcel from "../../../../utils/export_exc"
import request from "../../../../utils/request"
import "../../../../css/a.css"

//电子卖场合同
//筛选条件
let accept_start_time = null         //验收日期
let accept_end_time = null           //验收日期
let signed_start_time = null         //签订日期
let signed_end_time = null           //签订日期
let keywords = ""                     //关键字
export default function PthtHd() {
    //页面布局
    const { Header, Content } = Layout;
    //列名
    const columns = [
        { dataIndex: 'contractname', title: '合同名称' }
        , { dataIndex: 'signdate', title: '签订日期' }
        , { dataIndex: 'expirdate', title: '有效期' }
        , { dataIndex: 'acceptdate', title: '验收日期' }
        , { dataIndex: 'userdept', title: '使用科室' }
        , { dataIndex: 'amount', title: '总金额' }
        , { dataIndex: 'purchaser', title: '采购人' }
        , { dataIndex: 'tips', title: '备注' }
        , { dataIndex: 'state', title: '合同状态' }
        , {
            title: '操作', width: 240, dataIndex: 'operate'
            , render: (text, record) => (
                <>
                    <Button onClick={() => {
                        window.location.href = '/xxk/contract_detail?xxk_contract_id=' + record.xxk_contract_id
                    }} >合同详情</Button> &nbsp;
                    <Button type="danger" onClick={() => {
                        Modal.warning({
                            title: '是否要删除数据？', onOk: () => {
                                request.post('/xxk_contract/delete_contract_data?xxk_contract_id=' + record.xxk_contract_id).then((res) => {
                                    Toast.success("操作成功")
                                    let key = record.xxk_contract_id
                                    let newDataSource = [...das];
                                    if (key != null) {
                                        let idx = newDataSource.findIndex(data => data.xxk_contract_id === key);
                                        if (idx > -1) {
                                            newDataSource.splice(idx, 1);
                                            setdas(newDataSource);
                                        }
                                    }
                                })
                            }
                        });

                    }} >删除</Button>
                </>
            ),
        }
    ];
    //



    //分页条件
    const [size, setSize] = useState(10);
    const [curPage, setcurPage] = useState(1);
    const [total, setTotal] = useState(0);
    //当前页数据
    const [das, setdas] = useState([
        {},
    ])
    //所有页数据
    let exeDas = []
    const setEx = (data) => {
        exeDas = data
    }
    //数据回显
    useEffect(() => {
        getDatas({
            currentPage: 1,               //页码
            pageSize: 10,                     //尺寸
            accept_start_time: null,   //验收开始时间
            accept_end_time: null,       //验收结束时间            
            signed_start_time: null,   //签订开始时间
            signed_end_time: null,       //签订结束时间
            keywords: ""                      //关键字
        }, setdas)
    }, [setdas])
    //导出excel数据
    const downloadexcel = async () => {
        await getDatas({
            currentPage: -1,                        //页码
            pageSize: size,                         //尺寸
            accept_start_time: accept_start_time,   //验收开始时间
            accept_end_time: accept_end_time,       //验收结束时间            
            signed_start_time: signed_start_time,   //签订开始时间
            signed_end_time: signed_end_time,       //签订结束时间
            keywords: keywords                      //关键字
        }, setEx)
        exportToExcel(columns, exeDas, '表数据')
    }
    //获取当页数据
    const getdtats = () => {
        getDatas({
            currentPage: curPage,                   //页码
            pageSize: size,                         //尺寸
            accept_start_time: accept_start_time,   //验收开始时间
            accept_end_time: accept_end_time,       //验收结束时间            
            signed_start_time: signed_start_time,   //签订开始时间
            signed_end_time: signed_end_time,       //签订结束时间
            keywords: keywords                      //关键字
        }, setdas)
    }
    //发送请求-查询数据
    const getDatas = async (props, setDate) => {
        await request.post('/xxk_contract/contract_data', props).then((res) => {
            setDate(res.data.result)
            setTotal(res.data.total)
        })
    }
    //页码变化
    function onChange(currentPage, pageSize) {
        getDatas({
            currentPage: currentPage,                   //页码
            pageSize: pageSize,                         //尺寸
            accept_start_time: accept_start_time,   //验收开始时间
            accept_end_time: accept_end_time,       //验收结束时间            
            signed_start_time: signed_start_time,   //签订开始时间
            signed_end_time: signed_end_time,       //签订结束时间
            keywords: keywords                      //关键字
        }, setdas)
        setcurPage(currentPage);        //当前页数
        setSize(pageSize)
    }
    //设置时间范围
    const SelectAcceptTime = (date, dateString) => {   //验收日期
        accept_start_time = dateString[0]
        accept_end_time = dateString[1]
    }
    const SelectSignedTime = (date, dateString) => {   //签订日期
        signed_start_time = dateString[0]
        signed_end_time = dateString[1]
    }
    //选择关键字
    const SelectKey = (e) => {
        keywords = e.nativeEvent.srcElement.defaultValue
    }

    const showDialog = () => {
        window.location.href = '/xxk/contract_insert'
    }

    return <>
        <Layout className="components-layout-demo">
            <Header>
                <MyButton name="合同导入" clicfun={showDialog} /><br></br><br></br>
                关键字搜索：<Input defaultValue='' onBlur={SelectKey} style={{ width: 240 }}></Input><br></br><br></br>
                签订日期：&nbsp;&nbsp;&nbsp;&nbsp;<DatePicker type="dateRange" density="compact" style={{ width: 260 }} onChange={SelectSignedTime} />
                &nbsp;&nbsp;&nbsp;&nbsp;验收日期：<DatePicker type="dateRange" density="compact" style={{ width: 260 }} onChange={SelectAcceptTime} /><br></br><br></br>
                <MyButton name="查询" clicfun={getdtats} />
                &nbsp;&nbsp;&nbsp;&nbsp;<MyButton name="导出 EXCEL" clicfun={downloadexcel} />
            </Header>

            <Content >
                <br></br><Table columns={columns} dataSource={das} pagination={{
                    total: total,
                    showSizeChanger: true,
                    defaultCurrentPage: 1,
                    currentPage: curPage,
                    pageSize: size,
                    pageSizeOpts: [10, 20, 50, 200],
                    onChange: onChange
                }} bordered={true} size={'middle'} sticky={true} />
            </Content>
        </Layout>
    </>
}




