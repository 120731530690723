import doWithAuthCode from "../../utils/dd_api";
import * as dd from 'dingtalk-jsapi';
import requests from "../../utils/request"
import { Button, Modal } from "@douyinfe/semi-ui";


export default function ScanCode() {
    dd.scan({
        type: 'qr',
        success: (res) => {
            let { text } = res;
            let json_t = JSON.parse(text)
            doWithAuthCode((code) => {
                json_t.code = code
                if (json_t.t) {
                    requests.post("/ywb/code_signin", json_t).then(res => {
                        if (res.data) {
                            Modal.success({
                                title: "签到成功",
                                content: `姓名: ${res.data.name}, 部门: ${res.data.dept}, ${res.msg}`,
                                hasCancel: false,
                                centered: true
                            })
                        } else {
                            Modal.warning({
                                title: "签到失败",
                                content: res.msg,
                                hasCancel: false,
                                centered: true
                            })
                        }

                    })
                } else {
                    requests.post("/ywb/code_signin_static", json_t).then(res => {
                        if (res.data) {
                            Modal.success({
                                title: "签到成功",
                                content: `姓名: ${res.data.name}, 部门: ${res.data.dept}, ${res.msg}`,
                                hasCancel: false,
                                centered: true
                            })
                        } else {
                            Modal.warning({
                                title: "签到失败",
                                content: res.msg,
                                hasCancel: false,
                                centered: true
                            })
                        }

                    })
                }

            })
        },
        fail: () => { },
        complete: () => { },
    });
    return <>
        <div style={{ textAlign: "center" }}>
            <Button size="large" style={{ marginTop: "60%" }} onClick={() => window.location.reload()}>重新扫码</Button>
        </div>

    </>
}