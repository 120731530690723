import React from 'react';
import './App.css';
import { Layout, Nav, Dropdown, Avatar } from '@douyinfe/semi-ui';
import * as Icon from '@douyinfe/semi-icons';
import { Outlet, Link } from "react-router-dom";
import { get_data } from './utils/data_ctl';
import requests from './utils/request'
import { logout } from './utils/location_util';

// const menus = [
//   { itemKey: '1', text: '首页', path: '/' },
//   {
//     itemKey: '2', text: '用户管理',
//     items: [{ itemKey: '3', text: "用户列表", path: '/skyy/admin/user_manage' }]
//   },
//   {
//     itemKey: '4', text: '用户组管理',
//     items: [{ itemKey: '5', text: "用户组列表", path: '/skyy/admin/user_group_manage', items: [] }]
//   },
//   {
//     itemKey: '6', text: '菜单管理',
//     items: [{ itemKey: '7', text: "菜单列表", path: '/skyy/admin/menu_manage', items: [] }]
//   }
// ]

const empty_tip_menu = [
  { itemKey: '1', text: '当前没有任何菜单', disabled: true },
  { itemKey: '2', text: '请联系信息科', disabled: true },
  { itemKey: '3', text: '为你添加菜单', disabled: true },
]

function gen_menu(parent_id, source, result) {
  if (!source) return []
  const newSource = []
  const rootMenus = []
  source.forEach(val => {
    if (val.parent_id === parent_id) {
      rootMenus.push(val)
    } else {
      newSource.push(val)
    }
  })
  if (rootMenus) {
    for (const i in rootMenus) {
      const menu = rootMenus[i]
      // itemKey必须为字符串，否则无法展开菜单
      if (menu.location !== "内") {
        const m = { itemKey: menu.menu_id + "", path: menu.path, text: menu.name, items: [] }
        result.push(m)
        m.items = gen_menu(menu.menu_id, newSource, m.items)
      }

    }
  }
  return result
}



class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      menus: [],
      user: null
    }
  }

  componentDidMount() {
    const user = get_data("user")
    const user_menus = get_data("user_menus",  true)
    if (user_menus.length === 0) {
      this.setState({ user: user, menus: empty_tip_menu })
      return
    }
    this.setState({ user: user, menus: gen_menu(null, user_menus, []) })
  }

  render() {
    const { Header, Sider, Content } = Layout
    // 记录用户当前打开的菜单, 防止刷新页面后丢失
    const dok = locate_menu(this.state.menus, window.location.pathname)
    const user = this.state.user
    let name = user ? user.username : "未登录"
    return (
      <Layout style={{ border: '1px solid var(--semi-color-border)' }}>
        <Sider style={{ backgroundColor: 'var(--semi-color-bg-1)' }}>
          <Nav
            limitIndent={false}
            defaultSelectedKeys={[dok]}
            style={{ maxWidth: 220, height: '100%' }}
            renderWrapper={({ itemElement, isSubNav, isInSubNav, props }) => {
              return (
                <ParseToLink
                  props={props}
                  element={itemElement}
                />
              );
            }}
            items={this.state.menus}
            header={{
              logo: <Icon.IconSemiLogo style={{ fontSize: 36 }} />,
              text: '功能导航',
            }}
            footer={{
              collapseButton: true, collapseText: (c) => c ? '展开' : '收起'
            }}
          />
        </Sider>
        <Layout>
          <Header style={{ backgroundColor: 'var(--semi-color-bg-1)' }}>
            <Nav
              mode="horizontal"
              footer={
                <>
                  <Dropdown
                    position="bottomRight"
                    render={
                      <Dropdown.Menu>
                        <Dropdown.Item onClick={() => window.location.reload()}>刷新页面</Dropdown.Item>
                        <Dropdown.Item onClick={logout}>退出</Dropdown.Item>
                      </Dropdown.Menu>
                    }
                  >
                    <Avatar size='small' color='light-blue' style={{ margin: 4 }}>{name.substring(0, 2)}</Avatar>
                    <strong>{name}</strong>
                  </Dropdown>
                </>
              }
            ></Nav>
          </Header>
          <Content
            style={{
              // padding: '24px',
              backgroundColor: 'var(--semi-color-bg-0)',
            }}
          >
            <div
              style={{
                // borderRadius: '10px',
                border: '1px solid var(--semi-color-border)',
                minHeight: '86vh',
                padding: '15px',
              }}
            >
              {/* 路由视图 */}
              <Outlet />
            </div>
          </Content>
        </Layout>
      </Layout>
    );
  }
}

export default App;

function ParseToLink({ props, element }) {
  if (props.path) {
    return (
      <Link
        style={{ textDecoration: "none" }}
        to={props.path}
      >
        {element}
      </Link>
    )
  } else {
    return element
  }
}

// 根据当前url定位需要展开的菜单
function locate_menu(m, link) {
  for (const i in m) {
    const menu = m[i]
    if (menu.path && menu.path === link) return menu.itemKey
    if (menu.items) {
      const result = locate_menu(menu.items, link)
      if (result) return result
      else continue
    }
  }
  return null
}
