import { Form } from '@douyinfe/semi-ui';
import { paths } from '../../../../router'
import { useState } from 'react';

export default function AddMenu({ setData }) {
    const [p, setP] = useState(paths)

    const onSearch = (value) => {
        const newArr = paths.filter(val => val.includes(value))
        setP(newArr)
    }
    return <>
        <Form render={({ formState, formApi, values }) => (
            <>
                <Form.Input field='name' label='菜单名称' />
                <Form.AutoComplete
                    data={p}
                    field='path'
                    label='跳转路径'
                    onSearch={onSearch}
                    style={{ width: 200 }}
                />
                <Form.Select style={{ width: 176 }} field='location' label='位置'>
                    <Form.Select.Option value="左">左</Form.Select.Option>
                    <Form.Select.Option value="上">上</Form.Select.Option>
                    <Form.Select.Option value="内">内</Form.Select.Option>
                </Form.Select>
            </>
        )} onValueChange={values => {
            setData(values)
        }}>
        </Form>
    </>
}