import { useEffect, useRef, useState } from 'react';
import { Button, Form, Toast } from '@douyinfe/semi-ui';
import requests from '../../../utils/request'
import { get_data } from '../../../utils/data_ctl';

let form_data = {}

export const dept_arr = ["手足科", "正骨科/小儿骨科", "胫踝科", "ICU", "外科", "髋关节科", "肩肘科/运动医学科",
    "膝关节科", "脊柱科", "内科", "康复科", "针灸理疗科"
]

const dept_map = {
    "手足科护理单元": "手足科",
    "正骨科护理单元": "正骨科/小儿骨科",
    "胫踝科护理单元": "胫踝科",
    "重症医学科护理单元": "ICU",
    "外科护理单元": "外科",
    "髋关节科护理单元": "髋关节科",
    "肩肘科护理单元": "肩肘科/运动医学科",
    "运动医学科护理单元": "肩肘科/运动医学科",
    "膝关节科护理单元": "膝关节科",
    "脊柱科护理单元": "脊柱科",
    "内科护理单元": "内科",
    "康复科护理单元": "康复科",
    "针灸理疗科护理单元": "针灸理疗科",
}

function get_map_dept(dept) {
    const d = dept_map[dept]
    return d ? d : null
}

export default function MgzbQt() {
    const user = get_data("user")
    const [loading, setLoading] = useState(false)
    document.title = "敏感指标上报"
    const { Select, DatePicker, InputNumber } = Form
    const handleSubmit = () => {
        api.current.validate()
            .then(values => {
                // 表单字段校验成功的操作
                setLoading(true)
                values['fjhxbgrs'] = parseInt(values['wg']) + parseInt(values['qgcg']) + parseInt(values['zxjmdg']) + parseInt(values['ylg']) + parseInt(values['ng'])
                values['lzdgrs'] = parseInt(values['lzwcg']) + parseInt(values['lzqgcg']) + parseInt(values['lzzxjmdg']) + parseInt(values['lzylg']) + parseInt(values['lzng'])
                requests.post("/hulibu/mgzb_data_submit", values).then(res => {
                    Toast.success(res.msg)
                }).finally(() => setLoading(false))
            })
            .catch(errors => {
                // 校验失败的操作
                for (let key in errors) {
                    // 滚动到出错的位置
                    api.current.scrollToField(key)
                    break
                }
            })
    }
    const api = useRef();

    // 用户填完科室和上报日期后，检查是否已填过，如果填过则回显
    const handleAutoComplete = (dept, regdate) => {
        requests.post("/hulibu/mgzb_data_query", { dept, regdate }).then(res => {
            // 回显数据
            if (res.data) {
                api.current.setValues(res.data)
            } else {
                let f_data = {}
                for(let i in api.current.getValues()) {
                    if(i === "regdate") f_data[i] = regdate
                    else if(i === "dept") f_data[i] = dept
                    else f_data[i] = 0
                }
                
                api.current.setValues(f_data)
            }

        })

    }

    const handleDeptChange = (dept) => {
        if (dept && form_data.regdate) {
            handleAutoComplete(dept, form_data.regdate)
        }
    }

    const handleRegdateChange = (regdate) => {
        if (regdate && form_data.dept) {
            handleAutoComplete(form_data.dept, regdate)
        }
    }

    useEffect(() => {
        if(get_map_dept(user.dept)) {
            api.current.setValue("dept", get_map_dept(user.dept))
        }
        
    }, [])


    return <>
        <h2 style={{ textAlign: 'center' }}>株洲市中医伤科医院护理质量控制敏感指标上报表</h2>
        <div style={{ width: '80%', margin: '0 auto' }}>
            <Form
                style={{ padding: 10, width: '100%' }}
                getFormApi={formApi => api.current = formApi}
                onValueChange={values => form_data = values}
            >
                <Select
                    field="dept"
                    label="科室"
                    placeholder="未获取到科室信息"
                    autoComplete={"false"}
                    onChange={handleDeptChange}
                    size='large'
                    rules={[{ required: true, message: '科室不能为空' }]}
                    disabled
                >
                    {dept_arr.map(dept => <Select.Option key={dept} value={dept}>{dept}</Select.Option>)}
                </Select>
                <DatePicker
                    field="regdate"
                    label="上报时间"
                    defaultValue={new Date()}
                    onChange={handleRegdateChange}
                    size='large'
                    rules={[{ required: true, message: '上报时间不能为空' }]}
                />
                <InputNumber size="large" field="bzrs" label="病重人数" min={0} initValue={0} />
                <InputNumber size="large" field="bwrs" label="病危人数" min={0} initValue={0} />
                <InputNumber size="large" field="ywsycwrs" label="药物使用错误人数" min={0} initValue={0} />
                <InputNumber size="large" field="gwywwsrs" label="高危药物外渗人数" min={0} initValue={0} />
                <InputNumber size="large" field="syfyrs" label="输液反应人数" min={0} initValue={0} />
                <InputNumber size="large" field="sxfyrs" label="输血反应人数" min={0} initValue={0} />
                <InputNumber size="large" field="wg" label="非计划性胃肠管拔管人数" min={0} initValue={0} />
                <InputNumber size="large" field="qgcg" label="非计划性气管插管拔管人数" min={0} initValue={0} />
                <InputNumber size="large" field="zxjmdg" label="非计划性中心静脉导管拔管人数" min={0} initValue={0} />
                <InputNumber size="large" field="ylg" label="非计划性引流管拔管人数" min={0} initValue={0} />
                <InputNumber size="large" field="ng" label="非计划性导尿管拔管人数" min={0} initValue={0} />
                <InputNumber size="large" field="lzwcg" label="留置胃肠管人数" min={0} initValue={0} />
                <InputNumber size="large" field="lzqgcg" label="留置气管插管人数" min={0} initValue={0} />
                <InputNumber size="large" field="lzzxjmdg" label="留置中心静脉导管人数" min={0} initValue={0} />
                <InputNumber size="large" field="lzylg" label="留置引流管人数" min={0} initValue={0} />
                <InputNumber size="large" field="lzng" label="留置导尿管人数" min={0} initValue={0} />
                <InputNumber size="large" field="ycgwfxxzls" label="压疮高危风险新增例数" min={0} initValue={0} />
                <InputNumber size="large" field="eqysycfsrsyn" label="Ⅱ期以上压疮发生人数（院内）" min={0} initValue={0} />
                <InputNumber size="large" field="eqysycfsrsyw" label="Ⅱ期以上压疮发生人数（院外）" min={0} initValue={0} />
                <InputNumber size="large" field="ddgwfxxzls" label="跌倒高危风险新增例数" min={0} initValue={0} />
                <InputNumber size="large" field="ddrsgw" label="跌倒人数（高危人群）" min={0} initValue={0} />
                <InputNumber size="large" field="ddrsfgw" label="跌倒人数（非高危人群）" min={0} initValue={0} />
                <InputNumber size="large" field="wxrs" label="误吸人数" min={0} initValue={0} />
                <InputNumber size="large" field="dgxgxlgrxzls" label="导管相关血流感染新增例数" min={0} initValue={0} />
                <InputNumber size="large" field="dgxgxnlgrxzls" label="导管相关性尿路感染新增例数" min={0} initValue={0} />
                <InputNumber size="large" field="vtexzls" label="VTE新增例数" min={0} initValue={0} />
                <InputNumber size="large" field="syhxjrs" label="使用呼吸机人数" min={0} initValue={0} />
                <InputNumber size="large" field="rgqdrs" label="人工气道人数" min={0} initValue={0} />
                <InputNumber size="large" field="syysdrs" label="使用约束带人数" min={0} initValue={0} />
                <InputNumber size="large" field="hxjxgxfy" label="呼吸机相关性肺炎" min={0} initValue={0} />
                <Button loading={loading} size='large' theme="solid" block onClick={handleSubmit}>提交</Button>
            </Form>

            <br />
            <br />
            <br />
        </div>
    </>
}