import React, { useState } from 'react';
import { Table, Button, Form } from '@douyinfe/semi-ui';
import requests from '../../../utils/request_noloading'
import exportToExcel from '../../../utils/export_exc';
import { dept_arr } from './mgzb_qt';


const columns = [
    {
        title: '漏报日期',
        dataIndex: 'regdate',
        width: 130
    },
    {
        title: '漏报科室',
        dataIndex: 'missing_depts',
    }
]



export default function MgzbLoubao() {
    const [dataSource, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [searchData, setSearhData] = useState(null)

    const fetchData = (cond = searchData) => {
        setLoading(true);
        requests.post("/hulibu/mgzb_query_by_date_range", cond).then(res => {
            const raw_data = res.data
            const true_data = []
            // 处理返回的数据
            for(let regdate in raw_data) {
                const d_info_arr = raw_data[regdate]
                const missing_depts = dept_arr.filter(v => d_info_arr.indexOf(v) === -1 )
                true_data.push({"regdate": regdate, "missing_depts": missing_depts.join("， ")})
            }
            setData(true_data)
        }).finally(() => setLoading(false))
    };

    const handleSearch = () => {
        let d = {}
        if(searchData) {
            d = {
                start_date: searchData.d_range[0],
                end_date: searchData.d_range[1]
            }
        }
        
        fetchData(d)
    }

    const { DatePicker } = Form

    const export_data = () => {
        exportToExcel(columns, dataSource, "漏报数据")
    }

    return <>
        <div style={{ marginBottom: '10px' }}>
            <Form layout='horizontal' onValueChange={values => setSearhData(values)}>
                <DatePicker
                    label="选择日期"
                    field='d_range'
                    type='dateRange'
                    autoSwitchDate={false}
                />
            </Form>
            <div style={{ marginTop: '10px' }}>
                <Button theme='solid' type='primary' style={{ marginRight: 8 }} onClick={handleSearch}>查询</Button>
                <Button theme='solid' type='primary' style={{ marginRight: 8 }} onClick={export_data}>导出数据</Button>
            </div>

        </div>
        <Table
            columns={columns}
            rowKey={'regdate'}
            dataSource={dataSource}
            pagination={false}
            loading={loading}
            bordered={true}
            sticky={true}
            resizable={true}
        />
    </>
}
