import React from 'react';
import { Button } from '@douyinfe/semi-ui';



export default function SubmitButton({name,clicfun}) {
    return (
        <>
            <Button theme='solid' type='primary' onClick={clicfun}
            >{name}</Button>
        </>
    );
}
