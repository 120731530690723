import React, { useState, useEffect } from 'react';
import { Table, Button, Toast, Form } from '@douyinfe/semi-ui';
import requests from '../../../../utils/request'
import DelButton from '../../../../component/del_button';
import * as dateFns from 'date-fns';
import ExamUserStateEditor from '../pages/exam_userstate_editor';
import { useNavigate } from "react-router-dom";
import exportToExcel from '../../../../utils/export_exc';

let setRecord2 = null
let setPoolEditorVisible2 = null
let getdas = null
let setDas = null
let navigate2 = null

const operation = (text, record) => {
    const handleEdit = () => {
        setRecord2(record)
        setPoolEditorVisible2(true)
    }

    const handleDel = () => {
        requests.post("/skyy/exam/user_exam_del", {
            examPaperId: record.user_exampaperid,
            userId: record.user_userid
        }).then(res => {
            Toast.success("删除成功")
            let key = record.userstateid
            const das = getdas()
            let newDataSource = [...das];
            if (key != null) {
                let idx = newDataSource.findIndex(data => data.userstateid === key);
                if (idx > -1) {
                    newDataSource.splice(idx, 1);
                    setDas(newDataSource);
                }
            }
        })
    }
    return <>
        <Button
            size='small'
            theme="solid"
            style={{ marginLeft: 10, marginRight: 10 }}
            onClick={handleEdit}
        >
            编辑
        </Button>
        <Button
            size='small'
            theme="solid"
            style={{ marginLeft: 10, marginRight: 10 }}
            onClick={() => navigate2("../exam_users_detail?examPaperId=" + record.user_exampaperid +"&userId=" + record.user_userid)}
        >
            详情
        </Button>
        <DelButton onOk={handleDel} desc='删除' />
    </>
}

const columns = [
    {
        title: '科室',
        dataIndex: 'user_dept',
    },
    {
        title: '姓名',
        dataIndex: 'user_name',
    },
    {
        title: '用户id',
        dataIndex: 'user_userid',
    },
    {
        title: '试卷名称',
        dataIndex: 'examPaperName',
    },
    {
        title: '分数',
        dataIndex: 'user_score',
    },
    {
        title: '考试开始时间',
        dataIndex: 'examStartTime',
        render: value => {
            return dateFns.format(new Date(value), 'yyyy-MM-dd HH:mm:ss');
        }
    },
    {
        title: '考试结束时间',
        dataIndex: 'examEndtime',
        render: value => {
            return dateFns.format(new Date(value), 'yyyy-MM-dd HH:mm:ss');
        }
    },
    {
        title: '操作',
        render: operation,
        width: 250
    },
]



export default function ExamUserState() {
    const [dataSource, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [currentPage, setPage] = useState(1);
    const [total, setTotal] = useState(0);
    const [pageSize, setPageSize] = useState(10);
    const [record, setRecord] = useState({})
    const [poolEditorVisible, setPoolEditorVisible] = useState(false)
    const [searchData, setSearhData] = useState({})
    const [fetchFlag, setFetchFlag] = useState(false)
    const navigate = useNavigate()

    setRecord2 = setRecord

    setPoolEditorVisible2 = setPoolEditorVisible

    setDas = setData

    navigate2 = navigate

    getdas = () => dataSource

    const fetchData = (cur = currentPage, size = pageSize, cond = searchData) => {
        setLoading(true);
        requests.post("/skyy/exam/exam_userstate_list", { ...cond, currentPage: cur, pageSize: size }).then(res => {
            setLoading(false)
            setData(res.data.result)
            setTotal(res.data.total)
        })
    };

    const handleChange = (cur, size) => {
        setPage(cur)
        setPageSize(size)
    };

    const handleSearch = () => {
        setPage(1)
        setFetchFlag(!fetchFlag)
    }

    useEffect(() => {
        fetchData(currentPage, pageSize)
    }, [currentPage, pageSize, fetchFlag])

    const handlClear = () => {
        requests.post("/skyy/exam/delete_exampool", {
            exam_id: null
        }).then(res => {
            Toast.success("删除成功")
            fetchData(1, 10)
        })
    }

    const { Input } = Form

    const updateUserMsg = ()=>{
        // console.log(searchData);
        requests.post("/skyy/exam/update_users_state", {...searchData}).then(res => {
            Toast.success("更新成功")
            fetchData(1, 10)
        })
    }

    const storageUserMsg = ()=>{
        // console.log(searchData);
        requests.post("/skyy/exam/storage_users_state", {...searchData}).then(res => {
            Toast.success("数据归档成功")
            fetchData(1, 10)
        })
    }

    const export_data = () => {
        exportToExcel(columns, dataSource, "分数")
    }

    return <>
        <div style={{ marginBottom: '10px' }}>
            <span>搜索与更新条件筛选</span>
            <Form layout='horizontal' onValueChange={values => setSearhData(values)}>
                <Input field='user_dept' label='科室' style={{ width: 150 }} />
                <Input
                    field='user_name'
                    label={{
                        text: '姓名',
                    }}
                    style={{ width: 176 }}
                />
                <Input field='examPaperName' label='考试名称' style={{ width: 300 }} />
            </Form>
            <div style={{ marginTop: '10px' }}>
                <Button theme='solid' type='primary' style={{ marginRight: 8 }} onClick={handleSearch}>搜索</Button>
                <Button theme='solid' type='primary' style={{ marginRight: 8 }} onClick={updateUserMsg}>更新</Button>
                <Button theme='solid' type='primary' style={{ marginRight: 8 }} onClick={storageUserMsg}>归档答题记录</Button>
                <Button theme='solid' type='primary' style={{ marginRight: 8 }} onClick={export_data}>导出数据</Button>
            </div>

        </div>
        <Table
            columns={columns}
            rowKey={'userstateid'}
            dataSource={dataSource}
            pagination={{
                currentPage,
                pageSize: pageSize,
                total: total,
                onChange: handleChange,
                showSizeChanger: true,
                pageSizeOpts: [10, 20, 30, 40, 50, 100, 500]
            }}
            loading={loading}
            bordered={true}
            sticky={true}
        />
        <ExamUserStateEditor record={record} visible={poolEditorVisible} setVisible={setPoolEditorVisible} fetchData={fetchData} />
    </>
}
