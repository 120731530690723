import React, { useEffect, useRef } from 'react';
import { Modal, Form, Toast } from '@douyinfe/semi-ui';
import requests from '../../../../utils/request'

let form_data = {}

export default function ExamPaperPoolAdd({ visible, setVisible, fetchData }) {
    const first = useRef(null)
    const handleOk = () => {
        form_data.examStartTime = form_data.t[0]
        form_data.examEndtime = form_data.t[1]

        requests.post("/skyy/exam/add_exam_paperpool", form_data).then(res => {
            if(res.code === -2) {
                Toast.error(res.msg)
                return
            }
            Toast.success("新增成功")
            fetchData()
            setVisible(false)
        })
    }

    const handleAfterClose = () => {
        // fetchData()
    }
    const { Input, Section, Select, DatePicker, InputNumber, AutoComplete } = Form

    useEffect(() => {
        if (visible) {
            first.current.focus()
        }

    }, [visible])

    return <>
        <Modal
            title={`试卷录入`}
            visible={visible}
            onOk={handleOk}
            afterClose={handleAfterClose} //>=1.16.0
            onCancel={() => setVisible(false)}
            okText={'录入'}
            cancelText={'取消'}
            closable={false}
            width={800}
            preventScroll={true}
        >
            <Form
                style={{ padding: 10, width: '100%' }}
                onValueChange={(v) => form_data = v}
                labelPosition='left'
            >
                <Section text={'基本信息'}>
                    <Input
                        field="examPaperName"
                        label="试卷名称"
                        trigger='blur'
                        autoComplete={"false"}
                        ref={first}
                    />
                    {/* <Select label="工种" field='examWorkcategory' style={{ width: '200px' }}>
                        <Select.Option value="护士">护士</Select.Option>
                        <Select.Option value="医生">医生</Select.Option>
                        <Select.Option value="药剂">药剂</Select.Option>
                        <Select.Option value="放射">放射</Select.Option>
                    </Select> */}
                    <AutoComplete
                        field='examWorkcategory'
                        label="工种"
                        data={['护士', '医生', '药剂', '放射']}
                        placeholder='输入或选择'
                        style={{ width: 200 }}
                    >
                    </AutoComplete>
                    <Select label="题库" field='examType' defaultValue={null} style={{ width: '200px' }}>
                        <Select.Option value={null}>默认</Select.Option>
                        <Select.Option value="固定题库">固定题库</Select.Option>
                    </Select>
                    <DatePicker label="考试时间" type='dateTimeRange' field='t' style={{ width: 464, display: 'inline-block' }} />
                    <Input
                        field="examPassword"
                        label="试卷密码"
                        trigger='blur'
                        autoComplete={"false"}
                    />

                </Section>

                <Section text={'中医'}>
                    <InputNumber min={0} field='examzhonyisingle' initValue={0} style={{ width: 80 }} label={{ text: '单选数量' }} />
                    <InputNumber min={0} field='examzhonyidouble' initValue={0} style={{ width: 80 }} label={{ text: '多选数量' }} />
                </Section>
                <Section text={'西医'}>
                    <InputNumber min={0} field='examxiyisingle' initValue={0} style={{ width: 80 }} label={{ text: '单选数量' }} />
                    <InputNumber min={0} field='examxiyidouble' initValue={0} style={{ width: 80 }} label={{ text: '多选数量' }} />
                </Section>
            </Form>

        </Modal>
    </>
}