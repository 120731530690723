import { useEffect, useRef, useState } from 'react';
import { Image, Form, Button, Modal, Input as XInput, List, Typography, Toast, Upload } from '@douyinfe/semi-ui';
import { IconSearch, IconUpload } from '@douyinfe/semi-icons';
import { get_data } from '../../../../utils/data_ctl';
import requests from '../../../../utils/request_noloading';
import { lcmc as dzmc_lcmc } from "./dzmcht_ht"
import { lcmc as fcght_lcmc } from './fcght_ht';
import { lcmc as cght_lcmc } from './cght_ht';
import { lcmc as yysq_lcmc } from './yysq_ht';
import { debounce } from '../../../../utils/user_friendly';

const cgsqb = ["电子卖场合同"]
const fcghtsp = ["非采购合同", "人事合同", "药剂合同", "工伤合同", "医保合同", "医联体", "合作协议"]
const cghtsp = ["采购合同"]
const yysq = ["用印申请"]

export {fcghtsp}

function get_request_type(t) {
    if (cgsqb.includes(t)) {
        return dzmc_lcmc
    } else if (fcghtsp.includes(t)) {
        return fcght_lcmc
    } else if (cghtsp.includes(t)) {
        return cght_lcmc
    } else if (yysq.includes(t)) {
        return yysq_lcmc
    }
}


export default function Htzdj() {
    const [search_visible, setSearchVisible] = useState(false)
    const [datasource, setDatasource] = useState([])
    const [search_loading, setSearchLoading] = useState(false)
    const [submit_loading, setSubmitLoading] = useState(false)

    document.title = "合同章登记";
    const api = useRef();
    const user = get_data("user")
    const { Input, Select, DatePicker, TextArea, InputNumber, Upload } = Form
    const { Text } = Typography
    const handle_ht_type_change = (val) => {
        setSearchVisible(true)
    }

    // 搜索数据
    const handle_search = (key) => {
        if (key) {
            setSearchLoading(true)
            requests.get("/office/search_sp_data", {
                params: {
                    ht_type: get_request_type(api.current.getValue("ht_type")),
                    key: key
                }
            }).then(res => {
                setDatasource(res.data)
            }).finally(() => setSearchLoading(false))
        }

    }

    // 选择审批数据后的处理
    const handle_selelct_data = (item) => {
        const ht_type = api.current.getValue("ht_type")
        if (cgsqb.indexOf(ht_type) !== -1) {
            let hwhfwmc = item.hwhfwmc
            try {
                hwhfwmc = JSON.parse(item.hwhfwmc).join("，  ")
            } catch(err) {}
            const oldValues = api.current.getValues()
            api.current.setValues({ ...oldValues, hwhfwmc: hwhfwmc, spdh: item.spdh })
        } else if (fcghtsp.indexOf(ht_type) !== -1) {
            const oldValues = api.current.getValues()
            api.current.setValues({ ...oldValues, htmc: item.htmc, jfmc: item.jfmc, yfmc: item.yfmc, qdrq: item.qdrq, spdh: item.spdh })
        } else if (cghtsp.indexOf(ht_type) !== -1) {
            const oldValues = api.current.getValues()
            api.current.setValues({ ...oldValues, htmc: item.htmc, jfmc: item.jfmc, yfmc: item.yfmc, spdh: item.spdh })
        } else if (yysq.indexOf(ht_type) !== -1) {
            const oldValues = api.current.getValues()
            api.current.setValues({ ...oldValues, yywjmc: item.yywjmc, wj_count: item.wj_count, spdh: item.spdh })
        }
        setSearchVisible(false)
    }

    // 重选数据
    const handle_reselect = () => {
        api.current.validate(["ht_type"]).then(values => {
            setSearchVisible(true)
        }).catch(err => {
            console.log(err)
        })
    }

    // 提交表单
    const handle_submit = () => {
        // 表单校验
        api.current.validate().then(values => {
            setSubmitLoading(true)
            const datas = api.current.getValues()
            // 附件数据格式处理
            if(datas.files) {
                datas.files = datas.files.map(val => {
                    return {path: val.response.data.path, name: val.name}
                })
            }
            requests.post("/office/save_hetong_data", datas).then(res => {
                if(res.code === 200) {
                    Modal.success({
                        title: "提交成功",
                        content: res.data ? `合同编号: ${res.data}` : "提交成功",
                        hasCancel: false,
                        centered: true
                    })
                } else if(res.code === -2) {
                    Modal.warning({
                        title: "提交失败",
                        content: "重复数据",
                        hasCancel: false,
                        centered: true
                    })
                }
                
            }).finally(() => setSubmitLoading(false))
        }).catch(err => {
            console.log(err)
        })
    }

    useEffect(() => {
        // 设置初始值
        api.current.setValues({ dj_name: user.username, dj_dept: user.dept, dj_time: new Date() })
    }, [])


    const customRequest = ({ file, onSuccess }) => {

        const formData = new FormData();
        formData.append('file', file.fileInstance);
        formData.append('type', "hetong")

        requests.post("/file/upload", formData).then(data => {
            onSuccess(data)
        })
    }


    return <>
        <Image
            width={200}
            height={50}
            src="/hospital.png"
            style={{ marginLeft: '25%' }}
        />
        <Form
            style={{ padding: 10, width: '90%' }}
            getFormApi={formApi => {
                api.current = formApi
            }}
        >
            {({ formState }) => (
                <>
                    <Input size='large' field='dj_name' label="登记人" />
                    <Input size='large' field='dj_dept' label="登记科室" />
                    <DatePicker size='large' field='dj_time' label="登记时间" />
                    <div style={{ display: 'flex' }}>
                        <Select
                            size='large'
                            field='ht_type'
                            label="合同类型"
                            onSelect={handle_ht_type_change}
                            placeholder="请选择合同类型"
                            rules={[{ required: true, message: "请先选择合同类型" }]}
                        >
                            {cgsqb.map(ht_type => <Select.Option key={ht_type} value={ht_type}>{ht_type}</Select.Option>)}
                            {fcghtsp.map(ht_type => <Select.Option key={ht_type} value={ht_type}>{ht_type}</Select.Option>)}
                            {cghtsp.map(ht_type => <Select.Option key={ht_type} value={ht_type}>{ht_type}</Select.Option>)}
                            {yysq.map(ht_type => <Select.Option key={ht_type} value={ht_type}>{ht_type}</Select.Option>)}
                        </Select>
                        <Text link style={{ paddingTop: 55, marginLeft: 12 }} onClick={handle_reselect}>重选审批数据</Text>
                    </div>
                    {fcghtsp.includes(formState.values.ht_type) || cghtsp.includes(formState.values.ht_type) ? (
                        <TextArea size='large' field="htmc" label='合同（协议）名称' autosize={{ minRows: 1, maxRows: 10 }} />
                    ) : null}
                    {fcghtsp.includes(formState.values.ht_type) || cghtsp.includes(formState.values.ht_type) ? (
                        <TextArea size='large' field="jfmc" label='甲方名称' autosize={{ minRows: 1, maxRows: 10 }} />
                    ) : null}
                    {fcghtsp.includes(formState.values.ht_type) || cghtsp.includes(formState.values.ht_type) ? (
                        <TextArea size='large' field="yfmc" label='乙方名称' autosize={{ minRows: 1, maxRows: 10 }} />
                    ) : null}
                    {cgsqb.includes(formState.values.ht_type) ? (
                        <TextArea size='large' field="hwhfwmc" label='货物或服务名称' autosize={{ minRows: 1, maxRows: 10 }} />
                    ) : null}
                    {cgsqb.includes(formState.values.ht_type) || fcghtsp.includes(formState.values.ht_type) || cghtsp.includes(formState.values.ht_type) ? (
                        <DatePicker size='large' field="qdrq" label='签订日期' />
                    ) : null}
                    {yysq.includes(formState.values.ht_type) ? (
                        <TextArea size='large' field="yywjmc" label='用印文件名称' autosize={{ minRows: 1, maxRows: 10 }} />
                    ) : null}
                    {yysq.includes(formState.values.ht_type) ? (
                        <InputNumber size='large' field="wj_count" label='文件份数' autosize={{ minRows: 1, maxRows: 10 }} />
                    ) : null}
                    <Input field="spdh" noLabel={true} allowEmptyString={true} style={{display:"none"}} />
                    <TextArea size='large' field="bz" label="备注" />
                    <Upload accept="image/*" action='' customRequest={customRequest} field="files" label="上传附件">
                        <Button size='large' icon={<IconUpload />} theme="light">上传附件</Button>
                    </Upload>
                </>
            )}


        </Form>
        <Button loading={submit_loading} theme="solid" size='large' onClick={handle_submit} block>提交</Button>
        <Modal
            visible={search_visible}
            title="选择已审批数据"
            onCancel={() => setSearchVisible(false)}
            width={"100%"}
            closable={false}
            preventScroll={false}
            afterClose={() => setDatasource(null)}
            footer={
                <div style={{ textAlign: 'center' }}>
                    <Button type="primary" theme="solid" onClick={() => setSearchVisible(false)} style={{
                        width: "100%",
                        marginLeft: 0
                    }}>
                        关闭
                    </Button>
                </div>
            }

        >
            <XInput prefix={<IconSearch />} placeholder={"输入内容搜索审批数据"} size="large" onChange={debounce(handle_search, 500)} showClear />
            <div style={{ padding: 12, border: '1px solid var(--semi-color-border)' }}>
                <List
                    loading={search_loading}
                    dataSource={datasource}
                    renderItem={item => (
                        <List.Item
                            main={
                                <div>
                                    <span style={{ color: 'var(--semi-color-text-0)', fontWeight: 500 }}><h3>审批单号：</h3>{item.spdh}</span><br />
                                    {item.hwhfwmc ?
                                        <><span style={{ color: 'var(--semi-color-text-0)', fontWeight: 500 }}><h3>货物或服务名称：</h3>{item.hwhfwmc}</span><br /></>
                                        : null
                                    }
                                    {item.htmc ?
                                        <><span style={{ color: 'var(--semi-color-text-0)', fontWeight: 500 }}><h3>合同（协议）名称：</h3>{item.htmc}</span><br /></>
                                        : null
                                    }
                                    {item.jfmc ?
                                        <><span style={{ color: 'var(--semi-color-text-0)', fontWeight: 500 }}><h3>甲方名称：</h3>{item.jfmc}</span><br /></>
                                        : null
                                    }
                                    {item.yfmc ?
                                        <><span style={{ color: 'var(--semi-color-text-0)', fontWeight: 500 }}><h3>乙方名称：</h3>{item.yfmc}</span></>
                                        : null
                                    }
                                    {item.yywjmc ?
                                        <><span style={{ color: 'var(--semi-color-text-0)', fontWeight: 500 }}><h3>用印文件名称：</h3>{item.yywjmc}</span></>
                                        : null
                                    }
                                    {item.wj_count ?
                                        <><span style={{ color: 'var(--semi-color-text-0)', fontWeight: 500 }}><h3>文件份数：</h3>{item.wj_count}</span></>
                                        : null
                                    }
                                    {/* {item.qdrq ?
                                        <><span style={{ color: 'var(--semi-color-text-0)', fontWeight: 500 }}>签订日期{item.qdrq}</span></>
                                        : null
                                    } */}
                                </div>
                            }
                            extra={
                                <Button onClick={() => handle_selelct_data(item)}>选择</Button>
                            }
                        />
                    )}
                />
            </div>

        </Modal>

    </>
}