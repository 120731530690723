import React from 'react';
import { Upload, Button, Toast } from '@douyinfe/semi-ui';
import { IconPlus } from '@douyinfe/semi-icons';
import { get_data } from '../../../../utils/data_ctl';

export default function AppendUpload({ type, item_id, onSuccess= () => Toast.info("上传成功"), desc="选择文件" }) {
    const uploadRef = React.createRef();
    let action = process.env.REACT_APP_SERVER_URL + "/file/append_upload"
    const user = get_data('user')
    let headers = {
        'Authorization': user.token
    }
    let data = {
        type: type,
        item_id: item_id
    }
    return <>
        <Upload
            action={action}
            multiple
            headers={headers}
            data={data}
            name='file'
            ref={uploadRef}
            onSuccess={onSuccess}
        >
            <Button icon={<IconPlus />} theme="light">
                {desc}
            </Button>
        </Upload>
        <br/>
    </>
}