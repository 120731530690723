import { Button, Banner } from '@douyinfe/semi-ui';
import React, { useState } from "react";
import { IconSend } from '@douyinfe/semi-icons';
import { convertUrlParamsToJson } from '../../../../utils/location_util';

export default function DingLogin({ handleSubmit }) {
    const [loading, setLoading] = useState(false)
    const [failobj, setFailObj] = useState({ failed: false, 'text': '出现异常' })
    const params = convertUrlParamsToJson(window.location.search)
    console.log(params)
    if(!params["notautologin"]) {
        handleSubmit(loading, setLoading, setFailObj)
    }
    const myHandle = () => handleSubmit(loading, setLoading, setFailObj)
    
    return <>
        {!failobj.failed ? <Button loading={loading} block onClick={myHandle} icon={<IconSend />}>登录</Button> : 
        <ErrorTip  failobj={failobj} setFailObj={setFailObj}/>}
    </>
}

function better_text(text) {
    if(text.indexOf("notInDingTalk") !== -1) {
        return "请在钉钉中打开本页面"
    }
    return text
}

function ErrorTip({failobj, setFailObj}) {
    return <>
        <Banner
            fullMode={false}
            title={better_text(failobj.text)}
            type="danger"
            bordered
            description={""}
            closeIcon={null}
        >
            <div className="semi-modal-footer">
                <Button
                    onClick={() => { setFailObj({ ...failobj, failed: false }) }}
                >
                    重试
                </Button>
            </div>
        </Banner>
    </>
}