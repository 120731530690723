import React, { useState, useEffect } from 'react';
import { Table, Button, Toast, Modal, Input } from '@douyinfe/semi-ui';
import requests from '../../../../utils/request_noloading'
import { useNavigate } from 'react-router';
import moment from 'moment'

let record2 = null
let setVisible2 = null

const operation = (text, record) => {

    const handleEdit = () => {
        record2 = record
        setVisible2(true)
    }
    return <>
        <Button
            size='small'
            theme="solid"
            style={{ marginLeft: 10, marginRight: 10 }}
            onClick={handleEdit}
        >
            进入
        </Button>
    </>
}

const columns = [
    {
        title: '考试名称',
        dataIndex: 'examPaperName',
    },
    {
        title: '考试开始时间',
        dataIndex: 'examStartTime',
        render: value => {
            // return dateFns.format(new Date(value), 'yyyy-MM-dd HH:mm:ss');
            return moment(value).format('YYYY-MM-DD HH:mm:ss');
        }
    },
    {
        title: '操作',
        render: operation,
    },
]



export default function ExamIndex() {
    document.title = "考试系统"
    const [dataSource, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [visible, setVisible] = useState(false)
    const [cur_pwd, setCurPwd] = useState("")
    const navigate = useNavigate()

    setVisible2 = setVisible

    const fetchData = () => {
        setLoading(true);
        requests.post("/skyy/exam/exam_index_list").then(res => {
            setLoading(false)
            setData(res.data)
        })
    };

    useEffect(() => {
        fetchData()
    }, [])

    const onOk = () => {
        // 去除用户输入密码中的空格
        const input_pwd = cur_pwd.replace(/\s*/g, "");

        if(record2.examPassword === input_pwd) {
            // 跳转到考试页面
            navigate("../user_exam?examPaperId=" + record2.examPaperId)
        } else {
            Toast.error("密码错误")
        }
    }
    return <>
        <Button style={{margin: '10px'}} onClick={() => window.location.reload()}>刷新页面</Button>

        <Table
            columns={columns}
            rowKey={'examPaperId'}
            dataSource={dataSource}
            loading={loading}
            bordered={true}
            pagination={false}
            empty="暂无正在进行的考试"
        />
        <Modal
            visible={visible}
            title="请输入密码"
            width={"100%"}
            onCancel={() => setVisible(false)}
            onOk={onOk}
            footerFill={true}
            style={{maxWidth: '500px'}}
            centered={true}
        >
            <Input placeholder='请输入密码' value={cur_pwd} onChange={val => setCurPwd(val) } showClear={true}></Input>
        </Modal>
    </>
}
