import React, { useState, useEffect } from 'react';
import { Table, Button, Toast, Form, Modal } from '@douyinfe/semi-ui';
import requests from '../../../../utils/request_noloading'
import DelButton from '../../../../component/del_button';
import { useNavigate } from "react-router-dom";
import exportToExcel from '../../../../utils/export_exc';
import ImgsShow from '../../../../component/imgs_show';
import { get_file_url_sync } from '../../../../utils/file';

let getdas = null
let setDas = null
let setImgsV2 = null
let setImgUrlList2 = null

const operation = (text, record) => {
    // const handleEdit = () => {
    //     setRecord2(record)
    //     setPoolEditorVisible2(true)
    // }

    const handleDel = () => {
        requests.get("/office/del_hetong_data", {
            params: {id: record.ht_id}
        }).then(res => {
            Toast.success("删除成功")
            let key = record.ht_id
            const das = getdas()
            let newDataSource = [...das];
            if (key != null) {
                let idx = newDataSource.findIndex(data => data.ht_id === key);
                if (idx > -1) {
                    newDataSource.splice(idx, 1);
                    setDas(newDataSource);
                }
            }
        })
    }

    const handleFilePreview = async () => {
        const res = await requests.get("/file/file_list", {
            params: {
                item_id: record.ht_id,
                type: "hetong"
            }
        })
        if(res.data && res.data.length !== 0) {
            const urls = res.data.map(u => get_file_url_sync({path: u.path}))
            setImgUrlList2(urls)
            setImgsV2(true)
        } else {
            Toast.warning("暂无附件")
        }
        
        
        
    }
    return <>
        <Button
            size='small'
            theme="solid"
            style={{ marginLeft: 10, marginRight: 10 }}
            onClick={handleFilePreview}
        >
            查看附件
        </Button>
        
        <DelButton onOk={handleDel} desc='删除' />
    </>
}

const lcmc = "采购合同审批"
export {lcmc}

const columns = [
    {
        title: '合同编号',
        dataIndex: 'htbh',
    },
    {
        title: '合同名称',
        dataIndex: 'htmc',
    },
    {
        title: '甲方名称',
        dataIndex: 'jfmc',
    },
    {
        title: '乙方名称',
        dataIndex: 'yfmc',
    },
    {
        title: '签订日期',
        dataIndex: 'qdrq',
    },
    {
        title: '登记人',
        dataIndex: 'dj_name',
    },
    {
        title: '登记科室',
        dataIndex: 'dj_dept',
    },
    {
        title: '登记日期',
        dataIndex: 'dj_time',
    },
    {
        title: '备注',
        dataIndex: 'bz',
    },
    {
        title: '操作',
        render: operation,
        width: 200
    },
]



export default function CghtHt() {
    const [dataSource, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [currentPage, setPage] = useState(1);
    const [total, setTotal] = useState(0);
    const [pageSize, setPageSize] = useState(10);
    const [searchData, setSearhData] = useState({})
    const [fetchFlag, setFetchFlag] = useState(false)
    const [imgs_v, setImgsV] = useState(false)
    const [img_url_list, setImgUrlList] = useState(null)

    setDas = setData

    setImgsV2 = setImgsV

    setImgUrlList2 = setImgUrlList

    getdas = () => dataSource

    const fetchData = (cur = currentPage, size = pageSize, cond = searchData) => {
        cond.lcmc = lcmc
        if(cond.dj_time) {
            cond.dj_time_start = cond.dj_time[0]
            cond.dj_time_end = cond.dj_time[1]
            cond.dj_time = undefined
        }
        if(cond.qdrq) {
            cond.qdrq_start = cond.qdrq[0]
            cond.qdrq_end = cond.qdrq[1]
            cond.qdrq = undefined
        }
        setLoading(true);
        requests.post("/office/search_hetong_data", { ...cond, currentPage: cur, pageSize: size }).then(res => {
            setLoading(false)
            setData(res.data.result)
            setTotal(res.data.total)
        })
    };

    const handleChange = (cur, size) => {
        setPage(cur)
        setPageSize(size)
    }

    const handleSearch = () => {
        setPage(1)
        setFetchFlag(!fetchFlag)
    }

    useEffect(() => {
        fetchData(currentPage, pageSize)
    }, [currentPage, pageSize, fetchFlag])

    const { Input, DatePicker } = Form

    const export_data = () => {
        exportToExcel(columns, dataSource, lcmc)
    }

    return <>
        <div style={{ marginBottom: '10px' }}>
            <Form layout='horizontal' onValueChange={values => setSearhData(values)}>
                <Input field='htmc' label='合同名称' style={{ width: 200 }} />
                <Input field='htbh' label='合同编号' style={{ width: 200 }} />
                <Input field='jfmc' label='甲方名称' style={{ width: 200 }} />
                <Input field='yfmc' label='乙方名称' style={{ width: 200 }} />
                <DatePicker type='dateRange' field='qdrq' label='签订日期' style={{ width: 300 }} />
                <Input field='dj_name' label='登记人' style={{ width: 200 }} />
                <Input field='dj_dept' label='登记科室' style={{ width: 200 }} />
                <DatePicker type='dateRange' field='dj_time' label='登记日期' style={{ width: 300 }} />
            </Form>
            <div style={{ marginTop: '10px' }}>
                <Button theme='solid' type='primary' style={{ marginRight: 8 }} onClick={handleSearch}>搜索</Button>
                <Button theme='solid' type='primary' style={{ marginRight: 8 }} onClick={export_data}>导出数据</Button>
            </div>
        </div>

        <Table
            columns={columns}
            rowKey={'ht_id'}
            dataSource={dataSource}
            pagination={{
                currentPage,
                pageSize: pageSize,
                total: total,
                onChange: handleChange,
                showSizeChanger: true,
                pageSizeOpts: [10, 20, 30, 40, 50, 100, 500]
            }}
            loading={loading}
            bordered={true}
            sticky={true}
        />
        <ImgsShow visible={imgs_v} setvisible={setImgsV} srcList={img_url_list}/>

    </>
}
