import React, { useEffect, useRef, useState } from "react";
import { Modal, Form, Toast } from "@douyinfe/semi-ui";
import requests from "../../../../utils/request_noloading";

let form_data = {};

export default function AddXxkAccount({ visible, setVisible, fetchData }) {
  const [confirm_l, set_confirm_l] = useState(false);
  const handleOk = () => {
    set_confirm_l(true)
    requests.post("/xxk/account/add_account", form_data).then((res) => {
      Toast.success("新增成功");
      fetchData();
      setVisible(false);
    }).finally(() => {
        set_confirm_l(false)
    })
  };

  const handleAfterClose = () => {
    // fetchData()
  };
  const { Input, TextArea } = Form;

  return (
    <>
      <Modal
        title={`新增资源账号`}
        visible={visible}
        onOk={handleOk}
        afterClose={handleAfterClose} //>=1.16.0
        onCancel={() => setVisible(false)}
        okText={"新增"}
        cancelText={"取消"}
        closable={false}
        footerFill={true}
        confirmLoading={confirm_l}
        fullScreen
      >
        <Form style={{ width: "100%" }} onValueChange={(v) => (form_data = v)}>
          <Input
            size="large"
            field="name"
            label="资源名称"
            trigger="blur"
            autoComplete={"false"}
          />
          <Input
            size="large"
            field="ip"
            label="IP地址"
            trigger="blur"
            autoComplete={"false"}
          />
          <TextArea
            size="large"
            field="content"
            label="账号内容"
            trigger="blur"
            autoComplete={"false"}
            autosize={{ minRows: 5, maxRows: 20 }}
            showClear
          />
          <TextArea
            size="large"
            field="bz"
            label="备注"
            trigger="blur"
            autoComplete={"false"}
            autosize={{ minRows: 5, maxRows: 20 }}
            showClear
          />
        </Form>
      </Modal>
    </>
  );
}
