// 携带当前url跳转回登录页
export function to_login(redirect=true) {
    if(redirect) {
        window.location.href = '/login?redirect=' + window.location.pathname + window.location.search
    } else {
        window.location.href = '/login'
    }

}

// 退出登录
export function logout() {
    window.location.href = '/login?notautologin=true'
}

// 将url的查询参数转为json
export function convertUrlParamsToJson(urlParams) {
    urlParams = new URLSearchParams(urlParams)
    var jsonParams = {};
    for (var pair of urlParams.entries()) {
        jsonParams[pair[0]] = pair[1];
    }
    return jsonParams;
}