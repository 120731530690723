import { Layout,Button,Table,Toast,Modal, Checkbox, Row,Col,Form} from '@douyinfe/semi-ui';
import { IconInstagram } from '@douyinfe/semi-icons';
import React, { useState,useEffect } from 'react';
import request from "../../../../utils/request_noloading"
import "../../../../css/a.css"


export default function ShouwenChoseUser({visible,setvisible,users,setUser,formApi,field}) {
    //分页条件
    const [size, setSize] = useState(10);
    const [curPage, setcurPage] = useState(1);
    const [total, setTotal] = useState(0);
    //当前页数据
    const [das,setdas] = useState([
        {},
    ])
    //刷新
    const [shouwen_refresh, setshouwen_refresh] = useState(false)
    //表单数据
    const [formdata,formdataSetdas] = useState({})   
    //页面布局
    const { Header, Content } = Layout;
    //列名
    const columns = [
        { dataIndex: 'userid', title: 'userid'},
        { dataIndex: 'username', title: '姓名'},
        { dataIndex: 'dept_name', title: '部门' }
    ];
    //加载数据
    useEffect(() => {
        getdatas()
        setshouwen_refresh(false)
    }, [curPage, size , shouwen_refresh])
    //页码变化
    function onChange(currentPage, pageSize) {
        setcurPage(currentPage);        //当前页数
        setSize(pageSize)
    }
    //获取数据
    const getdatas = ()=>{
        request.post('/office/get_shouwen_user', {
            currentPage: curPage,                   //页码
            pageSize: size,                         //尺寸
            username: formdata.username,            //用户名  
            dept_name: formdata.dept_name,          //部门
        }).then((res)=>{
            setdas(res.data.result)
            setTotal(res.data.total)
        })
    }
    //选择用户
    const rowSelection = {
        onChange: (selectedRowKeys,selectedRows) => {
            let UserNames = selectedRows.map(x => x['username'])
            let usersname_A = users.map(x => x['username'])
            let UserN=UserNames.concat(usersname_A)
            let us = users.concat(selectedRows)
            let unique_us = Array.from(new Set(us))
            let unique_UserN = Array.from(new Set(UserN))
            setUser(unique_us);
            formApi.setValue(field,unique_UserN)
            // console.log(us);
        },
    };
    //提交
    const onOk = () => {
        setvisible(false);
    };
    return <>
        <Modal
            title="选择用户"
            visible={visible}
            onOk={onOk}
            onCancel={()=>{setvisible(false)}}
            closeOnEsc={true}
            footerFill={true}
            style={{ overflow: 'auto',width:800}}
        >

            <Form
                onSubmit={values => Toast.info({ content: JSON.stringify(values) })}
                render={({ formState, formApi, values }) => (
                    <div>
                        <Row span={24}>
                            <Col span={12}>
                                <Form.Input field='username' label="姓名" placeholder=''  prefix={<IconInstagram />} style={{ width: 176 }}/>
                            </Col>
                            <Col span={12}>
                                <Form.Input field='dept_name' label="部门" placeholder=''  prefix={<IconInstagram />} style={{ width: 176 }}/>
                            </Col>

                        </Row>
                        <Row span={24}>
                            <br></br>
                            <Col span={12}>
                                <Button onClick={()=>{
                                    setcurPage(1)
                                    getdatas()
                                }}
                                >查询</Button>
                            </Col>
                            <Col span={11} offset={1}>
                                <Button htmlType="reset" onClick={()=>{formdataSetdas({})}}>重置条件</Button>
                            </Col>
                        </Row>
                    </div>
                )}
                onValueChange={values => {
                    console.log(values);
                    formdataSetdas(values)
                }}
            >
            </Form>
            <Table 
                columns={columns} 
                dataSource={das} 
                rowSelection={rowSelection} 
                rowKey={'userid'}
                pagination={{
                    total:total,
                    showSizeChanger:true,
                    defaultCurrentPage:1,
                    currentPage:curPage,
                    pageSize:size,
                    pageSizeOpts:[10, 20, 50, 200],
                    onChange:onChange
            }} bordered={true} size={'middle'} sticky={true} />
        </Modal>
    </>
}
