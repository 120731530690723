import ErrorPage from '../error-page';
import {PermRouteWrapper, RouteWrapper} from '../component/route-wrapper';


// 普通路由对象
 export class RouteObj {
    path
    element
    errorElement
    children
    constructor(path, element, child = null) {
        this.errorElement = <ErrorPage />
        this.path = path
        this.element = element
        this.children = child
    }
}


// 需要鉴权的路由对象
export class PermRouteObj {
    path
    element
    errorElement
    children
    constructor(path, element, child = null) {
        this.errorElement = <ErrorPage />
        this.path = path
        this.element = <PermRouteWrapper element={element} />
        this.children = child
    }
}