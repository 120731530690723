import { createRoot } from 'react-dom/client';
import './index.css';
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";
import routers from './router';
import doWithAuthCode from './utils/dd_api';
import { get_data, set_data } from './utils/data_ctl';
import requests from './utils/request_noloading'
import { throttle } from './utils/user_friendly';
import { Modal } from '@douyinfe/semi-ui';
import GonghaoBind from './component/gonghao_bind';
import { debounce } from './utils/user_friendly';

// 本文件所有代码在页面加载时执行

// 钉钉调试模式
// import { initDingH5RemoteDebug } from "dingtalk-h5-remote-debug";
// initDingH5RemoteDebug();

// 初始化路由
const router = createBrowserRouter(routers);

const root = createRoot(document.getElementById('root'));

let inDing = true

// 解决开发环境报错的问题
// const _ResizeObserver = window.ResizeObserver;
// window.ResizeObserver = class ResizeObserver extends _ResizeObserver{
//    constructor(callback) {
//      callback = debounce(callback, 200);
//      super(callback);
//    }
// }



doWithAuthCode(() => { set_data('inDing', inDing) }, (err) => {
  inDing = false
  set_data('inDing', inDing)
}, () => {
  const user = get_data("user")
  // 用户没登录直接渲染页面
  if(!user) {
    root.render(
      <RouterProvider router={router} />
    );
    return
  }
  // 检测是否绑定工号
  // throttle(() => {
  //   requests.get("/skyy/user/get_self_gonghao").then(res => {
  //     if(!res.data || res.data === "") {
  //       Modal.info({title: "检测到你还未绑定工号", content: <GonghaoBind />})
  //     }
      
  //   })
  // }, 2000)()
  
  // 获取用户菜单
  requests.get("/skyy/admin/get_user_menus").then(res => {
    set_data("user_menus", res.data, true)
    const permission_paths = {}
    res.data.forEach(val => {
      if (val.path != null) {
        permission_paths[val.path] = 1
      }
    })
    set_data("permission_paths", permission_paths, true)
  }).finally(() => {
    // 渲染页面
    root.render(
      <RouterProvider router={router} />
    );
  })
})






