import React, { useRef, useState } from 'react';
import { Form, Col, Row, Button, Typography, Divider, Toast } from '@douyinfe/semi-ui';
import { IconUpload, IconFile, IconPlus, IconMinus } from '@douyinfe/semi-icons';
import request from "../../../../utils/request"
import FUpload from '../../../public/file/pages/upload';

function Element({ index, date, amount, ispay, tips }) {
    const { DatePicker, InputNumber, TextArea } = Form;
    return <>
        <Row >
            <Col span={6}>
                <DatePicker field={'deadline[' + index + ']'} type="date" label='付款日期' style={{ width: 200 }} initValue={date} />
            </Col>
            <Col span={5}>
                <InputNumber field={'div_amount[' + index + ']'} initValue={amount} style={{ width: 150 }} label="应付金额" min={-1} />
            </Col>
            <Col span={4}>
                <Form.Select field={'ispay[' + index + ']'} initValue={ispay} label="是否付款" style={{ width: 100 }}>
                    <Form.Select.Option value="是">是</Form.Select.Option>
                    <Form.Select.Option value="否">否</Form.Select.Option>
                </Form.Select>
            </Col>
            <Col span={8}>
                <TextArea field={'intro[' + index + ']'} initValue={tips} autosize={{ minRows: 1 }} style={{ width: 300 }} label="备注" showClear />
            </Col>
        </Row>
    </>
}

const formSubmit = async (url, props) => {
    await request.post(url, props).then((res) => {
        Toast.success("操作成功")
        setTimeout(() => {
            window.location.href = '/xxk/xxk_contract'
        }, 800)
    })
}


export default class ContracrInsert extends React.Component {
    constructor() {
        super();
        this.state = {
            comps: [{ 'date': null, 'amount': 0, 'ispay': '否', 'tip': "无" }]
        }
    }
    render() {
        const { Input, DatePicker, TextArea, Upload, InputNumber } = Form;
        const { Title } = Typography;
        let tmp = -1 //key值渲染
        const formRef = React.createRef();

        let htsc_url = []
        let shwj_url = []
        let cght_url = []
        let gyszz_url = []
        let qtwj_url = []


        const submitmsg = () => {
            let tm = formRef.current.formApi.getValues()
            tm["htsc_url"] = htsc_url
            tm["shwj_url"] = shwj_url
            tm["cght_url"] = cght_url
            tm["gyszz_url"] = gyszz_url
            tm["qtwj_url"] = qtwj_url
            formSubmit('/xxk_contract/from_data', tm)
        }

        return (
            <>
                <Title heading={3} style={{ margin: '8px 0' }} >中医伤科医院合同管理</Title>
                <Form
                    style={{ padding: '10px', width: 1000 }} ref={formRef} uploadTrigger='custom'>
                    <Row>
                        <Col span={12}>
                            <Input field="contractname" label="合同名称：" trigger='blur' style={{ width: 400 }} />
                        </Col>
                        <Col span={12}>
                            <DatePicker field="signdate" type="date" label='合同签订日期：' style={{ width: 400 }} placeholder='请选择生效日期' />
                        </Col>
                    </Row>

                    <Row>
                        <Col span={12}>
                            <DatePicker field="acceptdate" type="date" label='验收日期' style={{ width: 400 }} placeholder='请选择生效日期' />
                        </Col>
                        <Col span={12}>
                            <InputNumber field="expirdate" label="合同有效期(年)" min={1} max={10} defaultValue={1} />
                        </Col>
                    </Row>

                    <Row>
                        <Col span={12}>
                            <Form.Select field="userdept" label="科室" style={{ width: 400 }}>
                                <Form.Select.Option value="信息部">信息部</Form.Select.Option>
                                <Form.Select.Option value="采购部">采购部</Form.Select.Option>
                                <Form.Select.Option value="护理部">护理部</Form.Select.Option>
                            </Form.Select>
                        </Col>
                        <Col span={12}>
                            <Input field="purchaser" label="采购人" trigger='blur' style={{ width: 400 }} />
                        </Col>
                    </Row>
                    <Row>
                        <InputNumber field="amount" label="合同金额(元)" min={1} />
                    </Row>
                    <Row>
                        <TextArea
                            style={{ width: '90%', height: 100 }}
                            field='tips'
                            label='备注信息'
                            placeholder='请填写备注信息'
                        />
                    </Row>

                    <Divider margin='12px'></Divider>

                    {this.state.comps.map((item) => {               //循环渲染付款日期
                        tmp += 1
                        return <div key={tmp + ""}>
                            <Element index={tmp} date={item.date} amount={item.amount} ispay={item.ispay} tips={item.tip} />
                        </div>
                    })}

                    <Row >
                        <Col span={12}>
                            <Button onClick={
                                () => {
                                    let tmps = this.state.comps.concat()
                                    tmps.push({ 'date': null, 'amount': 0, 'ispay': '否', "tip": "无" })
                                    this.setState({
                                        comps: tmps
                                    })
                                    console.log(this.state.comps);

                                }
                            }><IconPlus /></Button>

                        </Col>
                        <Col span={12}>
                            <Button onClick={
                                () => {
                                    let tmps = this.state.comps.concat()
                                    tmps.pop()
                                    this.setState({
                                        comps: tmps
                                    })
                                }
                            }
                            ><IconMinus /></Button>
                        </Col>
                    </Row>

                    <Divider margin='12px'></Divider>

                    <Row>
                        <Col span={12}>
                            <FUpload type={"htsc"} urls={htsc_url} desc={"合同上传"} />
                        </Col>

                        <Col span={12}>
                            <FUpload type={"cght"} urls={cght_url} desc={"采购申请/合同审批"} />
                        </Col>
                    </Row>
                    <Row>
                        <Col span={12}>
                            <FUpload type={"shwj"} urls={shwj_url} desc={"上会文件"} />
                        </Col>
                        <Col span={12}>
                            <FUpload type={"gyszz"} urls={gyszz_url} desc={"供应商资质"} />
                        </Col>
                    </Row>
                    <Row>
                        <FUpload type={"qtwj"} urls={qtwj_url} desc={"其他文件"} />
                    </Row>
                    <Divider margin='12px'></Divider>

                    <Button theme='solid' type='primary' onClick={(submitmsg)}>提交</Button>
                </Form>
            </>
        );
    }
}

