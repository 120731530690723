import {
  Modal,
  Table,
  Toast,
  Timeline,
  Badge,
  Avatar,
  Space,
  Card,
} from "@douyinfe/semi-ui";
import { useEffect, useState } from "react";

export default function ShouwenLiuChengShow({ visible, setvisible, sw_id }) {
  const Cancel = () => {
    setvisible(false);
  };
  return (
    <>
      <Modal
        title="阅示进度"
        visible={visible}
        onOk={Cancel}
        onCancel={Cancel}
        closeOnEsc={true}
        footerFill={true}
      >
        <YueshiDetail sw_id={sw_id} />
      </Modal>
    </>
  );
}

function YueshiDetail({ sw_id }) {
  const [data, set_data] = useState([
    [
      { username: "邓彬", is_look: false },
      { username: "尹舒龙", is_look: true },
      { username: "尹舒龙", is_look: true },
      { username: "尹舒龙", is_look: true },
      { username: "尹舒龙", is_look: true },
      { username: "尹舒龙", is_look: true },
      { username: "尹舒龙", is_look: true },
      { username: "尹舒龙", is_look: true },
      { username: "尹舒龙", is_look: true },
      { username: "名字名", is_look: false },
    ],
    [
      { username: "邓彬", is_look: true },
      { username: "尹舒龙", is_look: false },
    ],
    [
      { username: "邓彬", is_look: true },
      { username: "尹舒龙", is_look: false },
    ],
    [
      { username: "邓彬", is_look: true },
      { username: "尹舒龙", is_look: false },
    ],
    [
      { username: "邓彬", is_look: true },
      { username: "尹舒龙", is_look: false },
    ],
    [
      { username: "邓彬", is_look: true },
      { username: "尹舒龙", is_look: false },
    ],
    [
      { username: "邓彬", is_look: true },
      { username: "尹舒龙", is_look: false },
    ],
  ]);
  return (
    <>
      <Timeline>
        {data.map((user_list) => (
          <Timeline.Item type="ongoing">
            <Card shadows="always">
              <Space spacing={20} wrap>
                {user_list.map((user) => (
                  <Badge
                    count={user.is_look ? "已阅" : "未读"}
                    position="rightBottom"
                    type={user.is_look ? "success" : "tertiary"}
                  >
                    <Avatar color="light-blue" shape="square">
                      {user.username}
                    </Avatar>
                  </Badge>
                ))}
              </Space>
            </Card>
          </Timeline.Item>
        ))}
      </Timeline>
    </>
  );
}
