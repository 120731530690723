import React, { useState, useEffect } from 'react';
import { Table, Button, Toast, Form, SplitButtonGroup, Dropdown, Typography, Modal, InputNumber } from '@douyinfe/semi-ui';
import { IconPrint, IconTreeTriangleDown } from '@douyinfe/semi-icons'
import requests from '../../../../utils/request'
import * as dateFns from 'date-fns';
import { useNavigate } from "react-router-dom";
import { get_data, set_data } from '../../../../utils/data_ctl';

let setRecord2 = null
let getdas = null
let setDas = null
let navigate2 = null

const operation = (text, record) => {
    const handleEdit = () => {
        setRecord2(record)
    }

    const handleDel = () => {
        requests.post("/skyy/exam/待开发", {
            exam_id: record.exam_id
        }).then(res => {
            Toast.success("删除成功")
            let key = record.exam_id
            const das = getdas()
            let newDataSource = [...das];
            if (key != null) {
                let idx = newDataSource.findIndex(data => data.exam_id === key);
                if (idx > -1) {
                    newDataSource.splice(idx, 1);
                    setDas(newDataSource);
                }
            }
        })
    }




    return <>
        <Button
            size='small'
            theme="solid"
            style={{ marginLeft: 10, marginRight: 10 }}
            onClick={handleEdit}
        >
            编辑
        </Button>
        <Button
            size='small'
            theme="solid"
            style={{ marginLeft: 10, marginRight: 10 }}
            onClick={() => navigate2("../exam_paperpool_detail?examPaperId=" + record.examPaperId)}
        >
            详情
        </Button>
    </>
}


const columns = [
    {
        title: '编号',
        dataIndex: 'zcbh'
    },
    {
        title: '名称',
        dataIndex: 'name',
    },
    {
        title: '规格',
        dataIndex: 'guige',
    },
    {
        title: '品牌',
        dataIndex: 'brand',
    },
    {
        title: '单位',
        dataIndex: 'unit',
    },
    {
        title: '价格',
        dataIndex: 'price',
        render: value => value + "元"
    },
    {
        title: '创建时间',
        dataIndex: 'create_time',
        render: value => {
            return dateFns.format(new Date(value), 'yyyy-MM-dd HH:mm:ss');
        }
    },
    {
        title: '备注',
        dataIndex: 'bz',
    },
    // {
    //     title: '操作',
    //     render: operation,
    //     width: 250
    // },
]

let selectedData = []

export default function DzyhpList() {
    const [dataSource, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [currentPage, setPage] = useState(1);
    const [total, setTotal] = useState(0);
    const [pageSize, setPageSize] = useState(10);
    const [record, setRecord] = useState({})
    const [searchData, setSearhData] = useState({})
    const [fetchFlag, setFetchFlag] = useState(false)
    const navigate = useNavigate()
    const [btnVisible, setBtnVisible] = useState(false);
    const [printWidthAndHeight, setPWAH] = useState({
        width: get_data("dzyhp_print_width") || 6,
        height: get_data("dzyhp_print_height") || 4
    })

    const [printEditVisible, setPrintEditV] = useState(false)

    setRecord2 = setRecord

    setDas = setData

    navigate2 = navigate

    getdas = () => dataSource

    const fetchData = (cur = currentPage, size = pageSize, cond = searchData) => {
        setLoading(true);
        requests.post("/xzhq/dzyhp/get_dzyhp_list", { ...cond, currentPage: cur, pageSize: size }).then(res => {
            setLoading(false)
            setData(res.data.result)
            setTotal(res.data.total)
        })
    };

    const handleChange = (cur, size) => {
        selectedData = []
        setPage(cur)
        setPageSize(size)
    };

    const handleSearch = () => {
        setPage(1)
        setFetchFlag(!fetchFlag)
    }

    useEffect(() => {
        fetchData(currentPage, pageSize)
    }, [currentPage, pageSize, fetchFlag])


    const handlePrint = () => {
        if (selectedData.length === 0) {
            Toast.warning("请选择需要打印的内容!")
            return
        }
        let content = ""

        for (let i in selectedData) {
            const record = selectedData[i]
            content += `
            <div class="barcode-wrapper">
            <div class="barcode-title">株洲市中医伤科医院低值易耗品</div>
            <div class="barcode-content">
                <div>
                    <div>资产编号: ${record.dzyhp_id}</div>
                    <div>资产名称: ${record.name || "无"}</div>
                    <div>规格型号: ${record.guige || "无"}</div>
                    <div>资产品牌: ${record.brand || "无"}</div>
                    <div>资产价格: ${record.price + "元" || "无"}</div>
                    <div>资产单位: ${record.unit || "无"}</div>
                    <div>创建时间: ${dateFns.format(new Date(record.create_time), 'yyyy-MM-dd') || "无"}</div>
                </div>
            </div>
            </div>
            `
        }

        // 创建一个新的窗口或 iframe
        const printWindow = window.open('', '_blank', 'width=600,height=600');
        printWindow.document.open();
        printWindow.document.write(`
      <html>
        <head>
          <title>条码打印</title>
          <style>
            @media print {
            body {
                margin: 0;
                padding: 0;
            }
            .barcode-wrapper {
                width: ${printWidthAndHeight.width}cm; /* 设置条码纸的宽度 */
                height: ${printWidthAndHeight.height}cm; /* 设置条码纸的高度 */
                overflow: hidden; /* 防止内容溢出 */
                page-break-inside: avoid; /* 避免内部分页 */
            }
            .barcode-title {
                display: flex;
                justify-content: center;
                align-items: center;
                text-align: center;
                font-size: 13px; /* 根据需要调整字体大小 */
            }
            .barcode-content {
                width: 100%;
                height: 100%;
                display: flex;
                justify-content: left;
                align-items: left;
                text-align: left;
                font-size: 12px; /* 根据需要调整字体大小 */
            }
        }
          </style>
        </head>
        <body>
            ${content}
        </body>
      </html>
    `);
        printWindow.document.close();

        // 延迟打印以确保内容已经完全加载
        printWindow.onload = () => {
            printWindow.print();
            printWindow.close();
        };
    }

    const { Input } = Form

    const rowSelection = {
        onChange: (selectedRowKeys, selectedRows) => {
            selectedData = selectedRows
        },
    };

    const menu = [
        { node: 'item', name: '调整打印大小', onClick: () => setPrintEditV(true) },
    ];

    const { Text } = Typography;

    const handlePrintEditOk = () => {
        const new_data = {
            width: tmp_print_edit_data.width ? tmp_print_edit_data.width : printWidthAndHeight.width,
            height: tmp_print_edit_data.height ? tmp_print_edit_data.height : printWidthAndHeight.height
        }
        // 将新数据存入cookie
        set_data("dzyhp_print_width", new_data.width, false, 3153600000)
        set_data("dzyhp_print_height", new_data.height, false, 3153600000)
        setPWAH(new_data)
        setPrintEditV(false)
    }

    const handlePrintEditCacel = () => {
        setPrintEditV(false)
    }

    let tmp_print_edit_data = {}

    return <>
        <div style={{ marginBottom: '10px' }}>
            {/* <span>搜索与更新条件筛选</span>
            <Form layout='horizontal' onValueChange={values => setSearhData(values)}>
                <Input field='user_dept' label='科室' style={{ width: 150 }} />
                <Input
                    field='user_name'
                    label={{
                        text: '姓名',
                    }}
                    style={{ width: 176 }}
                />
                <Input field='examPaperName' label='考试名称' style={{ width: 300 }} />
            </Form> */}
            <div style={{ marginTop: '10px' }}>
                {/* <Button theme='solid' type='primary' style={{ marginRight: 8 }} onClick={handleSearch}>搜索</Button> */}
                <SplitButtonGroup style={{ marginRight: 10 }} aria-label="项目操作按钮组">
                    <Button icon={<IconPrint />} theme='solid' type='primary' onClick={handlePrint}>打印</Button>
                    <Dropdown onVisibleChange={(v) => setBtnVisible(v)} menu={menu} trigger="click" position="bottomRight">
                        <Button style={btnVisible ? { background: 'var(--semi-color-primary-hover)', padding: '8px 4px' } : { padding: '8px 4px' }} theme="solid" type="primary" icon={<IconTreeTriangleDown />}></Button>
                    </Dropdown>
                </SplitButtonGroup>

            </div>

        </div>
        <Text style={{marginBottom: '5px'}} link underline onClick={() => setPrintEditV(true)}>当前打印参数➡️ 宽度(width): {printWidthAndHeight.width}cm, 高度(height): {printWidthAndHeight.height}cm</Text>

        

        <Table
            columns={columns}
            rowKey={'dzyhp_id'}
            dataSource={dataSource}
            pagination={{
                currentPage,
                pageSize: pageSize,
                total: total,
                onChange: handleChange,
                showSizeChanger: true,
                pageSizeOpts: [10, 20, 30, 40, 50, 100, 500]
            }}
            rowSelection={rowSelection}
            loading={loading}
            bordered={true}
            sticky={true}
        />
        <Modal
                title="修改打印参数"
                visible={printEditVisible}
                onOk={handlePrintEditOk}
                onCancel={handlePrintEditCacel}
                closeOnEsc={true}
            >
                <label>宽度(width): </label>
                <InputNumber onChange={v => tmp_print_edit_data["width"] = v} innerButtons={true} suffix={'cm'} defaultValue={printWidthAndHeight.width} style={{ width: 120 }} />
                <br/><br/>
                <label>高度(height): </label>
                <InputNumber onChange={v => tmp_print_edit_data["height"] = v} innerButtons={true} suffix={'cm'} defaultValue={printWidthAndHeight.height} style={{ width: 120 }} />
            </Modal>
    </>
}