import React, { useEffect, useState } from 'react';
import { Button, Modal, Form, Toast } from '@douyinfe/semi-ui';
import requests from '../../../../utils/request'

let form_data = {}

export default function ExamUserStateEditor({ record, visible, setVisible, fetchData }) {

    if (visible && JSON.stringify(record) !== "{}") {
        form_data = record
    }

    const handleOk = () => {
        console.log(form_data)
        requests.post("/skyy/exam/update_exam_userstate", form_data).then(res => {
            Toast.success("修改成功")
            fetchData()
            setVisible(false)
        })
        // setVisible(false)
    }

    const handleAfterClose = () => {
        // fetchData()
    }
    const { Input,Select } = Form



    return <>
        <Modal
            title={`编辑用户答题记录`}
            visible={visible}
            onOk={handleOk}
            afterClose={handleAfterClose} //>=1.16.0
            onCancel={() => setVisible(false)}
            okText={'保存修改'}
            cancelText={'取消'}
            closable={false}
            width={700}
        >
            <Form
                initValues={record}
                style={{ padding: 10, width: '100%' }}
                onValueChange={(v) => form_data = v}
                labelPosition='left'
            >
                <Input
                    field="user_dept"
                    label="科室"
                    trigger='blur'
                    autoComplete={"false"}
                    disabled
                />
                <Input
                    field="user_name"
                    label="姓名"
                    trigger='blur'
                    autoComplete={"false"}
                    disabled
                />
                <Input
                    field="user_userid"
                    label="用户id"
                    trigger='blur'
                    autoComplete={"false"}
                    disabled
                />
                <Input
                    field="examPaperName"
                    label="试卷名称"
                    trigger='blur'
                    autoComplete={"false"}
                    disabled
                />
                <Select 
                    label="答题状态" 
                    trigger='blur'
                    field="user_state"
                    style={{ width: 180 }} 
                    optionList={[
                        { value: '考试结束', label: '考试结束', otherKey: 0 },
                        { value: '已参加考试', label: '已参加考试', otherKey: 1 },
                    ]}
                    defaultValue={{ value: record.user_state, label: record.user_state, otherKey: 2 }}
                />
                {/* <Input
                    field="user_state"
                    label="答题状态"
                    trigger='blur'
                    autoComplete={"false"}
                /> */}
            </Form>

        </Modal>
    </>
}