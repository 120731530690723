import { Layout,Button,Table,Toast} from '@douyinfe/semi-ui';
import MyButton from "../../../../pages/MyButton";
import React, { useState,useEffect } from 'react';
import request from "../../../../utils/request_noloading"
import "../../../../css/a.css"
import ImgsShow  from './imgs_show';
import AddGonShi from "./add_gonshi"
import DelButton from '../../../../component/del_button';

export default function DzyhpList() {
    //页面布局
    const { Header, Content } = Layout;
    //图片
    const [srcList, setsrcList] = useState([])
    //列名
    const columns = [
        { dataIndex: 'title', title: '标题'}
        , { dataIndex: 'content', title: '文字内容'}
        , { dataIndex: 'submit_date', title: '时间'}
        , { dataIndex: 'submit_user', title: '发布人'}
        , {title: '操作',width:180,dataIndex: 'operate'
            , render: (text, record) => (
                <>
                    <Button  onClick={() => {
                        request.get("/file/file_list",{
                            params:{
                                type: "yjk_gonshi",
                                item_id: record.id
                            }
                        }).then(res => {
                            let L= []
                            res.data.forEach(element => {
                                L.push(process.env.REACT_APP_STATIC_FILE_PATH + element.path);
                            });
                            if(L.length > 0){
                                setsrcList(L)
                                setshow_img(true)
                            }else{
                                Toast.warning("暂无附件")
                            }
                        })
                    }} >查看图片</Button>&nbsp;
                    <DelButton 
                        onOk={()=>{
                            del_date(record)
                        }}
                    ></DelButton>
                </>
            ),
        }
    ];
    const del_date = (record)=>{
        request.get("/yjk/gongshi_date_delete",{
        params:{
            type: "yjk_gonshi",
            item_id: record.id
        }
        }).then(res => {
            Toast.success("删除成功")
            getdtats()
        })
    }
    //刷新
    const [yjk_refresh, setyjk_refresh] = useState(false)
    //标志
    const [show_img, setshow_img] = useState(false)
    //分页条件
    const [size, setSize] = useState(10);
    const [curPage, setcurPage] = useState(1);
    const [total, setTotal] = useState(0);
    //当前页数据
    const [das,setdas] = useState([
        {},
    ])
    //所有页数据
    let exeDas=[]
    const setEx = (data) => {
        exeDas = data
    }
    //加载数据
    useEffect(() => {
        getdtats(curPage, size)
        setyjk_refresh(false)
    }, [curPage, size , yjk_refresh])
    //导出excel数据
    // const downloadexcel = async ()=>{
    //     await getDatas({
    //         currentPage: -1,                        //页码
    //         pageSize: size,                         //尺寸
    //     },setEx)
    //     exportToExcel(columns,exeDas,'表数据')
    // }
    //获取当页数据
    const getdtats = ()=>{
        getDatas({
            currentPage: curPage,                   //页码
            pageSize: size,                         //尺寸
        },setdas)
    }
    //发送请求-查询数据
    const getDatas= async (props,setDate)=>{
        await request.post('/yjk/gongshi_date', props).then((res)=>{
            setDate(res.data.result)
            setTotal(res.data.total)
        })
    }
    //页码变化
    function onChange(currentPage, pageSize) {
        getDatas({
            currentPage: currentPage,                   //页码
            pageSize: pageSize,                         //尺寸
        }, setdas)
        setcurPage(currentPage);        //当前页数
        setSize(pageSize)
    }
    //标志
    const [add_gonshi_flage, setadd_gonshi_flage] = useState(false)
    //添加公示
    const add_gonshi = ()=>{
        setadd_gonshi_flage(true)
    }
    return <>
        <Layout className="components-layout-demo">
            <Header>        
                <MyButton name="新增公示" clicfun={add_gonshi}/>
            </Header>
            <Content >
                <AddGonShi 
                    visible={add_gonshi_flage}
                    set_visible = {setadd_gonshi_flage}
                    setrefresh = {setyjk_refresh}
                />
                <ImgsShow
                    visible={show_img}
                    setvisible = {setshow_img}
                    srcList = {srcList}
                />
                <br></br><Table columns={columns} dataSource={das} pagination={{
                    total:total,
                    showSizeChanger:true,
                    defaultCurrentPage:1,
                    currentPage:curPage,
                    pageSize:size,
                    pageSizeOpts:[10, 20, 50, 200],
                    onChange:onChange
                }} bordered={true} size={'middle'} sticky={true} />
            </Content>
        </Layout>
    </>
}
