import { Modal, Toast } from '@douyinfe/semi-ui';
import { UserGroupEditor } from './user_group_editor'



export default function EditUser({ visible, set_visible, user_data, update_user_group,user_group_list, selected_group_list, setSelectedGroupList }) {
    const handleOk = () => {
        update_user_group(selected_group_list, user_data.user_id).then(res => {
            Toast.success("修改成功")
            set_visible(false)
        })
    };
    const handleCancel = () => {
        set_visible(false);
    };
    const handleAfterClose = () => {
        console.log('After Close callback executed');
    };
    return <>
        <Modal
            title={`编辑“${user_data.username}”的用户组`}
            visible={visible}
            onOk={handleOk}
            afterClose={handleAfterClose} //>=1.16.0
            onCancel={handleCancel}
            okText={'保存修改'}
            cancelText={'取消'}
            width={950}
            closable={false}
        >
            <UserGroupEditor
                d={user_group_list}
                user_data={user_data}
                selected_group_list={selected_group_list}
                set_selected_group_list={setSelectedGroupList}
            />
        </Modal>
    </>
}