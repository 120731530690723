import { Button, Input, Checkbox } from '@douyinfe/semi-ui';

export default function PwdLogin({ pt, handleSubmit }) {
    return <>
        <Input id="-1" value={pt.state.username} onChange={pt.onChange} prefix="账号" size="large"></Input>
        <br />
        <br />
        <Input id="-2" value={pt.state.password} onChange={pt.onChange} mode="password" prefix="密码" size="large"></Input>
        <br />
        <br />
        <Checkbox id="-3" check={pt.state.remember} onChange={pt.onRememberChange} aria-label="demo">记住密码</Checkbox>
        <br />
        <Button block={true} size="large" theme='solid' type='primary' onClick={handleSubmit}>登 录</Button>
    </>
}