import React from 'react';
import { Form, Button, Toast } from '@douyinfe/semi-ui';
import requests from "../../../../utils/request"


export default function InsertDzyhp() {
    const { Input, TextArea, InputNumber } = Form

    let datas = {}

    const handleAdd = () => {
        console.log(datas)
        requests.post("/xzhq/dzyhp/add_dzyhp", datas).then(res => {
            Toast.success("新增成功")
        })
    }
    return <>
        <h2>新增低值易耗品</h2>
        <Form labelPosition={"left"} style={{ width: '50%' }} render={({ formState, formApi, values }) => (
            <>
                <Input field="name" label='名称'></Input>
                <Input field="guige" label='规格'></Input>
                <Input field="brand" label='品牌'></Input>
                <TextArea field="bz" label='备注'></TextArea>
                <Input field="unit" label='单位' style={{width: '100px'}}></Input>
                <InputNumber
                    field="price"
                    label='价格'
                    innerButtons={true}
                    suffix={'元'}
                    precision={2}

                />
            </>
        )} onValueChange={values => datas = values}>
        </Form>

        <Button theme='solid' type='primary' onClick={handleAdd}>新增</Button>
    </>
}