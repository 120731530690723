import React, { useEffect, useState } from 'react';
import { Button, Modal, Form, Toast } from '@douyinfe/semi-ui';
import requests from '../../../../utils/request'


let form_data = {}

export default function ExamPoolEditor({ record, visible, setVisible, fetchData }) {

    if (visible && JSON.stringify(record) !== "{}") {
        console.log(record)
        if(typeof record.exam_answer == 'string'){
            record.exam_answer = JSON.parse(record.exam_answer)
        }
        form_data = record
    }

    const handleOk = () => {
        requests.post("/skyy/exam/exampool_editor_update", form_data).then(res => {
            Toast.success({duration:1,title:"修改成功"})
            setVisible(false)
            fetchData()
        })
    }

    const handleAfterClose = () => {
        // fetchData()
    }
    const { Input, Section, RadioGroup, Radio } = Form



    return <>
        <Modal
            title={`编辑题目`}
            visible={visible}
            onOk={handleOk}
            afterClose={handleAfterClose} //>=1.16.0
            onCancel={() => setVisible(false)}
            onValueChange={(v)=> form_data=v }
            okText={'保存修改'}
            cancelText={'取消'}
            closable={false}
            width={900}
        >
            <Form
                initValues={record}
                style={{ padding: 10, width: '100%' }}
                onValueChange={(v) => form_data = v}
                labelPosition='left'
            >
                <RadioGroup field="exam_medicate" label='医学分类' rules={[
                    { required: true, message: '必须选择医学分类 ' }
                ]}>
                    <Radio value="中医">中医</Radio>
                    <Radio value="西医">西医</Radio>
                </RadioGroup>

                <RadioGroup field="exam_category" label='题型分类' rules={[
                    { required: true, message: '必须选择题型分类 ' }
                ]}>
                    <Radio value="单选题">单选题</Radio>
                    <Radio value="多选题">多选题</Radio>
                </RadioGroup>
                <Input
                    field="exam_topic"
                    label="试题题目"
                    trigger='blur'
                    autoComplete={"false"}
                />
                <Section text={'考题选项'}>
                    <Input
                        field="exam_answer['0']"
                        label="选项A"
                        trigger='blur'
                        autoComplete={"false"}
                    />
                    <Input
                        field="exam_answer['1']"
                        label="选项B"
                        trigger='blur'
                        autoComplete={"false"}
                    />
                    <Input
                        field="exam_answer['2']"
                        label="选项C"
                        trigger='blur'
                        autoComplete={"false"}
                    />
                    <Input
                        field="exam_answer['3']"
                        label="选项D"
                        trigger='blur'
                        autoComplete={"false"}
                    />
                    <Input
                        field="exam_answer['4']"
                        label="选项E"
                        trigger='blur'
                        autoComplete={"false"}
                    />
                    <Input
                        field="exam_answer['5']"
                        label="选项F"
                        trigger='blur'
                        autoComplete={"false"}
                    />
                    <Input
                        field="exam_answer['6']"
                        label="选项G"
                        trigger='blur'
                        autoComplete={"false"}
                    />
                    <Input
                        field="exam_answer['7']"
                        label="选项H"
                        trigger='blur'
                        autoComplete={"false"}
                    />
                </Section>
            </Form>

        </Modal>
    </>
}