import requests from '../../../../utils/request'
import {React,useState}from "react"
import { Modal, Form, Toast } from '@douyinfe/semi-ui';

export default function UserMsgEditor({visible,setVisible,value}){
    // const [form_data, setFormData] = useState({
    //     id: value.user_id, 
    //     username: value.username, 
    //     dept: value.dept, 
    //     gonghao: value.gonghao
    // });
    let form_data= {
        user_id: value.user_id, 
        username: value.username, 
        dept: value.dept, 
        gonghao: value.gonghao
    }



    const handleOk = () => {
        requests.post("/skyy/admin/update_user_data",form_data).then((res) => {
            Toast.info("更新成功");
        });
        
        setVisible(false)
    };

    return <>
        <Modal
            title={`编辑题目`}
            visible={visible}
            onOk={handleOk}
            onCancel={()=>setVisible(false)}
            // onValueChange={(v)=> form_data=setFormData(v) }
            okText={'保存修改'}
            cancelText={'取消'}
            closable={false}
            width={900}
        >
            <Form
                style={{ padding: 10, width: '100%' }}
                onValueChange={(v) => {form_data=v;console.log(form_data);}}
                labelPosition='left'
            >
                <Form.Input
                    field="user_id"
                    disabled={true}
                    label="user_id"
                    initValue={value.user_id}
                    trigger='blur'
                    autoComplete={"false"}
                />
                <Form.Input
                    field="username"
                    label="用户名"
                    initValue={value.username}
                    trigger='blur'
                    autoComplete={"false"}
                />
                <Form.Input
                    field="dept"
                    label="部 门"
                    initValue={value.dept}
                    trigger='blur'
                    autoComplete={"false"}
                />
                <Form.Input
                    field="gonghao"
                    label="工号"
                    initValue={value.gonghao}
                    trigger='blur'
                    autoComplete={"false"}
                />
            </Form>
        </Modal>
    </>
    
}