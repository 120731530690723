import { React, useEffect, useState, useRef } from "react";
import {
  Space,
  Descriptions,
  Toast,
  Row,
  Button,
  TextArea,
  Col,
  Form,
} from "@douyinfe/semi-ui";
import requests from "../../../../utils/request_noloading";
import FileList from "../../../public/file/pages/file_list";
import { convertUrlParamsToJson } from "../../../../utils/location_util";
import {
  userA_bz,
  userB_bz,
  renderTagItem,
} from "../houduan/js/shouwen_upload";
import DingUserChose from "../../../../component/ding_user_chose";

// 领导批示
let ldps = null;

// 需要新增用户的表单字段名称
let field_name = null;

const userC_bz = "传阅领导";
const userD_bz = "阅示完毕后处理人";

// 传阅领导(默认值)
const C_init = [
  {
    username: "邹家全",
    userid: "01293531133836352747",
    bz: userC_bz,
  },
];

// 阅示完毕后处理人(默认值)
const D_init = [
  {
    username: "谭佳宁",
    userid: "01313100151735139419",
    bz: userD_bz,
  },
];

export default function ShouWenYueshi() {
  const [sw_data, set_sw_data] = useState({});
  const [yuewen_user_data, set_yuewen_user_data] = useState({});
  const [ldps_data, set_ldps_data] = useState("");
  const [load, set_load] = useState(false);
  const formApi = useRef();
  const [user_chose_v, set_user_chose_v] = useState(false);
  // 当前选择框的备注
  const [cur_bz, set_cur_bz] = useState(null);
  const sw_id = convertUrlParamsToJson(window.location.search)["sw_id"];
  // 当前用户所在顺序
  const order = convertUrlParamsToJson(window.location.search)["order"];
  useEffect(() => {
    if (sw_id) {
      requests
        .get("/office/get_shouwen_data_by_id", {
          params: { sw_id: sw_id },
        })
        .then((res) => {
          set_sw_data(res.data);
        });
      requests
        .get("/office/get_shouwen_liucheng_by_userid", {
          params: { sw_id: sw_id, order: order},
        })
        .then((res) => {
          set_yuewen_user_data(res.data.user);
          let str = "";
          for (let i in res.data.ldps) {
            str += res.data.ldps[i].ldps + "\n";
          }
          set_ldps_data(str);
        });
    }
  }, []);

  const handle_yi_yue = () => {
    set_load(true);
    const up_data = {
      sw_id: sw_id,
      order: order
    };
    // 数据处理
    if (yuewen_user_data.bz === userA_bz) {
      up_data["ldps"] = ldps;
    }
    if (yuewen_user_data.bz === userB_bz) {
      const l = [];
      l.push(formApi.current.getValue("User_C"));
      l.push(formApi.current.getValue("User_D"));
      up_data["users"] = l;
    }
    console.log(up_data);
    requests
      .post("/office/set_yi_yue", up_data)
      .then((res) => {
        if (res.code === 200) {
          Toast.info("操作成功");
        } else {
          Toast.warning({ content: res.msg, duration: 5 });
        }
      })
      .finally(() => {
        set_load(false);
      });
  };

  const handleLdpsChange = (val) => {
    ldps = val;
  };

  // 点击选择按钮调用
  const handleSelectUser = (f_name, bz) => {
    field_name = f_name;
    set_cur_bz(bz);
    set_user_chose_v(true);
  };

  // 选择好用户后的回调
  const onSelectUser = (users) => {
    users = users.map((user) => {
      user.bz = cur_bz;
      return user;
    });
    let new_users = formApi.current.getValue(field_name);
    new_users = new_users.concat(users);
    // 去重

    formApi.current.setValue(field_name, new_users);
  };

  const { TagInput } = Form;

  return (
    <>
      <DingUserChose
        visible={user_chose_v}
        setvisible={set_user_chose_v}
        onSelect={onSelectUser}
      />
      <div style={{ margin: 20 }}>
        <Row>
          <center>
            <h2>收文阅示</h2>
          </center>
          <Descriptions>
            <Descriptions.Item itemKey="发文单位">
              {sw_data["fwdw"]}
            </Descriptions.Item>
            <Descriptions.Item itemKey="发文时间">
              {sw_data["fwsj"]}
            </Descriptions.Item>
            <Descriptions.Item itemKey="原文编号">
              {sw_data["ywbh"]}
            </Descriptions.Item>
            <Descriptions.Item itemKey="原文标题">
              {sw_data["ywbt"]}
            </Descriptions.Item>
            <Descriptions.Item itemKey="收文时间">
              {sw_data["swsj"]}
            </Descriptions.Item>
            <Descriptions.Item itemKey="收文途径">
              {sw_data["swtj"]}
            </Descriptions.Item>
            <Descriptions.Item itemKey="领导批示">
              {ldps_data}
            </Descriptions.Item>
            <Descriptions.Item itemKey="批阅范围">
              {sw_data["pyfw"]}
            </Descriptions.Item>
            <Descriptions.Item itemKey="备注">
              {sw_data["bz"]}
            </Descriptions.Item>
          </Descriptions>
        </Row>

        <Row>
          <h4>附件查看</h4>
          <FileList item_id={sw_id} type="shouwen" show_download={true} />
        </Row>

        {yuewen_user_data.bz === userA_bz ? (
          <Row>
            <h4>领导批示</h4>
            <TextArea
              rows={5}
              placeholder="输入批示内容..."
              onChange={handleLdpsChange}
            />
          </Row>
        ) : null}
        {yuewen_user_data.bz === userB_bz ? (
          <>
            <Form
              style={{ padding: 10, width: "90%" }}
              getFormApi={(fApi) => {
                formApi.current = fApi;
              }}
            >
              {({ formState }) => (
                <>
                  <Row>
                    <Col span={20}>
                      <TagInput
                        field="User_C"
                        allowDuplicates={false}
                        label="传阅领导"
                        initValue={C_init}
                        style={{ width: "90%" }}
                        renderTagItem={(value, index, onClose) =>
                          renderTagItem(value, index, onClose)
                        }
                      />
                    </Col>
                    <Col offset={1} span={3}>
                      <Button
                        type="primary"
                        style={{ marginTop: 12 }}
                        onClick={() => {
                          handleSelectUser("User_C", userC_bz);
                        }}
                      >
                        新增
                      </Button>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <TagInput
                        field="User_D"
                        label="阅示完毕后处理人"
                        initValue={D_init}
                        style={{ width: "90%" }}
                        renderTagItem={(value, index, onClose) =>
                          renderTagItem(value, index, onClose)
                        }
                      />
                    </Col>
                    <Col offset={1} span={3}>
                      <Button
                        type="primary"
                        style={{ marginTop: 12 }}
                        onClick={() => {
                          handleSelectUser("User_D", userD_bz);
                        }}
                      >
                        新增
                      </Button>
                    </Col>
                  </Row>
                </>
              )}
            </Form>
          </>
        ) : null}
        {yuewen_user_data.bz !== userD_bz ? (<Row>
          <Button
            style={{ marginBottom: 20, marginTop: 30 }}
            size="large"
            theme="solid"
            loading={load}
            onClick={handle_yi_yue}
            block
          >
            已 阅
          </Button>
        </Row>) : null}
        
      </div>
    </>
  );
}
