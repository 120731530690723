export default  function exportToExcel (columns,data, fileName){
    let arr = columns.map(val => val.dataIndex)
    let arr2 = columns.map(val => val.title)
    if(arr2[arr2.length -1] === "操作") {
        arr.pop()
        arr2.pop()
    }
    
    let str = arr2.join(",") + "\n"

    // let str = `用户名,时间,坐标,来源,授权时间\n`;
    //增加\t为了不让表格显示科学计数法或者其他格式
    for(let i = 0 ; i < data.length ; i++ ){
        for(let ii = 0; ii < arr.length; ii++){
            const s = data[i][arr[ii]]
            if(typeof s === "string") {
                str+=s == null || s === undefined ? "" : s.replace(/,/g, "，")
            } else {
                str+=s == null || s === undefined ? "" : s
            }
            
            str+="\t,"   
        }
        str+='\n';
    }
    //encodeURIComponent解决中文乱码
    let uri = 'data:text/csv;charset=utf-8,\ufeff' + encodeURIComponent(str);
    //通过创建a标签实现
    let link = document.createElement("a");
    link.href = uri;
    //对下载的文件命名
    link.download = `${fileName || '表格数据'}.csv`;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
}