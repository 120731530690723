import {React,useState,useEffect} from 'react';
import { Form, Toast, Row, Col,  Button ,Modal} from '@douyinfe/semi-ui';
import requests from  "../../../../utils/request_noloading"

export default function ShouWenTaiZhangModify({visible,setvisible,value,setrefresh}) {
    const [dates,setdates] = useState({})
    //加载数据
    useEffect(() => {
        setdates(value)
    }, [value])
    //更新数据
    const onOk = (dates)=>{
        dates.update_time = new Date()
        dates.id = value.id
        let tmp = dates
        Object.keys(dates).forEach((key)=>{
            if(dates[key] == null){
                delete tmp[key]
            }
        })
        requests.post("/office/update_shouwen", tmp).then(da => {
            setvisible(false)
            setrefresh(true)
        })
    }

    return <>
        <Modal
            title="收文填写"
            visible={visible}
            onOk={()=>{onOk(dates)}}
            onCancel={()=>{setvisible(false)}}
            closeOnEsc={true}
            footerFill={true}
            bodyStyle={{height: 500,width: 400 }}
            width={800}
            height={800}
        >
            <Form
                onSubmit={values => Toast.info({ content: JSON.stringify(values) })}
                labelPosition={'left'} labelWidth={'100px'} labelAlign={'left'} style={{ padding: '5px', width: 700 }}
                render={({ formState, formApi, values }) => (
                    <div>
                        <Row>
                            <Col span={24}>
                                <Form.Input field='fwdw' initValue={value.fwdw} label="发文单位" placeholder=''/>
                                <Form.DatePicker field='fwsj' initValue={value.fwsj}  label="发文时间"  />
                                <Form.Input field='ywbh'  initValue={value.ywbh}  label="原文编号" placeholder=''/>
                                <Form.Input field='ywbt'  initValue={value.ywbt}  label="原文标题" placeholder=''/>
                                <Form.DatePicker field='swsj'  initValue={value.swsj}  label="收文时间" />
                                <Form.Input field='swsxh'  initValue={value.swsxh}  label="收文顺序号" placeholder=''/>
                                <Form.Select field='swtj'  initValue={value.swtj} label="收文途径"   style={{ width: '250px' }}>
                                    <Form.Select.Option value="OA">OA</Form.Select.Option>
                                    <Form.Select.Option value="微信">微信</Form.Select.Option>
                                </Form.Select>
                                <Form.Select  field='pyfw' initValue={value.pyfw}   label="批阅范围" style={{ width: '250px' }}>
                                    <Form.Select.Option  value="请龙书记阅示">请龙书记阅示</Form.Select.Option>
                                </Form.Select>
                                <Form.Input field='ldps'  initValue={value.ldps}  label="领导批示" placeholder=''/>
                                <Form.DatePicker field='sx'  initValue={value.sx}  label="时限" />
                                <Form.TextArea field="bz"  initValue={value.bz}  label="备注" placeholder="文字内容" size="large"></Form.TextArea>
                            </Col>
                        </Row>
                    </div>
                )}
                onValueChange={values => {
                    setdates(values)
                }}
            >
            </Form>
        </Modal>
        </>
}