import React, { useState, useEffect } from 'react';
import { Table, Button, Toast } from '@douyinfe/semi-ui';
import requests from '../../../../utils/request'
import ExamPaperPoolAdd from '../pages/exam_paperpool_add';
import DelButton from '../../../../component/del_button';
import * as dateFns from 'date-fns';
import ExamPaperPoolEditor from '../pages/exam_paperpool_editor';
import { useNavigate } from "react-router-dom";

let setRecord2 = null
let setPoolEditorVisible2 = null
let getdas = null
let setDas = null
let navigate2 = null

const operation = (text, record) => {
    const handleEdit = () => {
        setRecord2(record)
        setPoolEditorVisible2(true)
    }

    const handleDel = () => {
        requests.post("/skyy/exam/del_exampaperpool", {
            examPaperId: record.examPaperId
        }).then(res => {
            Toast.success("删除成功")
            let key = record.examPaperId
            const das = getdas()
            let newDataSource = [...das];
            if (key != null) {
                let idx = newDataSource.findIndex(data => data.examPaperId === key);
                if (idx > -1) {
                    newDataSource.splice(idx, 1);
                    setDas(newDataSource);
                }
            }
        })
    }
    return <>
        <Button
            size='small'
            theme="solid"
            style={{ marginLeft: 10, marginRight: 10 }}
            onClick={handleEdit}
        >
            编辑
        </Button>
        <Button
            size='small'
            theme="solid"
            style={{ marginLeft: 10, marginRight: 10 }}
            onClick={() => navigate2("../exam_paperpool_detail?examPaperId=" + record.examPaperId)}
        >
            详情
        </Button>
        <DelButton onOk={handleDel} desc='删除' />
    </>
}

const columns = [
    {
        title: '试题名称',
        dataIndex: 'examPaperName',
    },
    {
        title: '考试类型',
        dataIndex: 'examType',
    },
    {
        title: '考试开始时间',
        dataIndex: 'examStartTime',
        render: value => {
            return dateFns.format(new Date(value), 'yyyy-MM-dd HH:mm:ss');
        }
    },
    {
        title: '考试结束时间',
        dataIndex: 'examEndtime',
        render: value => {
            return dateFns.format(new Date(value), 'yyyy-MM-dd HH:mm:ss');
        }
    },
    {
        title: '密码',
        dataIndex: 'examPassword',
    },
    {
        title: '工种',
        dataIndex: 'examWorkcategory',
    },
    {
        title: '中医单选',
        dataIndex: 'examzhonyisingle',
    },
    {
        title: '中医多选',
        dataIndex: 'examzhonyidouble',
    },
    {
        title: '西医单选',
        dataIndex: 'examxiyisingle',
    },
    {
        title: '西医多选',
        dataIndex: 'examxiyidouble',
    },
    {
        title: '操作',
        render: operation,
        width: 250
    },
]



export default function ExamPaperPool() {
    const [dataSource, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [currentPage, setPage] = useState(1);
    const [total, setTotal] = useState(0);
    const [pageSize, setPageSize] = useState(10);
    const [record, setRecord] = useState({})
    const [poolEditorVisible, setPoolEditorVisible] = useState(false)
    const [paperpoolAddVisible, setPaperpoolAddVisible] = useState(false)
    const navigate = useNavigate()

    setRecord2 = setRecord

    setPoolEditorVisible2 = setPoolEditorVisible

    setDas = setData

    navigate2 = navigate

    getdas = () => dataSource

    const fetchData = (cur = currentPage, size = pageSize) => {
        setLoading(true);
        requests.post("/skyy/exam/exam_paperpool_list", { currentPage: cur, pageSize: size }).then(res => {
            setLoading(false)
            setData(res.data.result)
            setTotal(res.data.total)
        })
    };

    const handleChange = (cur, size) => {
        setPage(cur)
        setPageSize(size)
    };

    useEffect(() => {
        fetchData(currentPage, pageSize)
    }, [currentPage, pageSize])

    const handlClear = () => {
        requests.post("/skyy/exam/delete_exampool", {
            examPaperId: null
        }).then(res => {
            Toast.success("删除成功")
            fetchData(1, 10)
        })
    }

    const handleUploadSuccess = () => {
        Toast.success("导入成功")
        fetchData(1, 10)
    }


    return <>
        <div>
            <Button onClick={() => setPaperpoolAddVisible(true)}>录入试卷</Button>
        </div>
        <Table
            columns={columns}
            rowKey={'examPaperId'}
            dataSource={dataSource}
            pagination={{
                currentPage,
                pageSize: pageSize,
                total: total,
                onChange: handleChange,
                showSizeChanger: true,
                pageSizeOpts: [10, 20, 30, 40, 50, 100, 500]
            }}
            loading={loading}
            bordered={true}
            sticky={true}
        />
        <ExamPaperPoolAdd visible={paperpoolAddVisible} setVisible={setPaperpoolAddVisible} fetchData={fetchData} />
        <ExamPaperPoolEditor record={record} visible={poolEditorVisible} setVisible={setPoolEditorVisible} fetchData={fetchData}/>
    </>
}
