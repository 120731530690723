import React, { useState, useEffect } from 'react';
import { Table, Button, Toast, Form } from '@douyinfe/semi-ui';
import requests from '../../../../utils/request'
import ExamPoolEditor from '../pages/exampool_editor';
import DelButton from '../../../../component/del_button';


let setRecord2 = null
let setPoolEditorVisible2 = null
let getdas = null
let setDas = null


const columns = [
    {
        title: '医学分类',
        dataIndex: 'exampaper_medicate',
    },
    {
        title: '题型分类',
        dataIndex: 'exampaper_category',
    },
    {
        title: '题目名称',
        dataIndex: 'exampaper_topic',
    },
    {
        title: '正确答案',
        dataIndex: 'exampaper_rightanswer',
    },
    {
        title: '用户答案',
        dataIndex: 'user_answer',
    }
]



export default function ExamUsersDetail() {
    const [dataSource, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [currentPage, setPage] = useState(1);
    const [total, setTotal] = useState(0);
    const [pageSize, setPageSize] = useState(100);
    const [record, setRecord] = useState({})
    const [poolEditorVisible, setPoolEditorVisible] = useState(false)
    const urlSearchParams = new URLSearchParams(window.location.search);
    const examPaperId = Object.fromEntries(urlSearchParams.entries()).examPaperId;
    const userId = Object.fromEntries(urlSearchParams.entries()).userId;

    setRecord2 = setRecord

    setPoolEditorVisible2 = setPoolEditorVisible

    setDas = setData

    getdas = () => dataSource

    const fetchData = (cur = currentPage, size = pageSize) => {
        setLoading(true);
        requests.post("/skyy/exam/exam_userpaper_detail", { currentPage: cur, pageSize: size, examPaperId: examPaperId, userId: userId}).then(res => {
            setLoading(false)
            setData(res.data.result)
            setTotal(res.data.total)
        })
    };



    const handleChange = (cur, size) => {
        setPage(cur)
        setPageSize(size)
    };

    useEffect(() => {
        fetchData(currentPage, pageSize)
    }, [currentPage, pageSize])

    return <>
        <Table
            columns={columns}
            rowKey={'exampaperpooldetail_id'}
            dataSource={dataSource}
            pagination={{
                currentPage,
                pageSize: pageSize,
                total: total,
                onChange: handleChange,
                showSizeChanger: true,
                pageSizeOpts: [10, 20, 30, 40, 50, 100, 500]
            }}
            loading={loading}
            bordered={true}
            sticky={true}
        />
        <ExamPoolEditor record={record} visible={poolEditorVisible} setVisible={setPoolEditorVisible} fetchData={fetchData} />
    </>
}
