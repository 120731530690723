import React, { useState, useEffect } from 'react';
import { Table, Button, Toast, Form } from '@douyinfe/semi-ui';
import requests from '../../../../utils/request'
import ExamPoolEditor from '../pages/exampool_editor';
import { ExamPoolUpload } from '../../../public/file/pages/upload';
import DelButton from '../../../../component/del_button';

let setRecord2 = null
let setPoolEditorVisible2 = null
let getdas = null
let setDas = null

const operation = (text, record) => {
    const handleEdit = () => {
        setRecord2(record)
        setPoolEditorVisible2(true)
    }

    const handleDel = () => {
        requests.post("/skyy/exam/delete_exampool", {
             exam_id: record.exam_id 
        }).then(res => {
            Toast.success("删除成功")
            let key = record.exam_id
            const das = getdas()
            let newDataSource = [...das];
            if (key != null) {
                let idx = newDataSource.findIndex(data => data.exam_id === key);
                if (idx > -1) {
                    newDataSource.splice(idx, 1);
                    setDas(newDataSource);
                }
            }
        })
    }
    return <>
        <Button
            size='small'
            theme="solid"
            style={{ marginLeft: 10, marginRight: 10 }}
            onClick={handleEdit}
        >
            编辑
        </Button>
        <DelButton onOk={handleDel} desc='删除' />
    </>
}

const columns = [
    {
        title: '医学分类',
        dataIndex: 'exam_medicate',
    },
    {
        title: '题型分类',
        dataIndex: 'exam_category',
    },
    {
        title: '职业',
        dataIndex: 'exam_workcategory',
    },
    {
        title: '题目',
        dataIndex: 'exam_topic',
    },
    {
        title: '答案',
        dataIndex: 'exam_answer',
    },
    {
        title: '正确答案',
        dataIndex: 'exam_rightanswer',
    },
    {
        title: '操作',
        render: operation,
        width: 150
    },
]



export default function ExamPool() {
    const [dataSource, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [currentPage, setPage] = useState(1);
    const [total, setTotal] = useState(0);
    const [pageSize, setPageSize] = useState(10);
    const [record, setRecord] = useState({})
    const [poolEditorVisible, setPoolEditorVisible] = useState(false)

    setRecord2 = setRecord

    setPoolEditorVisible2 = setPoolEditorVisible

    setDas = setData

    getdas = () => dataSource

    const fetchData = (cur = currentPage, size = pageSize) => {
        setLoading(true);
        requests.post("/skyy/exam/exampool_list", { currentPage: cur, pageSize: size }).then(res => {
            setLoading(false)
            setData(res.data.result)
            setTotal(res.data.total)
        })
    };

    const handleChange = (cur, size) => {
        setPage(cur)
        setPageSize(size)
    };

    useEffect(() => {
        fetchData(currentPage, pageSize)
    }, [currentPage, pageSize])

    const handlClear = () => {
        requests.post("/skyy/exam/delete_exampool", {
            exam_id: null
        }).then(res => {
            Toast.success("删除成功")
            fetchData(1, 10)
        })
    }

    const handleUploadSuccess = () => {
        Toast.success("导入成功")
        fetchData(1, 10)
    }


    return <>
        <div style={{ width: 200 }}>
            <DelButton onOk={handlClear} desc='清空题库' />
            <ExamPoolUpload onSuccess={handleUploadSuccess} url='/skyy/exam/import_exampool' />
        </div>
        <Table
            columns={columns}
            rowKey={'exam_id'}
            dataSource={dataSource}
            pagination={{
                currentPage,
                pageSize: pageSize,
                total: total,
                onChange: handleChange,
                showSizeChanger: true,
                pageSizeOpts: [10, 20, 30, 40, 50, 100, 500]
            }}
            loading={loading}
            bordered={true}
            sticky={true}
        />
        <ExamPoolEditor record={record} visible={poolEditorVisible} setVisible={setPoolEditorVisible}  fetchData={fetchData} />
    </>
}
