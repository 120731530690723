import requests from '../../../../utils/request'
import React from "react"
import { Tree, Skeleton, Toast, Modal, Banner } from '@douyinfe/semi-ui';

function menu_wrapper(data, level) {
    return {
        key: data.menu_id + "",
        value: data.menu_id,
        parent_id: data.parent_id,
        level: level,
        label: (
            <div style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
            }}>
                <strong>{data.name}</strong>
            </div>
        ),
        children: []
    }
}

export function generate_tree(parent_id, source, result, level = 0) {
    if (!source) return []
    const newSource = []
    const rootMenus = []
    source.forEach(val => {
        if (val.parent_id === parent_id) {
            rootMenus.push(val)
        } else {
            newSource.push(val)
        }
    })
    if (rootMenus) {
        for (const i in rootMenus) {
            const menu = rootMenus[i]
            const m = menu_wrapper(menu, level)
            result.push(m)
            m.children = generate_tree(menu.menu_id, newSource, m.children, level + 1)
        }
    }
    return result
}


const style = {
    display: 'flex',
    alignItems: 'flex-start',
    marginTop: 10
};

const placeholder = (
    <div style={style}>
        <div>
            <Skeleton.Paragraph style={{ width: 240 }} rows={5} />
        </div>
    </div>
);

export default class GroupMenuEditor extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            treeData: [],
            skLoading: true,
            selected_menus: []
        }
        this.lock = false
    }

    componentDidMount() {
        requests.get("/skyy/admin/get_menu_list").then(res1 => {
            this.setState({ ...this.state, treeData: generate_tree(null, res1.data, []) })
        })
    }

    render() {
        const fetchData = () => {
            this.setState({ skLoading: true })
            requests.get("/skyy/admin/get_menu_list_by_groupid", {
                params: { group_id: this.props.cur_group_id }
            }).then(res2 => {
                let newData = res2.data.map(val => val.menu_id)
                this.setState({ ...this.state, selected_menus: newData, skLoading: false })
            })
        }

        if (!this.lock && this.props.visible) {
            this.lock = true
            fetchData()
        }
        const handleOk = () => {
            requests.post("/skyy/admin/update_group_menu", {
                ids: this.state.selected_menus,
                group_id: this.props.cur_group_id
            }).then(res => {
                this.props.setVisible(false)
                Toast.success("修改成功")
            })

        }
        return <>
            <Modal
                title="编辑组菜单"
                visible={this.props.visible}
                onOk={handleOk}
                afterClose={() => {
                    this.lock = false
                }} //>=1.16.0
                onCancel={() => {
                    this.props.setVisible(false)
                }}
                okText={'保存'}
                cancelText={'取消'}
            >
                <Skeleton placeholder={placeholder} loading={this.state.skLoading} active>
                    <Tree
                        multiple
                        treeData={this.state.treeData}
                        style={{
                            width: '100%',
                            height: '100%'
                        }}
                        onChange={values => {
                            this.setState({ selected_menus: values })
                        }}
                        value={this.state.selected_menus}
                        defaultExpandAll
                        checkRelation='unRelated'
                    />
                </Skeleton>
            </Modal>
        </>
    }
}