import { DatePicker, Layout, Toast, Table } from '@douyinfe/semi-ui';
import DepthSelect from "../../../pages/DepthSelect";
import MyButton from "../../../pages/MyButton";
import React, { useEffect, useState } from 'react';
import exportToExcel from "../../../utils/export_exc"
import request from "../../../utils/request"
import "../../../css/a.css"
import moment from 'moment'
import DelButton from '../../../component/del_button';


//筛选条件
let start_time = null
let end_time = null
let dept = ""
let getdas = null
let setDas = null
export default function MgzbHd() {
    //页面布局
    const { Header, Content } = Layout;
    //列名
    const columns = [
        { title: '科室', dataIndex: 'dept', width: 100 }
        , { title: '上报日期', dataIndex: 'regdate', width: 130 }
        , {
            title: '提交时间', dataIndex: 'submit_time', width: 150, render: value => {
                return value ? moment(value).format('YYYY-MM-DD HH:mm:ss') : "";
            }
        }
        , { title: '病重人数', dataIndex: 'bzrs' }
        , { title: '病危人数', dataIndex: 'bwrs' }
        , { title: '药物使用错误人数', dataIndex: 'ywsycwrs' }
        , { title: '高危药物外渗人数', dataIndex: 'gwywwsrs' }
        , { title: '输液反应人数', dataIndex: 'syfyrs' }
        , { title: '输血反应人数', dataIndex: 'sxfyrs' }
        , { title: '非计划性拔管人数', dataIndex: 'fjhxbgrs' }
        , { title: '留置导管人数', dataIndex: 'lzdgrs' }
        , { title: '压疮高危风险新增例数', dataIndex: 'ycgwfxxzls' }
        , { title: 'Ⅱ期以上压疮发生人数（院内）', dataIndex: 'eqysycfsrsyn' }
        , { title: 'Ⅱ期以上压疮发生人数（院外）', dataIndex: 'eqysycfsrsyw' }
        , { title: '跌倒高危风险新增例数', dataIndex: 'ddgwfxxzls' }
        , { title: '跌倒人数（高危人群）', dataIndex: 'ddrsgw' }
        , { title: '跌倒人数（非高危人群）', dataIndex: 'ddrsfgw' }
        , { title: '误吸人数', dataIndex: 'wxrs' }
        , { title: '导管相关血流感染新增例数', dataIndex: 'dgxgxlgrxzls' }
        , { title: '导管相关性尿路感染新增例数', dataIndex: 'dgxgxnlgrxzls' }
        , { title: 'VTE新增例数', dataIndex: 'vtexzls' }
        , { title: '使用呼吸机人数', dataIndex: 'syhxjrs' }
        , { title: '人工气道人数', dataIndex: 'rgqdrs' }
        , { title: '使用约束带人数', dataIndex: 'syysdrs' }
        , { title: '呼吸机相关性肺炎', dataIndex: 'hxjxgxfy' }
        , {
            title: '操作', width: 80
            , render: (text, record) => (
                <>
                    <DelButton onOk={() => {
                        request.get("/hulibu/mgzb_data_del", {
                            params: {hlzlkzmgzbsbb_id: record.hlzlkzmgzbsbb_id}
                        }).then(res => {
                            Toast.success("删除成功")
                            let key = record.hlzlkzmgzbsbb_id
                            const das = getdas
                            let newDataSource = [...das];
                            if (key != null) {
                                let idx = newDataSource.findIndex(data => data.hlzlkzmgzbsbb_id === key);
                                if (idx > -1) {
                                    newDataSource.splice(idx, 1);
                                    setDas(newDataSource);
                                }
                            }
                        })
                    }}/>
                </>
            ),
        }
    ];
    //分页条件
    const [size, setSize] = useState(10);
    const [curPage, setcurPage] = useState(1);
    const [total, setTotal] = useState(20);
    //当前页数据
    const [das, setdas] = useState([
        {},
    ])
    //所有页数据
    let exeDas = []
    const setEx = (data) => {
        exeDas = data
    }

    setDas = setdas

    getdas = das
    //导出excel数据
    const downloadexcel = async () => {
        console.log(dept);
        await getDatas({
            currentPage: -1,        //页码
            pageSize: size,         //尺寸
            start_time: start_time, //开始时间
            end_time: end_time,     //结束时间
            dept: dept              //科室
        }, setEx)
        exportToExcel(columns, exeDas, '表数据')
    }
    //获取当页数据
    const getdtats = () => {
        getDatas({
            currentPage: curPage,   //页码
            pageSize: size,         //尺寸
            start_time: start_time, //开始时间
            end_time: end_time,     //结束时间
            dept: dept              //科室
        }, setdas)
    }
    //发送请求-查询数据
    const getDatas = async (props, setDate) => {
        await request.post('/hulibu/mgzb_data', props).then((res) => {
            setDate(res.data.result)
            setTotal(res.data.total)
        })
    }

    //页码变化
    function onChange(currentPage, pageSize) {
        getDatas({
            currentPage: currentPage,       //页码
            pageSize: pageSize,             //尺寸
            start_time: start_time,     //开始时间
            end_time: end_time,         //结束时间
            dept: dept                  //科室
        }, setdas)
        setcurPage(currentPage);        //当前页数
        setSize(pageSize)

    }
    //设置时间范围
    const SelectTime = (date, dateString) => {
        start_time = dateString[0]
        end_time = dateString[1]
    }
    //选择科室
    const selectDept = (value) => {
        setcurPage(1)
        dept = value
    }

    useEffect(() =>{
        getdtats()
    }, [])

    return <>
        <Layout className="components-layout-demo">
            <Header>
                <br></br>
                &nbsp;&nbsp;&nbsp;&nbsp;请选择日期：<DatePicker type="dateRange" density="compact" style={{ width: 260 }} onChange={SelectTime} />
                &nbsp;&nbsp;&nbsp;&nbsp;请选择科室：<DepthSelect setDepth={selectDept} />
                &nbsp;&nbsp;&nbsp;&nbsp;<MyButton name="查询" clicfun={getdtats} />
                &nbsp;&nbsp;&nbsp;&nbsp;<MyButton name="导出 EXCEL" clicfun={downloadexcel} />
            </Header>

            <Content >
                <br></br><Table columns={columns} dataSource={das} pagination={{
                    total: total,
                    showSizeChanger: true,
                    defaultCurrentPage: 1,
                    currentPage: curPage,
                    pageSize: size,
                    pageSizeOpts: [10, 20, 50, 200],
                    onChange: onChange
                }} bordered={true} size={'middle'} sticky={true} />
            </Content>
        </Layout>
    </>
}




