import { Modal, Form, Toast } from '@douyinfe/semi-ui';

export default function AddUserGroupModel({ visible, set_visible, do_add_user_group, fetch_data }) {
    let data = {}
    const handleOk = () => {
        do_add_user_group(data).then(res => {
            Toast.success("新增成功")
            set_visible(false)
        })
    };
    const handleCancel = () => {
        set_visible(false);
    };
    const handleAfterClose = () => {
        fetch_data()
    };
    return <>
        <Modal
            title="新增用户组"
            visible={visible}
            onOk={handleOk}
            afterClose={handleAfterClose} //>=1.16.0
            onCancel={handleCancel}
            okText={'新增'}
            cancelText={'取消'}
        >
            <Form render={({ formState, formApi, values }) => (
                <>
                    <Form.Input field='name' label='组名称(一般使用英文字母缩写, 例: xxk)' />
                    <Form.Input field='desc' label='组描述(中文名称, 例: 信息科)' />
                    <Form.TextArea field='bz' label='备注' />
                </>
            )} onValueChange={values => data = values}>
            </Form>
        </Modal>
    </>
}