import { useEffect } from "react";
import { throttle } from "../../../../utils/user_friendly";


export default function QRCodeLogin({ handleSubmit }) {
    useEffect(() => {
        window.DTFrameLogin(
            {
                id: 'qrcode',
                width: 300,
                height: 300,
            },
            {
                redirect_uri: encodeURIComponent(window.location.href),
                client_id: process.env.REACT_APP_DING_CLIENT_ID,
                scope: 'openid corpid',
                response_type: 'code',
                prompt: 'consent',
                corpId: 'dingb915c246d3776ce7f5bf40eda33b7ba0',
                state: new Date().getTime()
            },
            (loginResult) => {
                const { redirectUrl, authCode, state } = loginResult;
                throttle(() => {
                    handleSubmit(authCode)
                }, 800)()
                

            },
            (errorMsg) => {
                // 这里一般需要展示登录失败的具体原因
                alert(`登录失败: ${errorMsg}`);
            },
        );
    }, [handleSubmit])

    return <>
        <div style={{ display: "block", margin: "auto", textAlign: "center" }}>
            <strong style={{fontSize: '18px'}}>请使用手机钉钉扫码</strong>
            <div id="qrcode">

            </div>
        </div>
    </>
}