import { Modal, Transfer, Toast } from '@douyinfe/semi-ui';
import requests from '../../../../utils/request'
import { useEffect, useState } from 'react';

let lock = false

export default function GroupPermEditor({ visible, setVisible, cur_group_id }) {
    const [source, setSource] = useState([])
    const [selected_perm, setSelectedPerm] = useState([])
    const handleOk = () => {
        requests.post("/skyy/admin/update_group_perm", {
            ids: selected_perm,
            group_id: cur_group_id
        }).then(res => {
            setVisible(false)
            Toast.success("修改成功")
        })
        
    }

    useEffect(() => {
        requests.get("/skyy/admin/get_perm_list").then(res => {
            const newData = res.data.map(val => {
                return {
                    label: `${val.name}(${val.path})`,
                    value: val.perm_id,
                    key: val.perm_id
                }
            })
            setSource(newData)
        })
    }, [])

    if (!lock && visible) {
        lock = true
        requests.get("/skyy/admin/get_perm_list_by_groupid", {
            params: { group_id: cur_group_id }
        }).then(res2 => {
            let newData = res2.data.map(val => val.perm_id)
            setSelectedPerm(newData)
        })
    }
    return <>
        <Modal
            title="编辑组权限"
            visible={visible}
            onOk={handleOk}
            afterClose={() => { lock = false }} //>=1.16.0
            onCancel={() => setVisible(false)}
            okText={'保存修改'}
            cancelText={'取消'}
            width={820}
        >
            <Transfer
                style={{
                    width: 723,
                    height: 416
                }}
                dataSource={source}
                value={selected_perm}
                onChange={(values, items) => setSelectedPerm(values)}
            />
        </Modal>
    </>
}