import { useEffect, useState } from "react";
import UserGroupList from "../pages/user_group_list";
import requests from "../../../../utils/request";
import AddUserGroupModel from "../pages/add_user_group";

export default function UserGroupManage() {
    const [group_list, setGorupList] = useState([])
    const [group_model_visible, setGroupModelVisible] = useState(false)
    const do_add_user_group = (data) => requests.post("/skyy/admin/add_user_group", data)
    const fetch_data = () => {
        requests.get("/skyy/admin/get_user_group_list").then((res) => {
            setGorupList(res.data)
        })
    }
    const get_user_group_list_by_id = (user_id) => requests.get("/skyy/admin/get_user_group_by_id", {user_id})
    useEffect(() => {
        fetch_data()
    }, [])
    return <>
        <UserGroupList tb_data={group_list} set_group_model_visible={setGroupModelVisible} fetch_data={fetch_data} />
        <AddUserGroupModel
            visible={group_model_visible}
            set_visible={setGroupModelVisible}
            do_add_user_group={do_add_user_group}
            fetch_data={fetch_data}
            get_user_group_list_by_id={get_user_group_list_by_id}
        />
    </>
}