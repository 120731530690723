import {DatePicker, Layout,Button,Table} from '@douyinfe/semi-ui';
import DepthSelect from "../../../pages/DepthSelect";
import MyButton from "../../../pages/MyButton";
import React, { useState } from 'react';
import exportToExcel from "../../../utils/export_exc"
import request from "../../../utils/request"
import "../../../css/a.css"


//筛选条件
let start_time = null
let end_time = null
let dept = ""
export default  function MgzbHd() {
    //页面布局
    const { Header, Content } = Layout;
    //列名
    const columns = [
        { dataIndex: 'dept', title: '科室'}
        , { dataIndex: 'bednum', title: '床位'}
        , { dataIndex: 'patientname', title: '姓名' }
        , { dataIndex: 'thedate', title: '日期'}
        , { dataIndex: 'a1', title: '您初入病房时，首诊护士的接待您是否满意？'}
        , { dataIndex: 'a2', title: '入院时，接待您的护士是否给您介绍住院环境及相关事项？'}
        , { dataIndex: 'a3', title: '您是否知晓您的责任护士姓名？'}
        , { dataIndex: 'a4', title: '住院期间您对责任护士的服务是否满意？'}
        , { dataIndex: 'a5', title: '责任护士对您的疾病所给子的健康知识讲解是否满意'}
        , { dataIndex: 'a6', title: '您对护士的操作技术是否满意？'}
        , { dataIndex: 'a7', title: '当您遇到问题时，您满意护士的解释与处理吗？'}
        , { dataIndex: 'a8', title: '护士能主动到您床旁巡视吗？'}
        , { dataIndex: 'a9', title: '您认为护士的微笑服务如何？'}
        , { dataIndex: 'a10', title: '责任护士是否协助您生活照顾（必要时）'}
        , { dataIndex: 'a11', title: '您对费用清单上的收费理解吗？'}
        , { dataIndex: 'a12', title: '您对病房的卫生状况是否满意？'}
        , { dataIndex: 'a13', title: '您对病房的安静程度是否满意？'}
        , { dataIndex: 'a14', title: '您对护士长的工作是否满意？'}
        , { dataIndex: 'a15', title: '最满意的护士1'}
        , { dataIndex: 'a16', title: '最满意的护士2'}
        , { dataIndex: 'a17', title: '最不满意的护士1'}
        , { dataIndex: 'a18', title: '最不满意的护士2'}
        , { dataIndex: 'a19', title: '建议'}
        , { dataIndex: 'a20', title: '您对我院护理工作的整体评价'}
        ,{title: '操作',width:180,dataIndex: 'operate'
            , render: (text, record) => (
                <>
                    <Button  onClick={() => {
                        console.log(record);
                    }} >删除</Button>&nbsp;
                    <Button  onClick={() => {
                        console.log(record);
                    }} >修改</Button>&nbsp;
                    <Button  onClick={() => {
                        console.log(record);
                    }} >详情</Button>
                </>
            ),
        }
    ];
    //分页条件
    const [size, setSize] = useState(10);
    const [curPage, setcurPage] = useState(1);
    const [total, setTotal] = useState(20);
    //当前页数据
    const [das,setdas] = useState([
        {},
    ])
    //所有页数据
    let exeDas=[]
    const setEx = (data) => {
        exeDas = data
    }
    //导出excel数据
    const downloadexcel = async ()=>{
        console.log(dept);
        await getDatas({
            currentPage: -1,        //页码
            pageSize: size,         //尺寸
            start_time: start_time, //开始时间
            end_time: end_time,     //结束时间
            dept: dept              //科室
        },setEx)
        exportToExcel(columns,exeDas,'表数据')
    }
    //获取当页数据
    const getdtats = ()=>{
        getDatas({
            currentPage: curPage,   //页码
            pageSize: size,         //尺寸
            start_time: start_time, //开始时间
            end_time: end_time,     //结束时间
            dept: dept              //科室
        },setdas)
    }
    //发送请求-查询数据
    const getDatas= async (props,setDate)=>{
        await request.post('/hulibu/myddc_data', props).then((res)=>{
            setDate(res.data.result)
            setTotal(res.data.total)
        })
    }
    //页码变化
    function onChange(currentPage, pageSize) {
        getDatas({
            currentPage: currentPage,       //页码
            pageSize: pageSize,             //尺寸
            start_time: start_time,     //开始时间
            end_time: end_time,         //结束时间
            dept: dept                  //科室
        }, setdas)
        setcurPage(currentPage);        //当前页数
        setSize(pageSize)

    }
    //设置时间范围
    const SelectTime= (date, dateString)=>{
        start_time=dateString[0]
        end_time=dateString[1]
    }
    //选择科室
    const selectDept = (value)=>{
        setcurPage(1)
        dept = value
    }

    return <>
        <Layout className="components-layout-demo">
            <Header>        
                <br></br>
                &nbsp;&nbsp;&nbsp;&nbsp;请选择日期：<DatePicker type="dateRange" density="compact" style={{ width: 260 }} onChange={SelectTime}/>
                &nbsp;&nbsp;&nbsp;&nbsp;请选择科室：<DepthSelect setDepth={selectDept}/>
                &nbsp;&nbsp;&nbsp;&nbsp;<MyButton name="查询" clicfun={getdtats}/>
                &nbsp;&nbsp;&nbsp;&nbsp;<MyButton name="导出 EXCEL" clicfun={downloadexcel}/>
            </Header>

            <Content >
                <br></br><Table columns={columns} dataSource={das} pagination={{
                    total:total,
                    showSizeChanger:true,
                    defaultCurrentPage:1,
                    currentPage:curPage,
                    pageSize:size,
                    pageSizeOpts:[10, 20, 50, 200],
                    onChange:onChange
                }} bordered={true} size={'middle'} sticky={true} />
            </Content>
        </Layout>
    </>
} 




