import { Table, Button, Toast } from '@douyinfe/semi-ui';
import { IconDelete, IconEdit, IconInheritStroked, IconSourceControl } from '@douyinfe/semi-icons';
import { useState } from 'react';
import GroupMenuEditor from './group_menu_editor';
import GroupPermEditor from './group_perm_editor';
import { CommonSingleUpload } from '../../../public/file/pages/upload';
import requests from '../../../../utils/request'

export default function UserGroupList({ tb_data, set_group_model_visible, fetch_data }) {
    const columns = [
        {
            title: 'id',
            dataIndex: 'group_id',
        },
        {
            title: '组名称',
            dataIndex: 'name',
        },
        {
            title: '组描述',
            dataIndex: 'desc',
        },
        {
            title: '备注',
            dataIndex: 'bz',
        },
        {
            title: '操作',
            dataIndex: 'operate',
            render: (text, record) => (
                <Operation
                    text={text}
                    record={record}
                    set_menu_visible={setGroupMenuV}
                    set_perm_visible={setGroupPermV}
                    setCurGroupId={setCurGroupId}
                />
            ),
        },
    ]
    const [group_menu_v, setGroupMenuV] = useState(false)
    const [group_perm_v, setGroupPermV] = useState(false)
    const [user_editor_v, setUserEditorV] = useState(false)
    const [cur_group_id, setCurGroupId] = useState(null)

    const handleExport = () => {
        requests.get("/skyy/admin/export_user_group_data", { responseType: 'blob' }).then(res => {
            const url = window.URL.createObjectURL(new Blob([res.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'export_group_data.json'); // 设置文件下载的文件名
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link)
        })
    }

    const handleImport = () => {
        Toast.success("导入成功")
        fetch_data()
    }
    return <>
        <div style={{ marginBottom: 10 }}>
            <AddGroup set_group_model_visible={set_group_model_visible} />
            <Button style={{marginLeft: 10}} onClick={handleExport} theme="solid">导出用户组数据</Button>
            <CommonSingleUpload onSuccess={handleImport} btn_text='导入用户组数据' url='/skyy/admin/import_user_group_data'/>
        </div>

        <Table
            columns={columns}
            dataSource={tb_data}
            rowKey='group_id'
            pagination={false}
            bordered={true}
        />
        <GroupMenuEditor visible={group_menu_v} setVisible={setGroupMenuV} cur_group_id={cur_group_id} />
        <GroupPermEditor visible={group_perm_v} setVisible={setGroupPermV} cur_group_id={cur_group_id} />
    </>
}

function AddGroup({ set_group_model_visible }) {
    const openWindow = () => {
        set_group_model_visible(true)
    }
    return <>
        <Button theme='solid' onClick={openWindow}>
            新增用户组
        </Button>
    </>
}

function Operation({ text, record, set_menu_visible, set_perm_visible, setCurGroupId }) {
    const handle_menu_editor_open = () => {
        setCurGroupId(record.group_id)
        set_menu_visible(true)
    }

    const handle_perm_editor_open = () => {
        setCurGroupId(record.group_id)
        set_perm_visible(true)
    }
    return <>
        <Button icon={<IconEdit />} theme="borderless" onClick={() => alert("功能开发中")} >编辑</Button>
        <Button icon={<IconInheritStroked />} theme="borderless" onClick={handle_menu_editor_open} >编辑组菜单</Button>
        <Button icon={<IconSourceControl />} theme="borderless" onClick={handle_perm_editor_open} >编辑组权限</Button>
        <Button icon={<IconDelete />} theme="borderless" type="danger" onClick={() => alert("功能开发中")} />
    </>
}