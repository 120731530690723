import { useEffect, useState, useRef } from "react";
import { Card, Typography, CheckboxGroup, Checkbox, Button, Collapse, Avatar, Toast, RadioGroup, Radio, Divider, Modal } from "@douyinfe/semi-ui";
import { IconAlertCircle, IconTickCircle, IconSpin } from "@douyinfe/semi-icons"
import '../../../../css/user_exam.css'
import requests from '../../../../utils/request'
import requests_nl from '../../../../utils/request_noloading'
import { get_data } from "../../../../utils/data_ctl";
import { useNavigate } from "react-router";

const empty_tip = { "text": "", "type": "primary" }

export default function UserExam() {
    const navigate = useNavigate()
    const [endT, setEndT] = useState(null)
    const [idx, setIdx] = useState(1)
    const [timu_data, setTimuData] = useState(false)
    const [exam_status_list, setESL] = useState([])
    const [update_timu, setUT] = useState(empty_tip)
    const user = get_data("user")
    document.title = "考试系统"
    const urlSearchParams = new URLSearchParams(window.location.search);
    const examPaperId = Object.fromEntries(urlSearchParams.entries()).examPaperId;
    const dept = user.dept
    const username = user.username
    useEffect(() => {
        // 初始化考试
        requests.get("/skyy/exam/start_exam", {
            params: {
                examPaperId
            }
        }).then(res => {
            setEndT(res.data.examEndTime)
            document.title = res.data.examPaperName
            get_timu_data(idx)
            gen_exam_status()
        }).catch(err => {
            navigate("/skyy/exam/exam_index")
        })
    }, [examPaperId])

    // 生成答题情况表
    const gen_exam_status = () => {
        requests.get("/skyy/exam/get_exam_status", {
            params: {
                examPaperId
            }
        }).then(res => {
            setESL(res.data)
        })
    }

    const get_timu_data = (idx) => {
        setUT({ "type": "warning", "text": "获取题目信息...", icon: "get" })
        requests_nl.get("/skyy/exam/get_exam_userpool", {
            params: {
                examPaperId,
                idx: idx - 1
            }
        }).then(res => {
            // 清除提示信息
            setUT(empty_tip)
            // 数据获取成功后改变题号
            setIdx(idx)
            // 设置题目信息
            setTimuData(res.data)
        }).catch(err => {
            setUT({ "type": "danger", "text": `获取题目信息失败: ${err}`, icon: "fail" })
        })
    }

    // 切换下一题
    const next_q = () => {
        if (idx > exam_status_list.length - 1) {
            Toast.warning("没有下一题")
            return
        }
        get_timu_data(idx + 1)
    }

    // 切换上一题
    const before_q = () => {
        if (idx === 1) {
            Toast.warning("没有上一题")
            return
        }
        get_timu_data(idx - 1)
    }

    // 保存答题数据
    const save_timu_data = (user_answer, examuser_id) => {
        setUT({ "type": "warning", "text": "保存中...", "icon": "save" })
        requests_nl.post("/skyy/exam/update_exam_userpool", { user_answer, examuser_id }).then(res => {
            setUT({ "type": "success", "text": "保存成功", "icon": "success" })
        }).catch(err => {
            setUT({ "type": "danger", "text": `保存失败: ${err}`, "icon": "failed" })
        })
    }

    // 提交试卷
    const sub_exam = () => {
        Modal.confirm({
            title: '确定要提交吗？', content: '试卷提交后会计算分数', centered: true, onOk: () => {
                requests.get("/skyy/exam/submit_exam", {
                    params: { examPaperId: parseInt(examPaperId) }
                }).then(res => {
                    Modal.success({
                        title: "提交成功", hasCancel: false, centered: true, onOk: () => navigate("/skyy/exam/exam_index")
                    })
                })
            }
        });

    }

    const { Text } = Typography
    return <>
        <div style={{ padding: '10px', marginTop: '0' }} id="main_ue">
            <strong>
                姓名: {username}<br />
                部门: {dept}<br />
                剩余时间: <CountDown endTime={endT} navigate={navigate} examPaperId={examPaperId} /><br />
                <Text type={update_timu.type} icon={<UpdateStatusIcon type={update_timu.icon}/>}>{update_timu.text}</Text>
            </strong>
            <Card
                style={{ marginTop: '5px' }}
            >
                <Timu timu_data={timu_data} idx={idx} esl={exam_status_list} setESL={setESL} save_timu_data={save_timu_data} />
            </Card>
            <br />
            <div>
                <Button theme='solid' size="large" style={{ width: '48%' }} onClick={before_q}>上一题</Button>
                <Button theme='solid' size="large" style={{ float: 'right', width: '48%' }} onClick={next_q}>下一题</Button>
            </div>
            <br />
            <Collapse keepDOM={true}>
                <Collapse.Panel header="答题详情" itemKey="1">
                    <ExamStatus exam_status_list={exam_status_list} get_timu_data={get_timu_data} />
                </Collapse.Panel>
            </Collapse>
            <Button theme='solid' size="large" block onClick={sub_exam}>提交</Button>
            <Button style={{ marginTop: '10px' }} block onClick={() => window.location.reload()}>刷新页面</Button>
        </div>

    </>
}

function UpdateStatusIcon({type}) {
    switch (type){
        case "save":
            return <IconSpin spin />
        case "success":
            return <IconTickCircle/>
        case "fail":
            return <IconAlertCircle/>
        case "get":
            return <IconSpin spin />
        default:
            return null
    }
}

function Timu({ timu_data, idx, esl, setESL, save_timu_data }) {
    const { Title } = Typography;

    return <>
        <Title heading={5}>
            <span>
                {`${idx}.`}
            </span>
            <span style={{ color: 'red' }}>
                {"[" + (timu_data.exampaper_category || "加载中...") + "]"}
            </span>
            {timu_data.exampaper_topic}
        </Title>
        <Divider margin='12px' />
        {timu_data.exampaper_category === "单选题" ?
            (<DanXuan timu_data={timu_data} esl={esl} setESL={setESL} idx={idx} save_timu_data={save_timu_data} />)
            : (<DuoXuan timu_data={timu_data} esl={esl} setESL={setESL} idx={idx} save_timu_data={save_timu_data} />)}
    </>
}

function DanXuan({ timu_data, esl, setESL, idx, save_timu_data }) {
    const [ua, setUa] = useState(null)
    useEffect(() => {
        if (timu_data) {
            // 用户答案回显
            setUa(timu_data.user_answer ? JSON.parse(timu_data.user_answer)[0] : null)
        }
    }, [timu_data])
    if (!timu_data) return
    const d1 = JSON.parse(timu_data.exampaper_answer)
    const items = []
    // 选项渲染
    for (let p in d1) {//遍历json数组时，这么写p为索引，0,1
        items.push(<Radio key={p} value={d1[p]}><span style={{ fontSize: '18px' }}>{d1[p]}</span></Radio>)
    }

    const handleChange = event => {
        const val = event.target.value
        setUa(val)
        if (val) {
            const cpe = [...esl]
            cpe[idx - 1] = 1
            setESL(cpe)
            const l = []
            l.push(val)
            // 提交用户答案到数据库
            save_timu_data(l, timu_data.examuser_id)
            // })
        } else {
            const cpe = [...esl]
            cpe[idx - 1] = 0
            setESL(cpe)
            // 提交用户答案到数据库
            save_timu_data(null, timu_data.examuser_id)
        }

    }
    return <>
        <RadioGroup
            type='card'
            value={ua}
            direction='vertical'
            aria-label="单选"
            name="demo-radio-group-card"
            mode="advanced"
            onChange={handleChange}
        >
            {items}
        </RadioGroup>
    </>
}

function DuoXuan({ timu_data, esl, setESL, idx, save_timu_data }) {
    const [ua, setUa] = useState([])
    useEffect(() => {
        if (timu_data) {
            setUa(timu_data.user_answer ? JSON.parse(timu_data.user_answer) : [])
        }
    }, [timu_data])
    if (!timu_data) return
    const d1 = JSON.parse(timu_data.exampaper_answer)
    const items = []
    // 生成选项
    for (let p in d1) {//遍历json数组时，这么写p为索引，0,1
        items.push(<Checkbox key={p} value={d1[p]}><span style={{ fontSize: '18px' }}>{d1[p]}</span></Checkbox>)
    }
    const handleChange = vals => {
        setUa(vals)
        if (vals.length !== 0) {
            const cpe = [...esl]
            cpe[idx - 1] = 1
            setESL(cpe)
            // 提交用户答案到数据库
            save_timu_data(vals, timu_data.examuser_id)
            // requests_nl.post("/skyy/exam/update_exam_userpool", {
            //     user_answer: vals,
            //     examuser_id: timu_data.examuser_id
            // })
        } else {
            const cpe = [...esl]
            cpe[idx - 1] = 0
            setESL(cpe)
            // 提交用户答案到数据库
            save_timu_data(null, timu_data.examuser_id)
            // requests_nl.post("/skyy/exam/update_exam_userpool", {
            //     user_answer: null,
            //     examuser_id: timu_data.examuser_id
            // })
        }

    }
    return <>
        <CheckboxGroup
            name="duoxuan"
            type='card'
            style={{ width: '100%', marginTop: '5px' }}
            value={ua}
            aria-label="多选题"
            onChange={handleChange}
        >
            {items}
        </CheckboxGroup>
    </>
}

function ExamStatus({ exam_status_list, get_timu_data }) {
    const itemsRef = useRef([]);
    const handleClick = (e) => {
        get_timu_data(parseInt(e.target.innerText))
    }

    itemsRef.current = []

    for (let i in exam_status_list) {
        itemsRef.current.push(
            <Avatar
                key={i}
                onClick={handleClick}
                color={exam_status_list[i] === 0 ? "grey" : "blue"}
                shape="square"
                size="small"
                style={{ margin: 4 }}
                alt="User">
                {parseInt(i) + 1}
            </Avatar>
        )
    }
    return (<>
        {itemsRef.current}
    </>)
}

let endFlag = false

function CountDown({ endTime, navigate, examPaperId }) {
    useEffect(() => {
        if (!endTime) return
        var endT = new Date(endTime)
        var countdownElement = document.getElementById("countdown"); // 获取显示倒计时的元素
        var endDate = endT; // 设置结束日期（这里为2021年12月31日）

        function updateCountdown() {
            var now = new Date().getTime(); // 当前时间

            var distance = endDate - now; // 距离结束日期还有多少时间
            if (distance < 0) {
                clearInterval(timer); // 如果已经到达或超过了结束日期，清除定时器并隐藏倒计时元素
                // countdownElement.style.display = "none";
                if (!endFlag) {
                    endFlag = true
                    requests.get("/skyy/exam/submit_exam", {
                        params: { examPaperId: parseInt(examPaperId) }
                    }).then(res => {
                        Modal.info({
                            title: "考试结束，已为你自动提交试卷", hasCancel: false, centered: true
                            , onOk: () => navigate("/skyy/exam/exam_index")
                        })
                    })
                }

                return;
            }

            var days = Math.floor(distance / (1000 * 60 * 60 * 24)); // 天数
            var hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)); // 小时
            var minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60)); // 分钟
            var seconds = Math.floor((distance % (1000 * 60)) / 1000); // 秒数

            countdownElement.innerHTML = days + "天 " + hours + "时 " + minutes + "分 " + seconds + "秒 ";
        }

        updateCountdown(); // 初始化倒计时
        var timer = setInterval(updateCountdown, 1000); // 每秒更新一次倒计时
    }, [endTime])

    return <>
        <div id="countdown" style={{ display: 'inline' }}></div>
    </>
}