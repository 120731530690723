import React, { useEffect } from 'react';
import { useFormApi, Form, Col, Row, Button, Divider, Toast, ArrayField } from '@douyinfe/semi-ui';
import { IconUpload, IconFile, IconPlus, IconMinusCircle } from '@douyinfe/semi-icons';
import request from "../../../../utils/request"
import FileList from "../../../public/file/pages/file_list"
import FileListWithAppend from '../../../public/file/pages/file_list_with_append';




function Element({ field, remove }) {
    const { DatePicker, InputNumber, TextArea } = Form;
    return <>
        <Row >
            <Col span={5}>
                <DatePicker labelPosition='left' field={`${field}[date]`} type="date" label='付款日期' initValue={field.date ? field.date : new Date()}/>
            </Col>

            <Col span={4}>
                <InputNumber labelPosition='left' field={`${field}[amount]`} label="应付金额" min={-1}  initValue={field.amount ? field.amount : 0}/>
            </Col>

            <Col span={4}>
                <Form.Select labelPosition='left' field={`${field}[ispay]`} label="是否付款"  initValue={field.ispay ? field.ispay : "否"}>
                    <Form.Select.Option value="是">是</Form.Select.Option>
                    <Form.Select.Option value="否">否</Form.Select.Option>
                </Form.Select>
            </Col>

            <Col span={8}>
                <TextArea labelPosition='left' field={`${field}[tip]`} initValue={field.tip ? field.tip : "无"} autosize={{ minRows: 1 }} label="备注" showClear />
            </Col>
            <Col span={2}>
                <Button
                    type='danger'
                    theme='borderless'
                    icon={<IconMinusCircle />}
                    onClick={remove}
                    style={{ margin: 12 }}
                />
            </Col>
        </Row>

    </>
}


export default function FormDetail({ setcomps, comps, formdata, xxk_contract_id }) {
    const formApi = useFormApi();
    const { Input, DatePicker, TextArea, Upload, InputNumber } = Form;
    console.log(formdata);
    useEffect(() => {
        formdata['comps'] = comps
        comps.map(val => {
            if (!val.tip) {
                val.tip = "无"
            }
            return val
        })
        formApi.setValues(formdata);
    }, [comps, formApi, formdata])
    const submitmsg = () => {
        let tm = formApi.getValues()
        tm['xxk_contract_id'] = xxk_contract_id
        formSubmit('/xxk_contract/update_from', tm)
    }
    const formSubmit = async (url, props) => {
        await request.post(url, props).then((res) => {
            Toast.success("操作成功")
            setTimeout(() => {
                window.location.href = '/xxk/xxk_contract'
            }, 800)
        })
    }
    return (
        <>
            <Row>
                <Col span={12}>
                    <Input field="contractname" label="合同名称：" trigger='blur' style={{ width: 400 }} />
                </Col>
                <Col span={12}>
                    <DatePicker field="signdate" type="date" initValue="2024-01-02" label='合同签订日期：' style={{ width: 400 }} placeholder='请选择生效日期' />
                </Col>
            </Row>

            <Row>
                <Col span={12}>
                    <DatePicker field="acceptdate" type="date" initValue="2024-01-03" label='验收日期' style={{ width: 400 }} placeholder='请选择生效日期' />
                </Col>
                <Col span={12}>
                    <InputNumber field="expirdate" label="合同有效期(年)" initValue={3} min={1} max={10} defaultValue={1} />
                </Col>
            </Row>

            <Row>
                <Col span={12}>
                    <Form.Select field="userdept" label="科室" initValue='信息部' style={{ width: 400 }}>
                        <Form.Select.Option value="信息部">信息部</Form.Select.Option>
                        <Form.Select.Option value="采购部">采购部</Form.Select.Option>
                        <Form.Select.Option value="护理部">护理部</Form.Select.Option>
                    </Form.Select>
                </Col>
                <Col span={12}>
                    <Input field="purchaser" label="采购人" initValue="尹" trigger='blur' style={{ width: 400 }} />
                </Col>
            </Row>
            <Row>
                <InputNumber field="amount" label="合同金额(元)" initValue={100} min={1} />
            </Row>
            <Row>
                <TextArea
                    style={{ width: '90%', height: 100 }}
                    field='tips'
                    label='备注信息'
                    placeholder='请填写备注信息'
                    initValue='备注'
                />
            </Row>

            <Divider margin='12px'></Divider>

            <ArrayField field='comps' initValue={comps}>
                {({ add, arrayFields, addWithInitValue }) => (
                    <React.Fragment>
                        {
                            arrayFields.map(({ field, key, remove }, i) => (
                                <div key={key} style={{ width: 1000, display: 'flex' }}>
                                    <Element field={field} remove={remove} />
                                </div>
                            ))
                        }
                        <Button onClick={add} icon={<IconPlus />} theme='light'></Button>
                    </React.Fragment>
                )}
            </ArrayField>


            <Divider margin='12px'></Divider>
            <h2>合同文件</h2>
            <FileListWithAppend item_id={xxk_contract_id} type={"htsc"}></FileListWithAppend>
            <h2>采购申请/合同审批</h2>
            <FileListWithAppend item_id={xxk_contract_id} type={"cght"}></FileListWithAppend>
            <h2>上会文件</h2>
            <FileListWithAppend item_id={xxk_contract_id} type={"shwj"}></FileListWithAppend>
            <h2>供应商资质</h2>
            <FileListWithAppend item_id={xxk_contract_id} type={"gyszz"}></FileListWithAppend>
            <h2>其他文件</h2>
            <FileListWithAppend item_id={xxk_contract_id} type={"qtwj"}></FileListWithAppend>
            <Divider margin='12px'></Divider>

            <Button theme='solid' type='primary' onClick={(submitmsg)}>提交</Button>
        </>
    );
}
