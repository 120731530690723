import { PermRouteObj, RouteObj } from './utils/route_obj';
import App from './App';
import Login from './app/auth/login/js/login';
// import TaskIndex from './app/xxk-task/pages/task_index';
// import TaskOverview from './app/xxk-task/pages/task_overview';
// import TaskDetail from './app/xxk-task/pages/task_detail';
// import TaskCreate from './app/xxk-task/pages/task_create';

import MgzbHd from './app/hulibu/js/mgzb_ht';
import MyddcHt from './app/hulibu/js/myddc_ht';

import XxkContract from './app/xxk/contract/js/xxk_contract';
import ContracrInsert from './app/xxk/contract/js/contracr_insert';
import ContractDetail from './app/xxk/contract/js/contracr_detail';

import XxkDaily from './app/xxk/xxkdaily/js/xxkdaily';


import UserManage from './app/skyy/admin/js/user_manage';
import UserGroupManage from './app/skyy/admin/js/user_group_manage';
import MenuManage from './app/skyy/admin/js/menu_manage';
import FileUpload from './app/public/file/js/file_upload';
import ExamPool from './app/skyy/exam/js/exampool';
import ExamPoolGuding from './app/skyy/exam/js/exampool_guding';
import ExamPaperPool from './app/skyy/exam/js/exam_paperpool';
import ExamPaperPoolDetail from './app/skyy/exam/pages/exam_paperpool_detail';
import ExamIndex from './app/skyy/exam/js/exam_index';
import UserExam from './app/skyy/exam/js/user_exam';
import ExamUserState from './app/skyy/exam/js/exam_userstate';
import InsertDzyhp from './app/xzhq/dzyhp/js/insert_dzyhp';
import DzyhpList from './app/xzhq/dzyhp/js/dzyhp_list';

import ExamUsersDetail from './app/skyy/exam/pages/exam_users_detail';
import UserDeptInfoManage from './app/skyy/admin/js/user_dept_info_manage';
import MgzbQt from './app/hulibu/js/mgzb_qt';
import MgzbLoubao from './app/hulibu/js/mgzb_loubao';
import MgzbTj from './app/hulibu/js/mgzb_tj';
import MgzbBgHLz from './app/hulibu/js/mgzb_bghlz';
import MyddcQt from './app/hulibu/js/myddc_qt';
import Htzdj from './app/office/ht/js/htzdj';
import YjkGonShi from './app/yjk/gongshi/houduan/yjk_gonshi'
import YjkPhoneShow from './app/yjk/gongshi/qianduan/phone_show'
import YjkComputerShow from './app/yjk/gongshi/qianduan/computer_show'
import TpClientManage from './app/skyy/admin/js/tp_client_manage';
import DzmccgsqbHt from './app/office/ht/js/dzmcht_ht';
import FcghtHt from './app/office/ht/js/fcght_ht';
import CghtHt from './app/office/ht/js/cght_ht';
import YysqHt from './app/office/ht/js/yysq_ht';
import ShouWeiUpload from './app/office/sw/houduan/js/shouwen_upload';
import ShouwenTaiZhang from './app/office/sw/houduan/js/shouwen_taizhang'
import QrcodeSignin from './app/ywb/qrcode_signin';
import ScanCode from './app/ywb/scan_code';
import QrcodeSigninStatic from './app/ywb/qrcode_signin_static';
import ToupiaoHt from './app/renshi/toupiao/js/toupiao_ht';
import ToupiaoQt from './app/renshi/toupiao/js/toupiao_qt';
import AccountList from './app/xxk/account/js/account_list';
import ShouWenYueshi from './app/office/sw/qianduan/shouwen_yueshi';
import ShouWenApprove from './app/office/sw/qianduan/ShouWenApprove';
// 嵌入主页面的router前面不用加斜杠，例如此处'hlb/'的'h'前面没有'/'
const hulibuRouter = new RouteObj(
    "hlb/", null, [
        new PermRouteObj("mgzb_ht",<MgzbHd/>),
        new PermRouteObj("myddc_ht",<MyddcHt/>),
        new PermRouteObj("mgzb_loubao",<MgzbLoubao/>),
        new PermRouteObj("mgzb_tj",<MgzbTj/>),
        new PermRouteObj("mgzb_bghlz",<MgzbBgHLz/>),
    ]
);

// 单独页面的router前面要加斜杠，例如此处'/hlb/'的'h'前面有'/'
const hlbSinglePageRouter = new RouteObj(
    "/hlb/", null, [
        new PermRouteObj("mgzb_qt",<MgzbQt/>),
        new RouteObj("myddc_qt",<MyddcQt/>),
    ]
);

const xxkContract = new RouteObj(
    "xxk/", null, [
        new PermRouteObj("xxk_contract",<XxkContract/>),
        new PermRouteObj("contract_insert",<ContracrInsert/>),
        new PermRouteObj("contract_detail",<ContractDetail/>)
    ]
);

const XxkDailyRouter = new RouteObj(
    "/xxk/", null, [
        new RouteObj("xxk_daily", <XxkDaily/>),
    ],
)


const officeRouter = new RouteObj(
    "office/", null, [
        // new PermRouteObj("dzmc_ht",<DzmcHt/>),
        new PermRouteObj("dzmc_ht",<DzmccgsqbHt/>),
        new PermRouteObj("fcght_ht",<FcghtHt/>),
        new PermRouteObj("cght_ht",<CghtHt/>),
        new PermRouteObj("yysq_ht",<YysqHt/>),
        new PermRouteObj("shouwei_upload",<ShouWeiUpload/>),
        new PermRouteObj("Shouwen_taizhang",<ShouwenTaiZhang/>),
    ]
);

const officeSingleRouter = new RouteObj(
    "/office/", null, [
        new PermRouteObj("htzdj", <Htzdj/>),
        new PermRouteObj("shouwen_yueshi", <ShouWenYueshi/>),
        new PermRouteObj("ShouWenApprove", <ShouWenApprove/>)

    ]
);

const ywkSingleRouter = new RouteObj(
    "/ywb/", null, [
        new RouteObj("sign_code", <QrcodeSignin/>),
        new RouteObj("sign_code_static", <QrcodeSigninStatic/>),
        new RouteObj("scan_code", <ScanCode/>)
    ]
);

const loginRouter = new RouteObj(
    "/login", <Login />
);

// const xxkTaskRouter = new RouteObj(
//     "xxk/task/", <TaskIndex />, [
//     new RouteObj("dashboard", <TaskOverview />),
//     new RouteObj("detail/:type", <TaskDetail />),
//     new RouteObj("create", <TaskCreate />)
// ]
// );

const skyyRouter = new RouteObj(
    "skyy/admin/", null, [
        new PermRouteObj("user_manage", <UserManage/>),
        new PermRouteObj("user_group_manage", <UserGroupManage/>),
        new PermRouteObj("menu_manage", <MenuManage/>),
        new PermRouteObj("user_dept_info_manage", <UserDeptInfoManage/>),
        new PermRouteObj("tp_client_manage", <TpClientManage/>)
    ]
)

// const fileRouter = new RouteObj(
//     "/file", null, [
//         new RouteObj("upload", <FileUpload/>)
//     ]
// )

const examRouter = new RouteObj(
    "skyy/exam/", null, [
        new PermRouteObj("exampool", <ExamPool />),
        new PermRouteObj("exam_paperpool", <ExamPaperPool/>),
        new PermRouteObj("exam_paperpool_detail", <ExamPaperPoolDetail/>),
        new PermRouteObj("exam_userstate", <ExamUserState/>),
        new PermRouteObj("exampool_guding", <ExamPoolGuding/>),
        new PermRouteObj("exam_users_detail", <ExamUsersDetail/>)
    ]
)

const userExamRouter = new RouteObj(
    "/skyy/exam/", null, [
        new PermRouteObj("exam_index", <ExamIndex/>),
        new PermRouteObj("user_exam", <UserExam/>),
    ]
)

const xzhqDzyhpRouter = new RouteObj(
    "xzhq/dzyhp/", null, [
        new RouteObj("add_dzyhp", <InsertDzyhp/>),
        new RouteObj("dzyhp_list", <DzyhpList/>)
    ]
)
const yjkRouter = new RouteObj(
    "yjk/", null, [
        new PermRouteObj("houduan/gonshi", <YjkGonShi/>),
    ],
)
const yjkqianduanRouter = new RouteObj(
    "/yjk/", null, [
        new RouteObj("qianduan/phone_show", <YjkPhoneShow/>),
        new RouteObj("qianduan/computer_show", <YjkComputerShow/>)
    ],
)

const renshiRouter = new RouteObj(
    "renshi/", null, [
        new PermRouteObj("toupiao_ht", <ToupiaoHt/>)
    ],
)

const renshiSingleRouter = new RouteObj(
    "/renshi/", null, [
        new RouteObj("toupiao_qt", <ToupiaoQt/>)
    ],
)

const xxkAccountSingleRouter = new RouteObj(
    "/xxk/account", null, [
        new PermRouteObj("account_list", <AccountList/>)
    ],
)

const mainRouter = new PermRouteObj(
    "/", <App />, [skyyRouter, hulibuRouter, xxkContract, officeRouter, examRouter, xzhqDzyhpRouter,yjkRouter,renshiRouter]
);

const paths = []

function get_paths(route,cur_path) {
    if(route && route.path != null) {
        if(route.children) {
            for(let i in route.children) {
                get_paths(route.children[i], cur_path + route.path)
            }
        } else {
            paths.push(cur_path + route.path)
        }
    }
}



const routers = [mainRouter, loginRouter, userExamRouter, XxkDailyRouter, hlbSinglePageRouter
, officeSingleRouter, yjkqianduanRouter, ywkSingleRouter, renshiSingleRouter, xxkAccountSingleRouter]

for(let i in routers) {
    get_paths(routers[i], "")
}


export {paths}

export default routers;










