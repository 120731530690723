import {React,useState} from 'react';
import {Modal, Form, Toast, Row, Col,  Button } from '@douyinfe/semi-ui';
import { IconUpload, IconDelete } from '@douyinfe/semi-icons';
import requests from '../../../../utils/request_noloading'

export default function AddUserGroupModel({visible,set_visible,setrefresh}) {
    const customRequest = ({ file, onSuccess }) => {
        const formData = new FormData();
        formData.append('file', file.fileInstance);
        formData.append('type', 'yjk_gonshi')
        requests.post("/file/upload", formData).then(data => {
            onSuccess(data)
        })
    }
    const handleCancel = () => {
        set_visible(false);
    };
    const [date,setdate] = useState({})
    const renderFileOperation = (fileItem) => (
        <div style={{ display: 'flex', columnGap: 8, padding: '0 8px' }}>
            <Button onClick={e=>fileItem.onRemove()} icon={<IconDelete></IconDelete>} type="tertiary" theme="borderless" size="small"></Button>
        </div>
    )
    const handleOk = () => {
        requests.post("/yjk/gongshi_date_upload", {
            'title': date['标题'],
            'submit_date': date['发布时间'],
            'submit_user': date['发布人'],
            'content': date['文字内容'],
            'files': date['附件']
        }).then(da => {
            set_visible(false)
            Toast.success('上传成功')
            setrefresh(true)
        })
    };
    return <>
        <Modal
            title="新增公示"
            visible={visible}
            onOk={handleOk}
            onCancel={handleCancel}
            closeOnEsc={true}
            footerFill={true}
            bodyStyle={{ overflow: 'auto', height: 500 }}
        >
            <Form
                onSubmit={values => Toast.info({ content: JSON.stringify(values) })}
                visible = {visible}
                render={({ formState, formApi, values }) => (
                    <div>
                        <Row>
                            <Col span={24}>
                                <Form.Input field='标题' placeholder='标题'/>
                                <Form.TextArea field="文字内容" placeholder="文字内容" size="large"></Form.TextArea>
                                <Form.DatePicker field='发布时间' initValue={new Date()}  />
                                <Form.Input field="发布人" initValue='袁灿烂' disabled={true}/>
                                <Form.Upload customRequest={customRequest}  field="附件"  itemStyle={{ width: 300 }} renderFileOperation={renderFileOperation} >
                                    <Button icon={<IconUpload />} theme="light">上传图片</Button>
                                </Form.Upload>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <center>
                                    <Button htmlType="reset" onClick={()=>{setdate({'发布人':"袁灿烂"})}}>重置</Button>
                                </center>
                            </Col>
                        </Row>
                    </div>
                )}
                onValueChange={values => {
                    setdate(values)
                    console.log(values);
                }}
            >
            </Form>
        </Modal>
    </>
}