import {Button,Table,Typography,Col,Row,Divider } from '@douyinfe/semi-ui';
import React, { useState,useEffect } from 'react';
import request from "../../../../utils/request_noloading"
// import "../../../../css/a.css"

export default function ComputerShow() {
    //展示详情
    const [flag, setFlag] = useState(false);
    const [content, setContent] = useState("");
    //列信息
    const columns = [
        { dataIndex: 'title', title: '标题'}
        , { dataIndex: 'submit_date', title: '日期'}
        , { dataIndex: 'submit_user', title: '发布人'}
        , {title: '查看详情',width:100,dataIndex: 'operate'
        , render: (text, record) => (
            <>
                <Button onClick={()=>{
                    const newContent = record.content.replace(/<br>/g, "\n")
                    setContent(newContent)
                    setFlag(true)
                }}>
                    详情
                </Button>
            </>
        )}
    ];
    //分页条件
    const [size, setSize] = useState(20);
    const [curPage, setcurPage] = useState(1);
    const [total, setTotal] = useState(0);
    //当前页数据
    const [das,setdas] = useState([
        {},
    ])
    //发送请求-查询数据
    const getDatas= async (props,setDate)=>{
        await request.post('/yjk/gongshi_date', props).then((res)=>{
            setDate(res.data.result)
            setTotal(res.data.total)
            console.log(res);
        })
    }
    //加载数据
    useEffect(() => {
        getdtats(curPage, size)
    }, [curPage, size])
    //获取当页数据
    const getdtats = ()=>{
        getDatas({
            currentPage: curPage,                   //页码
            pageSize: size,                         //尺寸
        },setdas)
    }
    //页码变化
    function onChange(currentPage, pageSize) {
        getDatas({
            currentPage: currentPage,                   //页码
            pageSize: pageSize,                         //尺寸
        }, setdas)
        setcurPage(currentPage);                        //当前页数
        setSize(pageSize)
    }
    //布局
    const commonStyle = {
        height: 64,
        lineHeight: '64px',
        background: 'var(--semi-color-fill-0)'
    };
    const { Paragraph, Title } = Typography;

    return <>
        <div className="grid">
            <Row>
                <Col span={12}>            
                    <center><h3>公示目录</h3></center>
                </Col>
                <Col span={12}><center><h3>公示详情</h3></center></Col>
            </Row>
            <Row>
                <Col span={12}><br/>
                    <Table columns={columns} dataSource={das} pagination={{
                            total:total,
                            showSizeChanger:true,
                            defaultCurrentPage:1,
                            currentPage:curPage,
                            pageSize:size,
                            pageSizeOpts:[20, 50, 200],
                            onChange:onChange
                        }} bordered={true} size={'middle'} sticky={true} />
                </Col>
                <Col span={12}>
                    <Paragraph spacing="extended">
                        <Divider margin='21px'/>
                        <h2 style={{wordBreak: 'break-all', whiteSpace: 'pre-wrap'}}>
                            {content=="" ? "点击详情查看" : content}
                        </h2>
                    </Paragraph>
                </Col>
            </Row>
        </div>
    </>
}
