import React from 'react';
import { Select } from '@douyinfe/semi-ui';


export default function DepthSelect({setDepth}) {
    const selectdep = (value)=>{
        setDepth(value)
    }
    return (
        <>
            <Select placeholder="请选择科室" style={{ width: 120 }} showClear onChange={selectdep}>
                <Select.Option value="手足科">手足科</Select.Option>
                <Select.Option value="正骨科/小儿骨科">正骨科/小儿骨科</Select.Option>
                <Select.Option value="胫踝科">胫踝科</Select.Option>
                <Select.Option value="ICU">ICU</Select.Option>
                <Select.Option value="外科">外科</Select.Option>
                <Select.Option value="髋关节科">髋关节科</Select.Option>
                <Select.Option value="肩肘科/运动医学科">肩肘科/运动医学科</Select.Option>
                <Select.Option value="膝关节科">膝关节科</Select.Option>
                <Select.Option value="脊柱科">脊柱科</Select.Option>
                <Select.Option value="内科">内科</Select.Option>
                <Select.Option value="康复科">康复科</Select.Option>
                <Select.Option value="针灸理疗科">针灸理疗科</Select.Option>
            </Select>
        </>
    );
}
