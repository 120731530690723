import React, { useState, useEffect } from 'react';
import { Table, Button, Toast } from '@douyinfe/semi-ui';
import requests from '../../../../utils/request'
import { get_data } from '../../../../utils/data_ctl';
import { CommonSingleUpload } from '../../../public/file/pages/upload';


const columns = [
    {
        title: '钉钉id',
        dataIndex: 'userid',
    },
    {
        title: '部门',
        dataIndex: 'dept_name',
    },
    {
        title: '名称',
        dataIndex: 'username',
    },
]

export default function UserDeptInfoManage() {
    const [dataSource, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [currentPage, setPage] = useState(1);
    const [total, setTotal] = useState(0);
    const [pageSize, setPageSize] = useState(10);

    const fetchData = (cur = currentPage, size = pageSize) => {
        setLoading(true);
        requests.post("/skyy/admin/get_user_dept_info", { currentPage: cur, pageSize: size }).then(res => {
            setLoading(false)
            setData(res.data.result)
            setTotal(res.data.total)
        })
    };

    const handleChange = (cur, size) => {
        setPage(cur)
        setPageSize(size)
    };

    useEffect(() => {
        fetchData(currentPage, pageSize)
    }, [currentPage, pageSize])

    const user = get_data("user")

    const handleUpdate = () => {
        // try {
        //     let response = await fetch(process.env.REACT_APP_SERVER_URL+'/skyy/admin/update_dept_info', {
        //         headers: {
        //             "Authorization": user.token
        //         }
        //     });
        //     console.log(response);

        //     if (!response.ok) {
        //         throw new Error('Network response was not ok');
        //     }

        //     const reader = response.body.getReader();
        //     const textDecoder = new TextDecoder();
        //     let result = true;
        //     let output = ''

        //     while (result) {
        //         const { done, value } = await reader.read();

        //         if (done) {
        //             console.log('Stream ended');
        //             result = false;
        //             break;
        //         }

        //         const chunkText = textDecoder.decode(value);
        //         output += chunkText;
        //         console.log('Received chunk:', chunkText);
        //         Toast.info(JSON.parse(chunkText).name)
        //     }
        // } catch (e) {
        //     console.log(e);
        // }
        requests.get("/skyy/admin/update_dept_info").then(res => {
            Toast.success("更新成功")
            fetchData()
        })
    }

    const handleImportGonghao = () => {
        Toast.success("导入成功")
        fetchData()
    }


    return <>
        <div style={{ width: 500 }}>
            <Button onClick={handleUpdate}>更新用户部门信息</Button>
            <CommonSingleUpload onSuccess={handleImportGonghao} url='/skyy/admin/import_gonghao_info' btn_text='导入工号信息'/>
        </div>
        <Table
            columns={columns}
            dataSource={dataSource}
            pagination={{
                currentPage,
                pageSize: pageSize,
                total: total,
                onChange: handleChange,
                showSizeChanger: true,
                pageSizeOpts: [20, 50, 100, 500, 700]
            }}
            loading={loading}
            bordered={true}
            sticky={true}
        />
    </>
}