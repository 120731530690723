import React, { useEffect, useState } from 'react';
import { Tree, ButtonGroup, Button, Toast, Modal, Skeleton, Dropdown } from '@douyinfe/semi-ui';
import { IconDelete, IconPlusCircle } from '@douyinfe/semi-icons';
import requests from '../../../../utils/request';
import AddMenu from './add_menu';
import { CommonSingleUpload } from '../../../public/file/pages/upload';

let cur_parent_id = null

let openMenuAddModel = () => { }

function Ops({ data }) {
    return <>
        <ButtonGroup
            size="small"
            theme="borderless"
        >
            <Button
                onClick={e => {
                    cur_parent_id = data.menu_id
                    openMenuAddModel()
                    e.stopPropagation();
                }}
                icon={<IconPlusCircle />}
            />
            <Button
                icon={<IconDelete />}
                type='danger'
            />
        </ButtonGroup>
    </>
}

function menu_wrapper(data, level) {
    return {
        key: data.menu_id + "",
        value: data.menu_id,
        parent_id: data.parent_id,
        level: level,
        label: (
            <Dropdown
                trigger={'contextMenu'}
                position={'bottom'}
                render={
                    <Dropdown.Menu>
                        <Dropdown.Item onClick={() => { Toast.info("编辑") }}>编辑</Dropdown.Item>
                    </Dropdown.Menu>
                }
            >
                <div style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                }}>
                    <strong><span style={{ color: 'var(--semi-color-primary)' }}>{level === 0 ? '[根]' : `[${data.location}]` + '*'.repeat(level)}</span>{data.name}</strong>
                    <Ops data={data} />
                </div>
            </Dropdown>
        ),
        children: []
    }
}

export function generate_tree(parent_id, source, result, level = 0) {
    if (!source) return []
    const newSource = []
    const rootMenus = []
    source.forEach(val => {
        if (val.parent_id === parent_id) {
            rootMenus.push(val)
        } else {
            newSource.push(val)
        }
    })
    if (rootMenus) {
        for (const i in rootMenus) {
            const menu = rootMenus[i]
            const m = menu_wrapper(menu, level)
            result.push(m)
            m.children = generate_tree(menu.menu_id, newSource, m.children, level + 1)
        }
    }
    return result
}

// function addDataToTree(data, treeData, parent_id = null) {
//     if (treeData != null) {
//         if (parent_id == null) {
//             treeData.push(menu_wrapper(data))
//             return treeData
//         }
//         for (const i in treeData) {
//             let val = treeData[i]
//             if (val.value === parent_id) {
//                 val.children.push(menu_wrapper(data))
//             } else {
//                 addDataToTree(data, val.children, parent_id)
//             }
//         }
//     }
//     return treeData
// }

export default function MenuList() {
    const [treeData, setTreeData] = useState([])
    const [visible, setVisible] = useState(false)
    const [addMenuData, setAMData] = useState({})
    const [skLoading, setSkLoading] = useState(true)
    openMenuAddModel = () => setVisible(true)
    const handleOk = () => {
        addMenuData['parent_id'] = cur_parent_id
        requests.post("/skyy/admin/add_menu", addMenuData).then(res => {
            // let newData = treeData.concat()
            // addDataToTree(res.data, newData, cur_parent_id)
            // console.log(newData)
            // setTreeData(newData)
            // setVisible(false)
            // Toast.success("新增成功")
            requests.get("/skyy/admin/get_menu_list").then(res => {
                setTreeData(generate_tree(null, res.data, []))
                setVisible(false)
                Toast.success("新增成功")
            })

        })
    };
    const handleCancel = () => {
        setVisible(false);
    };
    const handleAfterClose = () => {

    };
    useEffect(() => {
        requests.get("/skyy/admin/get_menu_list").then(res => {
            setTreeData(generate_tree(null, res.data, []))
            setSkLoading(false)
        })
    }, [])

    const style = {
        display: 'flex',
        alignItems: 'flex-start',
        marginTop: 10
    };

    const handleMenuExport = () => {
        requests.get("/skyy/admin/export_menus", { responseType: 'blob' }).then(res => {
            const url = window.URL.createObjectURL(new Blob([res.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'export_menus.json'); // 设置文件下载的文件名
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link)
        })
    }

    const handleMenuImport = () => {
        Toast.success("导入成功")
        requests.get("/skyy/admin/get_menu_list").then(res => {
            setTreeData(generate_tree(null, res.data, []))
            setSkLoading(false)
        })
    }

    const placeholder = (
        <div style={style}>
            <div>
                <Skeleton.Paragraph style={{ width: 300 }} rows={5} />
            </div>
        </div>
    );
    return <>
        <div>
            <Button
                onClick={e => {
                    setVisible(true)
                    cur_parent_id = null
                }}
            >
                新增根菜单
            </Button>
            <Button
                style={{ marginLeft: '10px' }}
                onClick={handleMenuExport}
                theme='solid'
            >
                导出菜单数据
            </Button>
            <CommonSingleUpload
                url='/skyy/admin/import_menus'
                onSuccess={handleMenuImport}
                btn_text='导入菜单数据'
            />
        </div>
        <Skeleton placeholder={placeholder} loading={skLoading} active>
            <Tree
                treeData={treeData}
                style={{
                    width: '35%',
                    height: '100%'
                }}
                expandAction={'click'}
            />
        </Skeleton>
        <Modal
            title={`新增菜单`}
            visible={visible}
            onOk={handleOk}
            afterClose={handleAfterClose} //>=1.16.0
            onCancel={handleCancel}
            okText={'新增'}
            cancelText={'取消'}
            closable={false}
        >
            <AddMenu setData={setAMData} />
        </Modal>
    </>
}