import { Toast } from "@douyinfe/semi-ui";
import requests from "./request_noloading";

export function download_file({ path, name }) {
  requests
    .get("/file/download", {
      params: { path },
      responseType: "blob",
    })
    .then((res) => {
      if (res.code === 200) {
        const url = window.URL.createObjectURL(new Blob([res.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", name); // 设置文件下载的文件名
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      } else {
        const response = res;
        if (response.data.type === "application/json") {
          const reader = new FileReader(); //创建一个FileReader实例
          reader.readAsText(response.data, "utf-8"); //读取文件,结果用字符串形式表示
          reader.onload = function () {
            //读取完成后,**获取reader.result**
            const { msg } = JSON.parse(reader.result);
            Toast.error(msg); //弹出错误提示
          };
        }
      }
    });
}

export async function get_file_url({ path }) {
  return process.env.REACT_APP_SERVER_URL + "/file/download?path=" + path;
}

export function get_file_url_sync({ path }) {
  return process.env.REACT_APP_SERVER_URL + "/file/download?path=" + path;
}

export function del_file(path, file_id, cb = () => {}) {
  requests
    .get("/file/del_file", {
      params: {
        path,
        file_id,
      },
    })
    .then((res) => {
      cb(res);
    });
}
