import React, { useState, useEffect } from 'react';
import { Table, Button, Toast, Form } from '@douyinfe/semi-ui';
import requests from '../../../../utils/request'
import ExamPoolEditor from '../pages/exampool_editor';
import DelButton from '../../../../component/del_button';


let setRecord2 = null
let setPoolEditorVisible2 = null
let getdas = null
let setDas = null

// const operation = (text, record) => {
//     const handleEdit = () => {
//         setRecord2(record)
//         setPoolEditorVisible2(true)
//     }

//     const handleDel = () => {
//         requests.post("/skyy/exam/delete_exampool", {
//              exam_id: record.exam_id 
//         }).then(res => {
//             Toast.success("删除成功")
//             let key = record.exam_id
//             const das = getdas()
//             let newDataSource = [...das];
//             if (key != null) {
//                 let idx = newDataSource.findIndex(data => data.exam_id === key);
//                 if (idx > -1) {
//                     newDataSource.splice(idx, 1);
//                     setDas(newDataSource);
//                 }
//             }
//         })
//     }
//     return <>
//         <Button
//             size='small'
//             theme="solid"
//             style={{ marginLeft: 10, marginRight: 10 }}
//             onClick={handleEdit}
//         >
//             编辑
//         </Button>
//         <DelButton onOk={handleDel} desc='删除' />
//     </>
// }

const columns = [
    {
        title: '医学分类',
        dataIndex: 'exampaper_medicate',
    },
    {
        title: '题型分类',
        dataIndex: 'exampaper_category',
    },
    {
        title: '题目名称',
        dataIndex: 'exampaper_topic',
    },
    {
        title: '题目答案',
        dataIndex: 'exampaper_answer',
    }
]



export default function ExamPaperPoolDetail() {
    const [dataSource, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [currentPage, setPage] = useState(1);
    const [total, setTotal] = useState(0);
    const [pageSize, setPageSize] = useState(100);
    const [record, setRecord] = useState({})
    const [poolEditorVisible, setPoolEditorVisible] = useState(false)
    const urlSearchParams = new URLSearchParams(window.location.search);
    const examPaperId = Object.fromEntries(urlSearchParams.entries()).examPaperId;

    setRecord2 = setRecord

    setPoolEditorVisible2 = setPoolEditorVisible

    setDas = setData

    getdas = () => dataSource

    const fetchData = (cur = currentPage, size = pageSize) => {
        setLoading(true);
        requests.post("/skyy/exam/exam_paperpool_detail", { currentPage: cur, pageSize: size, examPaperId }).then(res => {
            setLoading(false)
            setData(res.data.result)
            setTotal(res.data.total)
        })
    };

    const handleChange = (cur, size) => {
        setPage(cur)
        setPageSize(size)
    };

    useEffect(() => {
        fetchData(currentPage, pageSize)
    }, [currentPage, pageSize])

    return <>
        <Table
            columns={columns}
            rowKey={'examuser_id'}
            dataSource={dataSource}
            pagination={{
                currentPage,
                pageSize: pageSize,
                total: total,
                onChange: handleChange,
                showSizeChanger: true,
                pageSizeOpts: [10, 20, 30, 40, 50, 100, 500]
            }}
            loading={loading}
            bordered={true}
            sticky={true}
        />
        <ExamPoolEditor record={record} visible={poolEditorVisible} setVisible={setPoolEditorVisible} fetchData={fetchData} />
    </>
}
