import { useRef, useState } from 'react';
import { Button, Divider, Form, Toast } from '@douyinfe/semi-ui';
import requests from '../../../utils/request'
import { dept_arr } from './mgzb_qt';

const desc = ["非常不满意😱", "不满意😅", "不是很满意😥", "满意😀", "比较满意😁", "非常满意🥳"]

export default function MyddcQt() {
    const [loading, setLoading] = useState(false)
    document.title = "患者满意度调查"
    const { Select, DatePicker, Rating, Input, TextArea, RadioGroup, Radio } = Form
    const handleSubmit = () => {
        api.current.validate()
            .then(values => {
                // 表单字段校验成功的操作
                setLoading(true)
                for(let i = 1; i <= 14; i++) {
                    values[`a${i}`] = values[`a${i}`] - 1
                }
                requests.post("/hulibu/myddc_submit", values).then(res => {
                    Toast.success({content: "提交成功, 感谢您的支持与配合🤝", onClose: () => window.location.reload(true)})
                }).finally(() => setLoading(false))
            })
            .catch(errors => {
                // 校验失败的操作
                for (let key in errors) {
                    // 滚动到出错的位置
                    api.current.scrollToField(key)
                    break
                }
            })
    }
    const api = useRef();




    return <>
        <div style={{ marginLeft: "0.5em" }}>
            <h3 style={{ marginBlock: "0.5em" }}>尊敬的病友：您好！</h3>
            <h3 style={{ marginBlock: "0.5em" }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;感谢您在住院期间对我们工作的支持与配合，请您如实填写此表，并请您写出 1-2
                名“您最满意的护士”，谢谢您的理解与支持！</h3>
        </div>

        <Divider/>
        <div style={{ width: '80%', margin: '0 auto' }}>
            <Form
                style={{ padding: 10, width: '100%' }}
                getFormApi={formApi => api.current = formApi}
            >

                <Rating
                    field='a1'
                    label='1、您初入病房时，首诊护士的接待您是否满意？'
                    allowClear={false}
                    initValue={6}
                    count={6}
                    onChange={value => {
                        Toast.info({ content: desc[value - 1], id: 1, showClose: false, textMaxWidth: 250, duration: 1 })
                    }}
                />
                <Divider />
                <Rating
                    field='a2'
                    label='2、入院时，接待您的护士是否给您介绍住院环境及相关事项？'
                    allowClear={false}
                    initValue={6}
                    count={6}
                    onChange={value => {
                        Toast.info({ content: desc[value - 1], id: 1, showClose: false, textMaxWidth: 250, duration: 1 })
                    }}
                />
                <Divider />
                <Rating
                    field='a3'
                    label='3、您是否知晓您的责任护士姓名？'
                    allowClear={false}
                    initValue={6}
                    count={6}
                    onChange={value => {
                        Toast.info({ content: desc[value - 1], id: 1, showClose: false, textMaxWidth: 250, duration: 1 })
                    }}
                />
                <Divider />
                <Rating
                    field='a4'
                    label='4、住院期间您对责任护士的服务是否满意？'
                    allowClear={false}
                    initValue={6}
                    count={6}
                    onChange={value => {
                        Toast.info({ content: desc[value - 1], id: 1, showClose: false, textMaxWidth: 250, duration: 1 })
                    }}
                />
                <Divider />
                <Rating
                    field='a5'
                    label='5、责任护士对您的疾病所给子的健康知识讲解是否满意'
                    allowClear={false}
                    initValue={6}
                    count={6}
                    onChange={value => {
                        Toast.info({ content: desc[value - 1], id: 1, showClose: false, textMaxWidth: 250, duration: 1 })
                    }}
                />
                <Divider />
                <Rating
                    field='a6'
                    label='6、您对护士的操作技术是否满意？'
                    allowClear={false}
                    initValue={6}
                    count={6}
                    onChange={value => {
                        Toast.info({ content: desc[value - 1], id: 1, showClose: false, textMaxWidth: 250, duration: 1 })
                    }}
                />
                <Divider />
                <Rating
                    field='a7'
                    label='7、当您遇到问题时，您满意护士的解释与处理吗？'
                    allowClear={false}
                    initValue={6}
                    count={6}
                    onChange={value => {
                        Toast.info({ content: desc[value - 1], id: 1, showClose: false, textMaxWidth: 250, duration: 1 })
                    }}
                />
                <Divider />
                <Rating
                    field='a8'
                    label='8、护士能主动到您床旁巡视吗？'
                    allowClear={false}
                    initValue={6}
                    count={6}
                    onChange={value => {
                        Toast.info({ content: desc[value - 1], id: 1, showClose: false, textMaxWidth: 250, duration: 1 })
                    }}
                />
                <Divider />
                <Rating
                    field='a9'
                    label='9、您认为护士的微笑服务如何？'
                    allowClear={false}
                    initValue={6}
                    count={6}
                    onChange={value => {
                        Toast.info({ content: desc[value - 1], id: 1, showClose: false, textMaxWidth: 250, duration: 1 })
                    }}
                />
                <Divider />
                <Rating
                    field='a10'
                    label='10、责任护士是否协助您生活照顾（必要时）'
                    allowClear={false}
                    initValue={6}
                    count={6}
                    onChange={value => {
                        Toast.info({ content: desc[value - 1], id: 1, showClose: false, textMaxWidth: 250, duration: 1 })
                    }}
                />
                <Divider />
                <Rating
                    field='a11'
                    label='11、您对费用清单上的收费理解吗？'
                    allowClear={false}
                    initValue={6}
                    count={6}
                    onChange={value => {
                        Toast.info({ content: desc[value - 1], id: 1, showClose: false, textMaxWidth: 250, duration: 1 })
                    }}
                />
                <Divider />
                <Rating
                    field='a12'
                    label='12、您对病房的卫生状况是否满意？'
                    allowClear={false}
                    initValue={6}
                    count={6}
                    onChange={value => {
                        Toast.info({ content: desc[value - 1], id: 1, showClose: false, textMaxWidth: 250, duration: 1 })
                    }}
                />
                <Divider />
                <Rating
                    field='a13'
                    label='13、您对病房的安静程度是否满意？'
                    allowClear={false}
                    initValue={6}
                    count={6}
                    onChange={value => {
                        Toast.info({ content: desc[value - 1], id: 1, showClose: false, textMaxWidth: 250, duration: 1 })
                    }}
                />
                <Divider />
                <Rating
                    field='a14'
                    label='14、您对护士长的工作是否满意？'
                    allowClear={false}
                    initValue={6}
                    count={6}
                    onChange={value => {
                        Toast.info({ content: desc[value - 1], id: 1, showClose: false, textMaxWidth: 250, duration: 1 })
                    }}
                />
                <Divider />
                <Input
                    field='a15'
                    label='15、请您写下最满意的护士的姓名：'
                    size='large'
                    prefix="姓名1:"
                    allowEmptyString={true}
                    initValue={""}
                />
                <Input
                    field='a16'
                    noLabel={true}
                    prefix="姓名2:"
                    size='large'
                    allowEmptyString={true}
                    initValue={""}
                />
                <Divider />
                <Input
                    field='a17'
                    label='16、请您写下最不满意的护士的姓名：'
                    size='large'
                    prefix="姓名1:"
                    allowEmptyString={true}
                    initValue={""}
                />
                <Input
                    field='a18'
                    noLabel={true}
                    prefix="姓名2:"
                    size='large'
                    allowEmptyString={true}
                    initValue={""}
                />
                <Divider />
                <TextArea
                    field="a19"
                    label="17、请您留下宝贵的意见与建议，我们将更加努力："
                    allowEmptyString={true}
                    initValue={""}
                />
                <Divider />
                <RadioGroup label="18、您对我院护理工作的整体评价" field="a20" initValue={"非常满意"}>
                    <Radio value={"非常满意"}>非常满意</Radio>
                    <Radio value={"满意"}>满意</Radio>
                    <Radio value={"基本满意"}>基本满意</Radio>
                    <Radio value={"不满意"}>不满意</Radio>
                </RadioGroup>
                <Divider />
                <Input
                    field='bednum'
                    label="床位"
                    size='large'
                    rules={[{ required: true, message: '请填写您的床位' }]}
                />
                <Input
                    field='patientname'
                    label="姓名"
                    size='large'
                    rules={[{ required: true, message: '请填写您的姓名' }]}
                />
                <Select
                    field="dept"
                    label="科室"
                    placeholder="请选择"
                    autoComplete={"false"}
                    size='large'
                    rules={[{ required: true, message: '请选择科室' }]}
                >
                    {dept_arr.map(dept => <Select.Option value={dept}>{dept}</Select.Option>)}
                </Select>

                <DatePicker
                    field="thedate"
                    label="日期"
                    initValue={new Date()}
                    size='large'
                    disabled
                />
                <Button loading={loading} size='large' theme="solid" block onClick={handleSubmit}>提交</Button>
            </Form>

            <br />
            <br />
            <br />
        </div>
    </>
}