import { useEffect, useState } from "react"
import { useQRCode } from 'next-qrcode'

export default function QrcodeSignin() {
    const [text, setText] = useState(new Date().getTime().toString())
    const { Canvas } = useQRCode()
    // 获取当前页面的URL
    const url = new URL(window.location);

    // 使用URLSearchParams解析查询参数
    const searchParams = new URLSearchParams(url.search);

    // 获取特定的查询参数
    const train_times_id = searchParams.get('train_times_id');
    useEffect(() => {
        setInterval(function () {
            setText(JSON.stringify({ t: new Date().getTime(), train_times_id: train_times_id }))
        }, 3000); // 每3秒执行一次
    }, [])

    return <>
        <div style={{ textAlign: "center" }}>
            <Canvas
                text={text}
                options={{
                    type: 'image/jpeg',
                    quality: 0.3,
                    errorCorrectionLevel: 'M',
                    margin: 10,
                    scale: 4,
                    width: 600,
                    color: {
                        dark: '#000000ff',
                        light: '#ffffffff',
                    },
                }}
            />
        </div>

    </>
}