import axios from 'axios';
import { Toast, Modal } from '@douyinfe/semi-ui';
import { get_data, set_data } from './data_ctl';
import moment from 'moment'
import { nanoid } from 'nanoid'
import { to_login } from './location_util';


set_data("token_exp_ts", -1, true)

// 1.创建axios实例
const service = axios.create({
  // 公共接口--,url = base url + request url

  baseURL: process.env.REACT_APP_SERVER_URL,

  // baseURL: 'https://api.example.com',
  // 超时时间 单位是ms，这里设置了10s的超时时间
  timeout: 20 * 1000
})

Date.prototype.toISOString = function () {
  return moment(this).format('YYYY-MM-DD HH:mm:ss')
}

// 2.请求拦截器request interceptor
service.interceptors.request.use(
  config => {
    // 发请求前做的一些处理，数据转化，配置请求头，设置token,设置loading等，根据需求去添加
    // 注意使用token的时候需要引入cookie方法或者用本地localStorage等方法，推荐js-cookie
    // config.params = {'token': token}    // 如果要求携带在参数中
    // config.headers.token = token;       // 如果要求携带在请求头中
    // bearer：w3c规范
    const user = get_data('user')
    if (user) {
      config.headers['Authorization'] = user.token
    }

    config.headers['ts'] = Date.now()

    config.headers['at-id'] = nanoid()

    return config
  },
  error => {
    // do something with request error
    // console.log(error) // for debug
    return Promise.reject(error)
  }
)

// 设置cross跨域 并设置访问权限 允许跨域携带cookie信息,使用JWT可关闭
service.defaults.withCredentials = false

// 自定义的code处理
service.interceptors.response.use(
  // 接收到响应数据并成功后的一些共有的处理，关闭loading等
  response => {
    // 响应为文件时直接返回response
    if(response.request.responseType === "blob") {
      return response
    }
    const res = response.data
    // 如果自定义代码不是200，则将其判断为错误。
    if (res.code !== 200) {
      // 处理凭证失效问题
      if (res.code === 600) {
        if (window.location.pathname !== "/login") {
          to_login()
        }
        // const cur_ts = parseInt(new Date().getTime() / 1000)
        // const save_ts = get_data("token_exp_ts", true)
        // // 防止重复弹窗
        // if (cur_ts - save_ts > 60) {
        //   set_data("token_exp_ts", cur_ts, true)
        //   Modal.warning({
        //     title: '凭证失效, 请重新登录', content: "错误信息: " + res.msg, hasCancel: false,
        //     onOk: () => {
        //       setTimeout(() => { window.location.href = '/login?redirect=' + window.location.pathname + window.location.search }, 300)
        //     }
        //   });
        // }
        return Promise.reject(new Error(res.msg || 'Error'))
      }
      // 提示型异常，交给具体业务自行处理
      if(res.code === -2) {
        return res
      }
      Toast.error({ content: res.msg, duration: 5, theme: 'light' })
      return Promise.reject(new Error(res.msg || 'Error'))
    }
    return res
  },
  error => {
    /** *** HTTP状态码异常响应的处理 *****/
    Toast.destroyAll()
    if (error.response) {
      console.log(error)
      if (error.response.status === 400) {
        Toast.error({ "content": "重复请求, 请刷新页面后重试", duration: 2, theme: "light" })
      }
      else if (error.response.status === 401 && window.location.pathname !== "/login") {
        to_login()
      }
      else if (error.response.status === 406) {
        Toast.error({ "content": "权限不足", duration: 2, theme: "light" })
      }
      else if (error.response.status === 422) {
        // Unprocessable Entity
        Toast.error({ "content": "数据解析失败, 请检查表单是否填写正确! ", duration: 3, theme: "light" })
      }
      else if (error.response.status === 423) {
        // 弹出验证码界面
        //   changeVisible(true)
      }
      else if (error.response.status === 500) {
        if (error.response.data.msg) {
          Toast.error({ "content": error.response.data.msg, duration: 5, theme: "light" })
        }
      }
    } else if (error.request) {
      if (error.message === "Network Error") {
        Toast.error({ "content": "网络异常，请检查网络连接后重试", duration: 2, theme: "light" })
      } else {
        Toast.error({ "content": "服务器无响应, 请联系信息科", duration: 5, theme: "light" })
      }
    } else {
      Toast.error({ "content": "一个未知错误导致无法发出请求(建议更换设备后重试): " + error.message, duration: 5, theme: "light" })
    }

    return Promise.reject(error)
  }
)

export default service