import { Modal, Form, Toast } from "@douyinfe/semi-ui";
import { debounce } from 'lodash-es';
import { useState, useRef } from "react";
import requests from '../../../../utils/request'
import requests_nl from '../../../../utils/request_noloading'


export default function AddUser({ visible, setVisible, fetch_d }) {
    const api = useRef();
    const { Input, AutoComplete } = Form

    const [search_loading, setSearchLoading] = useState(false)
    const [list, setList] = useState([]);
    // const [value, setValue] = useState('');


    let datas = {}

    const handleOk = () => {
        console.log(datas)
        requests.post("/skyy/admin/reg_user", datas).then(res => {
            Toast.success("新增成功")
            setVisible(false)
            fetch_d()
        })

    }

    const handleAfterClose = () => {

    }

    const handleCancel = () => {
        setList([])
        setVisible(false)
    }

    const onSearch = (inputValue) => {
        setSearchLoading(true);
        let result = [];
        if (inputValue) {
            requests_nl.post("/skyy/admin/search_dd_user?key=" + inputValue).then(res => {
                result = res.data.map(val => {
                    return { label: val.username, value: val.username, id: val.userid, dept: val.dept_name }
                })
                setSearchLoading(false);
                setList(result);
            })
        } else {
            setSearchLoading(false);
            setList([])
        }
    }

    const handleSelect = (value) => {
        api.current.setValue("third_party_id", value.id)
    }

    const renderItem = (item) => {
        return (
            <div>
                <div>{item.label}</div>
                <div>部门: {item.dept}</div>
            </div>
        );
    }

    const renderSelectedItem = (item) => {
        // 注意：与Select不同，此处只能返回String类型的值，不能返回ReactNode
        return item ? item.value : ""
    }


    return <>
        <Modal
            title={`新增用户`}
            visible={visible}
            onOk={handleOk}
            afterClose={handleAfterClose} //>=1.16.0
            onCancel={handleCancel}
            okText={'新增'}
            cancelText={'取消'}
            width={600}
            closable={false}

        >
            <Form getFormApi={formApi => api.current = formApi} labelPosition={"top"} style={{ width: '100%' }} render={({ formState, formApi, values }) => (
                <>
                    {/* 改为使用AutoComplete */}
                    <AutoComplete
                        label="用户名"
                        field="username"
                        data={list}
                        style={{ width: 250 }}
                        onSearch={onSearch}
                        loading={search_loading}
                        onSelectWithObject
                        renderItem={renderItem}
                        renderSelectedItem={renderSelectedItem}
                        onSelect={handleSelect}
                        autoFocus={false}
                    ></AutoComplete>
                    <Input field="third_party_id" label='三方应用id' style={{ width: 300 }}></Input>
                    <Input field="password" label='密码' style={{ width: 300 }}></Input>
                </>
            )} onValueChange={values => datas = values}>
            </Form>
        </Modal>
    </>
}