import { Modal, Table, Toast } from '@douyinfe/semi-ui';
import { useEffect, useState } from 'react';
import FileListWithAppend from '../../../public/file/pages/file_list_with_append';

export default function ShowFileList({visible,setvisible, item_id, type }) {
    const Cancel = () => {
        setvisible(false);
    };
    return <>
        <Modal
            title="附件管理"
            visible={visible}
            onOk={Cancel}
            onCancel={Cancel}
            closeOnEsc={true}
            footerFill={true}
            bodyStyle={{ overflow: 'auto', height: 500 }}
        >
            <FileListWithAppend 
                item_id={item_id}
                type={type}
            ></FileListWithAppend>
        </Modal>
    </>
}