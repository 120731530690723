import { React, useEffect, useState } from "react";
import { Form, Descriptions, Toast, Row, Button, TextArea, Col } from "@douyinfe/semi-ui";
import requests from "../../../../utils/request_noloading";
import FileList from "../../../public/file/pages/file_list";
import { convertUrlParamsToJson } from "../../../../utils/location_util";
import ShouwenChoseUser from "../houduan/shouwen_choseuser"

export default function ShouWenApprove() {
  const [sw_data, set_sw_data] = useState({});
  const [load, set_load] = useState(false)
  const [users_A, setUserA] = useState([])       //第一列选择的用户信息
  const [date, setdate] = useState({})
  const [ldps, setldps] = useState("")           //领导批示
  const [formApi, setformApi] = useState([])     //formApi
  const [showchoseuser_fA, setShowChoseUserFA] = useState(false);
  //去除第一列用户数据
  const remove_user_A = (removedValue, idx) => {
    let tm = []
    users_A.forEach((item) => {
      if (item['username'] != removedValue) {
        tm.push(item)
      }
    })
    setUserA(tm)
  }
  const sw_id = convertUrlParamsToJson(window.location.search)["sw_id"];
  useEffect(() => {
    if (sw_id) {
      set_load(true)
      requests
        .get("/office/get_shouwen_contents_by_id", {
          params: { sw_id: sw_id },
        })
        .then((res) => {
          console.log(res);
          set_sw_data(res.data['sw']);
          let tmp = ""
          res.data['ldps'].forEach((element, index) => {
            tmp = tmp + element.username + ":" + element.ldps + "\n"
          })
          setldps(tmp)
        })
        .finally(() => {
          set_load(false)
        })
    }
  }, []);

  const submit = ()=>{
    requests.post("/office/send_shouwen_to_user",{'sw_id':sw_id,'users':users_A}).then((res=>{
      Toast.success("收文发送成功。")
    }))
  }

  return <>
      <ShouwenChoseUser
        visible={showchoseuser_fA}
        setvisible={setShowChoseUserFA}
        users={users_A}
        setUser={setUserA}
        formApi={formApi}
        field={"User_A"}
      ></ShouwenChoseUser>
    <div style={{margin: 20}}>

    
      <Row>
        <center>
          <h2>收文阅示</h2>
        </center>
        <Descriptions>
          <Descriptions.Item itemKey="发文单位">
            {sw_data['fwdw']}
          </Descriptions.Item>
          <Descriptions.Item itemKey="发文时间">{sw_data['fwsj']}</Descriptions.Item>
          <Descriptions.Item itemKey="原文编号">{sw_data['ywbh']}</Descriptions.Item>
          <Descriptions.Item itemKey="原文标题">{sw_data['ywbt']}</Descriptions.Item>
          <Descriptions.Item itemKey="收文时间">{sw_data['swsj']}</Descriptions.Item>
          <Descriptions.Item itemKey="收文途径">{sw_data['swtj']}</Descriptions.Item>
          <Descriptions.Item itemKey="批阅范围">{sw_data['pyfw']}</Descriptions.Item>
          <Descriptions.Item itemKey="备注">{sw_data['bz']}</Descriptions.Item>
        </Descriptions>
      </Row>

      <Form
        labelPosition={'left'} labelWidth={'100px'} labelAlign={'left'}
        render={({ formState, formApi, values }) => (
          <div>

              <Row>
                <h4>附件查看</h4>
                <FileList item_id={sw_id} type="shouwen" show_download={true} />
              </Row>

              <Row>
                <h4>领导批示</h4>
                <TextArea value={ldps}></TextArea>
              </Row>

              <Row>
                <Col span={20}>
                  <Form.TagInput field="User_A" label='科室负责人' initValue={[]} style={{ 'width': '90%' }} onRemove={remove_user_A} />
                </Col>
                <Col offset={1} span={3}>
                  <Button type='primary' style={{ marginTop: 12 }} onClick={() => { setShowChoseUserFA(true); setformApi(formApi) }}>新增</Button>
                </Col>
              </Row>

              <Row>
                <Button type='primary' style={{ marginTop: 12 }} onClick={submit}>提交</Button>
              </Row>
          </div>
        )}
        onValueChange={values => {
          setdate(values)
        }}
      />
    </div>
    </>
}
