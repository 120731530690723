import React, { useRef, useState } from 'react';
import { Form, Col, Row, Button, Typography, Divider, Toast } from '@douyinfe/semi-ui';
import { IconUpload, IconFile, IconPlus, IconMinus } from '@douyinfe/semi-icons';
import request from "../../../../utils/request"
import FormDetail from "../pages/FormDetail"


export default class ContracrDetail extends React.Component {
    constructor() {
        super();

        this.state = {
            comps: [],
            formdata: {}
        }
    }

    componentDidMount() {
        // console.log(this.props);
        const urlSearchParams = new URLSearchParams(window.location.search);
        // 把键值对列表转换为一个对象
        const xxk_contract_id = Object.fromEntries(urlSearchParams.entries()).xxk_contract_id;

        request.post('/xxk_contract/from_detail_data?xxk_contract_id=' + xxk_contract_id).then((res) => {
            let xxk_contract_pay_detail = res.data.result.xxk_contract_pay_detail
            let xxk_contract_data = res.data.result.xxk_contract_data

            let tmp = []
            for (let index = 0; index < xxk_contract_pay_detail.length; index++) {
                tmp.push({
                    'date': xxk_contract_pay_detail[index].deadline,
                    'amount': xxk_contract_pay_detail[index].amount,
                    'ispay': xxk_contract_pay_detail[index].is_pay,
                    'tip': xxk_contract_pay_detail[index].tip
                })
            }
            console.log(tmp);
            this.setState({
                comps: tmp
                , formdata: xxk_contract_data
            })
        })
    }


    render() {
        const { Title } = Typography;
        // console.log(this.props);
        const urlSearchParams = new URLSearchParams(window.location.search);
        // 把键值对列表转换为一个对象
        const xxk_contract_id = Object.fromEntries(urlSearchParams.entries()).xxk_contract_id;

        const setcomps = (value) => {
            this.setState({
                comps: value
            })
        }

        return <>
            <Title heading={3} style={{ margin: '8px 0' }} >中医伤科医院合同管理</Title>
            {this.state.comps.length !== 0 && JSON.stringify(this.state.formdata) !== "{}" ? (
                <Form style={{ padding: '10px', width: 1000 }}>
                    <FormDetail setcomps={setcomps} comps={this.state.comps} formdata={this.state.formdata} xxk_contract_id={xxk_contract_id}></FormDetail>
                </Form>) : null}
        </>
    }
}


