import React, { useEffect, useRef } from 'react';
import { Modal, Form, Toast } from '@douyinfe/semi-ui';
import requests from '../../../../utils/request'

let form_data = {}

export default function AddTpClient({ visible, setVisible, fetchData }) {
    const handleOk = () => {

        requests.post("/skyy/admin/add_tp_client", form_data).then(res => {
            Toast.success("新增成功")
            fetchData()
            setVisible(false)
        })
    }

    const handleAfterClose = () => {
        // fetchData()
    }
    const { Input } = Form


    return <>
        <Modal
            title={`client录入`}
            visible={visible}
            onOk={handleOk}
            afterClose={handleAfterClose} //>=1.16.0
            onCancel={() => setVisible(false)}
            okText={'新增'}
            cancelText={'取消'}
            closable={false}
            width={800}
            preventScroll={true}
        >
            <Form
                style={{ padding: 10, width: '100%' }}
                onValueChange={(v) => form_data = v}
                labelPosition='left'
            >
                <Input
                    field="client_id"
                    label="client_id"
                    trigger='blur'
                    autoComplete={"false"}
                />
                <Input
                    field="sys_name"
                    label="系统名称"
                    trigger='blur'
                    autoComplete={"false"}
                />
            </Form>

        </Modal>
    </>
}